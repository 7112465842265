import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { SignsIllnessState } from './signsIllness.state'

@NgModule({
	declarations: [],
	imports: [NgxsModule.forFeature([SignsIllnessState])],
	providers: []
})
export class SignsIllnessStoreModule {}
