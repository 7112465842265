import moment from 'moment'

export function isInShiftRange(time: string, minTime: string, maxTime: string) {
	return moment(time).isBetween(
		moment(minTime, 'HH:mm'),
		moment(maxTime, 'HH:mm'),
		'minute',
		'[)'
	)
}
