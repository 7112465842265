import { Id } from './common'
import { GenericEntityResponse, FilterV2 } from '@biot-client/biot-client-generic-entity'
import { FileDTO } from './file'
import { UserInterface } from './user.model'
import { Order } from './alert'

export interface TaskId extends Id {}

export enum TaskTreatmentAction {
	ChangeDiaper = 'change_diaper',
	RepositionPatient = 'reposition_patient',
	MeasureAllVitals = 'measure_all_vitals'
}

export enum TaskStatus {
	Active = 'active',
	Completed = 'completed',
	Canceled = 'canceled'
}

export interface UpdateTaskInterface {
	taskCompletedTime?: string
	taskStatus?: TaskStatus.Completed | TaskStatus.Active | TaskStatus.Canceled
	expirationTime?: string
	taskCompletedBy?: {
		id: string | undefined
		name: string
		parentEntityId: null
		templateId: any
	}
	assignee_?: {
		id: string | undefined
		name: string
		parentEntityId: null
		templateId: any
	} | null
}

export interface BackendTaskDTO extends GenericEntityResponse {
	assignee_: TaskEntityInterface
	expirationTime: string
	taskCompletedBy?: any
	taskPatient: TaskEntityInterface
	taskStartTime: string
	taskStatus: string
	taskTreatmentAction: string
}

export interface TaskDTO extends TaskId {
	assignee?: TaskEntityInterface
	expirationTime: string
	taskCompletedBy?: any
	taskPatient: TaskEntityInterface
	taskStartTime: string
	taskStatus: string
	taskTreatmentAction: string
	creationTime: string
	lastModifiedTime: string
}

export interface TaskBackend {
	data: TaskDTO[]
	metadata: {
		page?: {
			totalResults?: number
			page?: number
			limit?: number
		}
		sort?: Array<Order>
		filter?: { [key: string]: FilterV2 }
		freeTextSearch?: string
	}
}

interface TaskEntityInterface {
	id: string
	name: string
}

export interface TaskDataInterface {
	id: string
	expirationTime: string
	taskCompletedBy?: any
	taskStartTime: string
	taskStatus: string
	taskTreatmentAction: string
	assignee?: TaskEntityInterface
}

export interface TaskPatientTasksInterface {
	id: string
	patient: {
		id: string | undefined
		firstName: string | undefined
		lastName: string | undefined
		gender: string | undefined
		room: string | undefined
		avatar: FileDTO | null
	}
	data: TaskDataInterface[]
}

export interface TaskPatientInterface {
	id: string
	timeLine: string
	isLaterTimeLine: boolean
	length: number
	tasks: TaskPatientTasksInterface[]
}

export interface OverdueTaskPatientInterface {
	id: string
	expirationTime: string
	patient: {
		id: string | undefined
		firstName: string | undefined
		lastName: string | undefined
		gender: string | undefined
		room: string | undefined
		avatar: FileDTO | null
	} | null
	assignee: UserInterface | null | undefined
	task: TaskDataInterface
}
