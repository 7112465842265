<div class="sidemenu" [class.tablet]="isTabletView" [class.opened]="isOpened">
  <div class="avatar" [class.desktop]="!isTabletView" (click)="avatarClick.emit()" nz-popover nzPopoverPlacement="bottomRight" nzPopoverTrigger="click" [nzPopoverContent]="nzPopoverContent" [(nzPopoverVisible)]="popoverVisible" nzPopoverOverlayClassName='current-user-setting-popover' [class.rn]="isUserRN">
    <lib-avatar [nzSrc]="user?.avatar" [nzText]="user?.name" [nzSize]="isTabletView ? 86 : 40" />
    <span class="sidemenu-text" [class.desktop]="!isTabletView">
      <span class="name">{{ user?.name }}</span>
      <!-- @if (departmentName) {
        <span class="department">{{ departmentName }}</span>
      } -->
      @if (deviceName) {
        <span class="device">Selected device: {{ deviceName }}</span>
      }
    </span>
  </div>
  <ul class="sidemenu-list">
    <li class="sidemenu-list__item" *ngFor="let item of items; let index = index" (click)="onListItemClick(item, index)">
      <a class="sidemenu-list__link" [routerLink]="item.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: item?.exact || false}">
        <nz-badge class="badge" [nzCount]="item?.badgeCount" [nzSize]="16">
          <span [class.desktop]="!isTabletView" *ngIf="item?.icon as icon" class="icon" nz-icon [nzType]="icon" [nzTheme]="item?.iconTheme"></span>
          <span class="sidemenu-text" [class.desktop]="!isTabletView" *ngIf="item?.title as title">{{ title | translate }}</span>
        </nz-badge>
      </a>
    </li>
  </ul>
  @if (customTemplateContent) {
    <ng-container *ngTemplateOutlet="customTemplateContent"></ng-container>
  }
  @if (!logoutHidden) {
    <ul class="sidemenu-logout" [class.desktop]="!isTabletView">
      <li class="sidemenu-logout__item" (click)="logout.emit()">
        <nz-badge class="badge">
          <span class="icon" [class.desktop]="!isTabletView" nz-icon nzType="logout" nzTheme="outline"></span>
          <span class="sidemenu-text" [class.desktop]="!isTabletView">{{ logoutText }}</span>
        </nz-badge>
      </li>
    </ul>
  }
</div>