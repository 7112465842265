import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { DepartmentState } from '../../../../../../store/department/department.state'
import { Select } from '@ngxs/store'
import { catchError, EMPTY, interval, Observable, of, switchMap, take } from 'rxjs'
import { DepartmentDTO } from '../../../../../../shared/model/permission.model'
import { UserState } from '../../../../../../store/user/user.state'
import { UserInterface } from '../../../../../../shared/model/user.model'
import { NzMessageService } from 'ng-zorro-antd/message'
import { cloneDeep } from 'lodash-es'
import { DepartmentFilter, DepartmentType } from '../../../../../../shared/model/departments.model'

@Component({
	selector: 'aiomed-login-department',
	templateUrl: './login-department.component.html',
	styleUrls: ['./login-department.component.scss']
})
export class LoginDepartmentComponent implements OnInit {
	@Output('formDepartmentSubmitEmitter')
	formSubmit = new EventEmitter<DepartmentDTO>()
	@Output() 
	cnaLoginRestrict = new EventEmitter<void>();
	@Select(DepartmentState.allDepartments)
	departments$: Observable<DepartmentDTO[]>
	@Select(UserState.beforeLogIntUser)
	user$: Observable<UserInterface>
	selectedValue: string | null = null;
	checked = false
	isButtonDisabled: boolean = false

	protected departmentLogin: boolean = true;

	constructor(
		private departmentState: DepartmentState,
		private userState: UserState,
		private message: NzMessageService
	) {}

	ngOnInit(): void {
		interval(500)
			.pipe(
				take(1),
				switchMap(() => this.userState.getBeforeAuthUser()),
				switchMap((user) => {
					this.setDefaultDepartment();
					return of(user);
				}),
			)
			.subscribe(() => {
				this.departmentState.getAllDepartments()
			})
	}

	async setCNAToDepartmentSetting(
		departments: DepartmentDTO[],
		department: DepartmentDTO,
		user: UserInterface,
		checked: boolean
	) {
		this.isButtonDisabled = true
		let currentPatientDepartments: DepartmentDTO[] = []
		departments.forEach((d) => {
			if (
				d.onDutyAssistantIds?.length &&
				d.onDutyAssistantIds.find((id) => id === user.id)
			) {
				currentPatientDepartments = [...currentPatientDepartments, d]
			}
		})
		const observer = {
			next: async (x: number) => {
				if (!currentPatientDepartments[x]) {
					observer.complete()
					return
				}
				this.departmentState.removeOnDutyAssistantIds(
					user.id,
					// @ts-ignore
					currentPatientDepartments[x].id,
					currentPatientDepartments[x]
				)
				const allDepartments = cloneDeep(
					this.departmentState.getState().allDepartments
				).map((d) => ({
					...d,
					onDutyAssistantIds: !d.onDutyAssistantIds
						? []
						: d.onDutyAssistantIds.filter((id) => id !== user.id)
				}))
				this.departmentState.patchState({ allDepartments })
				if (!currentPatientDepartments[x + 1]) {
					setTimeout(() => {
						observer.complete()
					})
				}
			},
			complete: () => {
				if (checked) {
					this.departmentState.updateShiftManagerDepartment(
						// @ts-ignore
						this.selectedValue === DepartmentFilter.All ? DepartmentFilter.All : department?.id,
						{
							shiftManager: {
								id: user?.id,
								name: `${user?.name.lastName} ${user?.name.firstName}`,
								templateId: (user as any)._template.id
							}
						}
					)
				} else {
					this.departmentState.updateOnDutyAssistantIds(
						user.id,
						// @ts-ignore
						this.selectedValue === DepartmentFilter.All ? DepartmentFilter.All : department?.id
					).pipe(take(1)).subscribe();
				}
				setTimeout(() => {
					const departmentType =  departments.find(d => d.id === this.selectedValue)?.isAutomatic ? DepartmentType.Automatic : DepartmentType.SemiAutomatic;
					const department = departments.find(d => d.id === this.selectedValue);
					this.formSubmit.emit(department)
				})
			}
		}
		if (!currentPatientDepartments.length) {
			await interval(1000).pipe(take(1)).forEach(observer.next)
		} else {
			await interval(1000)
				.pipe(take(currentPatientDepartments.length))
				.forEach(observer.next)
		}
	}

	async submitForm(user: UserInterface, departments: DepartmentDTO[]) {
		if (user?._degree === 'NO_DEGREE' || user?._degree === 'MEDICAL_ASSISTANT') {
			return
		} else if (!this.selectedValue) {
			return
		} else if (!this.checked) {
			const department = departments.find((d) => d.id === this.selectedValue)
			if (department?.shiftManager && department.shiftManager.id === user.id) {
				this.departmentState.updateShiftManagerDepartment(department.id, {
					// @ts-ignore
					shiftManager: null
				})
			}
			// @ts-ignore
			await this.setCNAToDepartmentSetting(departments, this.selectedValue === DepartmentFilter.all ? defaultAllDepartment : department, user, false)
		} else {
			departments.forEach((d) => {
				if (d.shiftManager && d.shiftManager.id === user.id) {
					this.departmentState.updateShiftManagerDepartment(d.id, {
						// @ts-ignore
						shiftManager: null
					})
				}
			})
			const department = departments.find((d) => d.id === this.selectedValue);
			// @ts-ignore
			await this.setCNAToDepartmentSetting(departments, this.selectedValue === DepartmentFilter.all ? defaultAllDepartment : department, user, true)
		}
	}

	private setDefaultDepartment(): void {
		this.departments$
			.pipe(
				take(2),
				catchError(() => {
					this.departmentLogin = false;
					return EMPTY;
				})).subscribe((d) => {
					const user = this.userState.snapshot.beforeLogIntUser;
					if (user !== null && user?._degree === 'NO_DEGREE' || user?._degree === 'MEDICAL_ASSISTANT') {
						this.cnaLoginRestrict.emit();
						return;
					}
					const departments = [...d];
					if (departments.length > 1) {
						if (user?.onDutyDepartment?.id && departments.some(department => department.id === user?.onDutyDepartment?.id)) {
							this.selectedValue = user?.onDutyDepartment?.id;
							this.submitForm(user!, departments);
							return;
						}
						if (!departments.some(department => department.onDutyAssistantIds?.includes(user?.id!))) {
							this.selectedValue = DepartmentFilter.All;
						} else {
							departments.forEach(department => {
								if (department.onDutyAssistantIds?.includes(user?.id!)) {
									this.selectedValue = department.id;
								}
							});
						}

						this.submitForm(user!, departments);
					}
				})
	}
}
