import { JsonPipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { InputNumberComponent, MinMaxStep } from '../input-number/input-number.component';
import { LibSelectDirective } from '../select/directives/lib-select.directive';
import { AlertSeverity } from '../shared/enums/alert-severity.enum';
import { ApplyClassToAllInnerSpansDirective } from '../checkbox/apply-class-to-checkbox-label.directive';

export type EmrAlertItemForm = {
  num: number | { [key: string]: number };
  select: number | string;
  check: boolean;
};

export type EmrAlertItemSelectOption = string | { nzValue: string, nzLabel: string };

export type EmrAlertItemErrorType = 'manual' | 'device';

@Component({
  selector: 'lib-emr-alert-item',
  standalone: true,
  imports: [NgIf, NgFor, NzFormModule, FormsModule, NzIconModule, NzCheckboxModule, InputNumberComponent, NzSelectModule, LibSelectDirective, TitleCasePipe, ApplyClassToAllInnerSpansDirective, JsonPipe],
  templateUrl: './emr-alert-item.component.html',
  styleUrls: ['./emr-alert-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmrAlertItemComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmrAlertItemComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @ViewChild('form') ngForm: NgForm;
  @ViewChild(InputNumberComponent) inputNumberComponent: InputNumberComponent;
  @Output() valueChanges = new EventEmitter<EmrAlertItemForm>();
  @Output() checkboxChange = new EventEmitter<boolean>();
  @Output() onInputFocus = new EventEmitter<void>();
  @Output() onInputBlur = new EventEmitter<void>();
  @Input({ required: true }) icon: string;
  @Input({ required: true }) fieldTitle: string;
  @Input() iconTheme?: string;
  @Input() severity: AlertSeverity = AlertSeverity.None;
  @Input() selectMinWidth: number = 295;
  @Input() selectOptions?: EmrAlertItemSelectOption[];
  @Input() selectLabel: string = 'Label';
  @Input() inputNumberUnit: string;
  @Input() inputNumberHasError: boolean = false;
  @Input() inputNumberDisabled: boolean = false;
  @Input() inputNumberMinMaxStep?: MinMaxStep | { [key: string]: MinMaxStep };
  @Input() inputNumberMinWidth: number = 264;
  @Input() warningText: string = 'Invalid value. Please try again';
  @Input() warningIcon: string = 'warning';
  @Input() hasError: boolean = true;
  @Input() hasErrorType: EmrAlertItemErrorType = 'device';
  @Input() isCritical: boolean = false;
  @Input() isDimmed: boolean = false;
  @Input() showRemeasure: boolean = true;
  @Input() dataReceived: boolean = false;
  @Input() dataReceivedIcon: string = 'icons:device-data-received';
  @Input() isLarge: boolean = true;
  @Input() isOldValue: boolean = false;
  @Input() disabledEditing: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() warningIconHidden: boolean = false;
  @Input() hasRecentValue: string | null = null;
  @Input() checkboxDisabled: boolean = false;
  @Input() tooltipShown: boolean = false;

  private cdr = inject(ChangeDetectorRef);

  private destroy$ = new Subject<void>();

  private onChange!: (value: EmrAlertItemForm) => void;
  private onTouched!: () => void;

  public dataForm: EmrAlertItemForm | undefined;

  ngAfterViewInit() {
    this.ngForm.form.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(v => {
      // if (this.dataForm?.select && this.ngForm.form.value.select && this.dataForm?.select === this.ngForm.form.value.select) {
        this.valueChanges.emit(v);
      // }
      this.onChange({ ...v });
    //   this.dataForm = {
    //     ...this.dataForm,
    //     select: v.select,
    //   } as EmrAlertItemForm
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: EmrAlertItemForm): void {
    if (!value) return;
    if (value?.num && typeof value.num !== 'object') {
      value.num = +value.num;
    }
    this.dataForm = { ...value };
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: EmrAlertItemForm) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public focusInputNumber(): void {
    this.inputNumberComponent.focus();
  }

  public inputChange(event: number | { [key: string]: number }) {
    if (!this.dataForm) {
      return;
    }
    if (typeof event === 'number') {
      this.dataForm.num = event;
      this.onChange({ ...this.dataForm })
    }
    if (typeof event === 'object') {
      // this.dataForm.num = { ...event };
    }
    this.cdr.markForCheck();
  }
}
