import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { PCC_CLOSE_WINDOW_DURATION } from "../constants/pcc-close-window-duration.constant";

@Injectable({
  providedIn: 'root',
})
export class WorkerService {
  private worker: Worker;
  public closeEmit$ = new Subject<void>();

  constructor() {
    this.initWebWorker();
  }

  public startWindowOpenedAutoClosingSession(): void {
    this.worker.postMessage({ timeout: PCC_CLOSE_WINDOW_DURATION });
  }

  private initWebWorker(): void {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('./app.worker', import.meta.url));
      this.worker.onmessage = (event: MessageEvent) => {
        if (event.data === 'close') {
          this.closeEmit$.next();
        }
      };
    } else {
      console.error('Web Workers are not supported in your browser.');
    }
  }
}