<form nz-form class="form" [attr.severity]="severity" #form="ngForm" [class.no-checkbox]="dataForm?.check === undefined">
  <nz-form-item class="form-checkbox__container">
    <nz-form-control class="form-checkbox" libApplyClassToAllInnerSpans="big">
      <label #checkbox="ngModel" nz-checkbox [ngModel]="dataForm?.check" name="check" [attr.severity]="severity" [class.error]="inputNumberHasError || (hasError && isCritical && hasErrorType === 'device')" [class.no-checkbox]="dataForm?.check === undefined" [class.big]="isLarge" [nzDisabled]="checkboxDisabled" (ngModelChange)="checkboxChange.emit($event)">
        <span class="icon" [class.big]="isLarge" [class.checked]="checkbox.value" nz-icon [nzType]="icon" [nzTheme]="iconTheme || 'outline'" [attr.severity]="severity"></span>
        <span class="title" [class.big]="isLarge">{{ fieldTitle }}</span>
      </label>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item [style.min-width.px]="inputNumberMinWidth" class="form-input-number" #nzFormItem>
    <nz-form-control nzValidateStatus="error" [nzErrorTip]="(inputNumberHasError || isRequired || hasError) && dateFormErrorTpl">
      <lib-input-number (onFocus)="onInputFocus.emit()" (onBlur)="onInputBlur.emit()" [isLarge]="isLarge" [isOldValue]="isOldValue" [disabledEditing]="disabledEditing" [ngModel]="dataForm?.num" (ngModelChange)="inputChange($event)" name="num" [hasError]="inputNumberHasError" [hasInputError]="hasError" [hasErrorType]="hasErrorType" [isCritical]="isCritical" [isDimmed]="isDimmed" [showRemeasure]="showRemeasure" [unit]="inputNumberUnit" [isDisabled]="inputNumberDisabled" [minMaxStep]="inputNumberMinMaxStep" />
      <ng-template #dateFormErrorTpl>
        @if (hasError) {
          <small [style.width.px]="nzFormItem.innerWidth" class="form-error" [class.purple]="(!isCritical || hasErrorType === 'manual')" [class.big]="isLarge">{{ (isCritical && hasErrorType === 'device') ? 'Critical Value Detected' : hasErrorType === 'device' ? 'Measurement inaccuracy' : 'Invalid entry' }}</small>
        } @else {
          <small *ngIf="warningText?.length" class="form-error" [class.big]="isLarge" [class.abnormal]="inputNumberHasError"><span *ngIf="!warningIconHidden" nz-icon [nzType]="warningIcon"></span>{{ warningText }}</small>
        }
      </ng-template>
      @if (hasError && tooltipShown) {
        <span class="form-error__tooltip" [class.no-checkbox]="dataForm?.check === undefined">{{ hasErrorType === 'device' ? 'Adjust devices and remeasure' : 'Enter a valid value to be able to save' }}</span>
      }
    </nz-form-control>
    <span class="required" *ngIf="isRequired" nz-icon nzType="aio:star-required"></span>
    <span *ngIf="hasRecentValue" class="recent" [class.recent-with-required]="isRequired" [class.with-data-received]="dataReceived">
      <span class="recent-value">{{ hasRecentValue }}</span>
    </span>
  </nz-form-item>
  <nz-form-item [style.min-width.px]="selectMinWidth" class="aio-select" [class.no-checkbox]="dataForm?.check === undefined" [class.dimmed]="isDimmed">
    <nz-form-control>
      <nz-select *ngIf="selectOptions" [nzOptionHeightPx]="isLarge ? 71 : 32" [nzOptionOverflowSize]="4" [ngModel]="dataForm?.select" name="select" [libSelect]="selectLabel" [class.big]="isLarge">
        <nz-option nzCustomContent *ngFor="let option of selectOptions" [nzValue]="option?.nzValue || option" [nzLabel]="option?.nzLabel || (option | titlecase)">
          <span [class]="isLarge ? 'big' : ''">{{ option?.nzLabel || (option | titlecase) }}</span>
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>
