export function setContentOverlays() {
	document.querySelector("[name='viewport']")?.remove()
	const meta = document.createElement('meta');
	meta.name = 'viewport';

	meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, interactive-widget=overlays-content');

	document.getElementsByTagName('head')[0].appendChild(meta);
}

export function setVisualResizes() {
	document.querySelector("[name='viewport']")?.remove()
	const meta = document.createElement('meta');
	meta.name = 'viewport';

	meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, interactive-widget=resizes-visual');
	document.getElementsByTagName('head')[0].appendChild(meta);
}