export function entitiesFilter(
	filter: string | undefined,
	array: any[]
): any[] {
	if (!filter) return array
	return array.filter(
		(item) =>
			item.name.toLowerCase().includes(filter.toLowerCase()) ||
			// item.id.includes(filter.toLowerCase()) ||
			item.room.toLowerCase().includes(filter.toLowerCase())
	)
}

export function entitiesDeviceFilter(
	filter: string | undefined,
	array: any[]
): any[] {
	if (!filter) return array
	return array.filter(
		(item) =>
			item.model.toLowerCase().includes(filter.toLowerCase()) ||
			item.serialNumber.includes(filter.toLowerCase()) ||
			(item.patient &&
				item.patient.name.toLowerCase().includes(filter.toLowerCase()))
	)
}
