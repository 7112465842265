import { getUserLocale } from './functions'
import { round } from 'lodash-es'

export const bodyTemperatureSetting = {
	isUserLocaleUS: getUserLocale() === 'en-US',
	body_temperature_static: {
		minUs: 93,
		min: 34,
		maxUs: 106,
		max: 41
	},
	getBodyTemperatureErmRules() {
		return {
			// min: this.isUserLocaleUS
			// 	? this.body_temperature_static.minUs
			// 	: this.body_temperature_static.min,
			// max: this.isUserLocaleUS
			// 	? this.body_temperature_static.maxUs
			// 	: this.body_temperature_static.max
			min: this.body_temperature_static.min,
			max: this.body_temperature_static.max
		}
	},
	toTransformFahrenheiToCelsiusWithoutRound(value: number) {
		return this.isUserLocaleUS ? ((Number(value) - 32) * 5) / 9 : value
	},
	toTransformTransformCelsiusToFahrenheiWithoutRound(value: number) {
		return this.isUserLocaleUS ? (value * 9) / 5 + 32 : value
	},
	toTransformFahrenheiToCelsius(value: number) {
		return this.isUserLocaleUS
			? round(((Number(value) - 32) * 5) / 9, 1)
			: round(value, 1)
	},
	toTransformCelsiusToFahrenhei(value: number): number {
		return this.isUserLocaleUS
			? round((value * 9) / 5 + 32, 1)
			: round(value, 1)
	},
	setBodyTemperatureErmRules(value: { minNormal: number; maxNormal: number }) {
		return {
			// min: this.toTransformCelsiusToFahrenhei(value.minNormal),
			// max: this.toTransformCelsiusToFahrenhei(value.maxNormal)
			min: round(value.minNormal, 1),
			max: round(value.maxNormal, 1)
		}
	},
	getBodyTemperatureSign() {
		return this.isUserLocaleUS ? '°F' : '°C'
	}
}
