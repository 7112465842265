export enum Entities {
  Departments = '102a6aff-b950-4db6-b36c-33ef39124e5d',
  AlertRules = 'c159c6bf-8aed-4965-b1cf-4b7015a19948',
  TaskBrowsing = 'd67a2b68-3246-41f1-9958-c246e3529693',
  PatientTaskBrowsing = 'd67a2b68-3246-41f1-9958-c246e3529693',
  AllTask = 'd67a2b68-3246-41f1-9958-c246e3529693',
  AllPatientLogs = 'ff831db9-94d3-455e-bb6d-0831ed8535ea',
  Exports = '53de458a-3ae1-4498-8455-c3a267a7c0d4',
  AllTreatmentPlan = 'a5f3746b-b8e8-4f59-831b-9a11d6c9c499',
  AllAlerts = '05d9c5d3-d854-4894-9fac-d02f166e11f8',
  UserProfile = '4c99f0b3-86e5-425a-b50e-41a51fcea884',
  CriticalAlerts = '05d9c5d3-d854-4894-9fac-d02f166e11f8',
  Insights = '368200ca-2176-45b6-99fc-03834a9b3a2b',
}

export enum EntitiesByNames {
  Departments = 'department',
  AlertRules = 'alertRules',
  TaskBrowsing = 'task',
  PatientTaskBrowsing = 'task',
  AllTask = 'task',
  AllPatientLogs = 'patientLogEntry',
  Exports = 'observation_export',
  AllTreatmentPlan = 'TreatmentPlan',
  AllAlerts = 'alert',
  UserProfile = 'userprofile',
  CriticalAlerts = 'alert',
  Insights = 'insight',
}