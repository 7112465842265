import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BtnComponent } from './btn/btn.component'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzButtonModule } from 'ng-zorro-antd/button'

@NgModule({
  declarations: [BtnComponent],
  imports: [CommonModule, NzIconModule, NzButtonModule],
  exports: [BtnComponent],
})
export class ButtonsModule {}
