import {
	CombineTreatmentPlansDTO,
	CombineTreatmentPlansVitalsDTO
} from '../../shared/model/treatment-plan'
import { PatientExportDTO } from '../../shared/model/pcc.model'
import {
	CheckShiftSentEmrInformationInterface,
	SentEmrInformation,
	ShiftCategory
} from '../../shared/model/report.model'
import moment from 'moment/moment'
import { cloneDeep } from 'lodash-es'
import { checkShiftTime } from './check-shift-time'

export function checkVitalsToTreatmentPlan(
	treatmentPlan: CombineTreatmentPlansDTO,
	exp: PatientExportDTO[],
	currentDate?: string
): CheckShiftSentEmrInformationInterface | null {
	const date = !currentDate ? moment() : moment(currentDate)
	const data = exp.filter((e) => e.export_status !== 'failed')
	const morningShiftTimeCheck =
		date.get('hours') >= checkShiftTime.getShiftTime('morning_shift').min &&
		date.get('hours') < checkShiftTime.getShiftTime('morning_shift').max
	const morningShiftExportData = data.filter((exports) => {
		return (
			exports.creationTime >=
				moment(new Date())
					.set('hours', checkShiftTime.getShiftTime('morning_shift').min)
					.set('minutes', 0)
					.toISOString() &&
			exports.creationTime <
				moment(new Date())
					.set('hours', checkShiftTime.getShiftTime('morning_shift').max)
					.set('minutes', 0)
					.toISOString()
		)
	})

	const dayShiftTimeCheck =
		date.get('hours') >= checkShiftTime.getShiftTime('day_shift').min &&
		date.get('hours') < checkShiftTime.getShiftTime('day_shift').max
	const dayShiftExportData = data.filter((exports) => {
		return (
			exports.creationTime >=
				moment(new Date())
					.set('hours', checkShiftTime.getShiftTime('day_shift').min)
					.set('minutes', 0)
					.toISOString() &&
			exports.creationTime <
				moment(new Date())
					.set('hours', checkShiftTime.getShiftTime('day_shift').max)
					.set('minutes', 0)
					.toISOString()
		)
	})

	const nightShiftTimeCheckMin =
		date.get('hours') >= checkShiftTime.getShiftTime('night_shift').min
	const nightShiftMinTimeExportData = data.filter((exports) => {
		return (
			exports.creationTime >=
			moment(new Date())
				.set('hours', checkShiftTime.getShiftTime('night_shift').min)
				.set('minutes', 0)
				.toISOString()
		)
	})

	const nightShiftTimeCheckMax =
		date.get('hours') < checkShiftTime.getShiftTime('night_shift').max
	const nightShiftMaxTimeExportData = data.filter((exports) => {
		return (
			exports.creationTime >=
				moment(new Date())
					.set('hours', checkShiftTime.getShiftTime('night_shift').min)
					.set('minutes', 0)
					.subtract(1, 'days')
					.toISOString() &&
			exports.creationTime <
				moment(new Date())
					.set('hours', checkShiftTime.getShiftTime('night_shift').max)
					.set('minutes', 0)
					.toISOString()
		)
	})

	if (
		treatmentPlan.morningShiftTime &&
		Object.values(treatmentPlan.morningVitalsData).length &&
		morningShiftTimeCheck
	) {
		const tmpData = setTreatmentPlanSetting(treatmentPlan.morningVitalsData)
		const tmpObj: CheckShiftSentEmrInformationInterface = cloneDeep(
			tmpData.tmpObj
		)
		const needToCheckLength: number = tmpData.needToCheckLength

		return setShiftCheckVitalsTreatmentPlanSetting(
			morningShiftExportData,
			tmpObj,
			needToCheckLength
		)
	} else if (
		treatmentPlan.dayShiftTime &&
		Object.values(treatmentPlan.dayVitalsData).length &&
		dayShiftTimeCheck
	) {
		const tmpData = setTreatmentPlanSetting(treatmentPlan.dayVitalsData)
		const tmpObj: CheckShiftSentEmrInformationInterface = cloneDeep(
			tmpData.tmpObj
		)
		const needToCheckLength: number = tmpData.needToCheckLength
		return setShiftCheckVitalsTreatmentPlanSetting(
			dayShiftExportData,
			tmpObj,
			needToCheckLength
		)
	} else if (
		treatmentPlan.nightShiftTime &&
		Object.values(treatmentPlan.nightVitalsData).length &&
		nightShiftTimeCheckMin
	) {
		const tmpData = setTreatmentPlanSetting(treatmentPlan.nightVitalsData)
		const tmpObj: CheckShiftSentEmrInformationInterface = cloneDeep(
			tmpData.tmpObj
		)
		const needToCheckLength: number = tmpData.needToCheckLength
		return setShiftCheckVitalsTreatmentPlanSetting(
			nightShiftMinTimeExportData,
			tmpObj,
			needToCheckLength
		)
	} else if (
		treatmentPlan.nightShiftTime &&
		Object.values(treatmentPlan.nightVitalsData).length &&
		nightShiftTimeCheckMax
	) {
		const tmpData = setTreatmentPlanSetting(treatmentPlan.nightVitalsData)
		const tmpObj: CheckShiftSentEmrInformationInterface = cloneDeep(
			tmpData.tmpObj
		)
		const needToCheckLength: number = tmpData.needToCheckLength
		return setShiftCheckVitalsTreatmentPlanSetting(
			nightShiftMaxTimeExportData,
			tmpObj,
			needToCheckLength
		)
	} else {
		return (
			checkNotRequiredPatientToSendShiftSentEmrInformation(
				morningShiftTimeCheck,
				morningShiftExportData
			) ||
			checkNotRequiredPatientToSendShiftSentEmrInformation(
				dayShiftTimeCheck,
				dayShiftExportData
			) ||
			checkNotRequiredPatientToSendShiftSentEmrInformation(
				nightShiftTimeCheckMin,
				nightShiftMinTimeExportData
			) ||
			checkNotRequiredPatientToSendShiftSentEmrInformation(
				nightShiftTimeCheckMax,
				nightShiftMaxTimeExportData
			)
		)
	}
}

function setTreatmentPlanSetting(
	treatmentPlan: CombineTreatmentPlansVitalsDTO
): {
	needToCheckLength: number
	tmpObj: CheckShiftSentEmrInformationInterface
} {
	let needToCheckLength = 0
	const tmpObj: CheckShiftSentEmrInformationInterface = {
		status: 'NEED_EXPORT_TO_EMR'
	}

	if (!!treatmentPlan.BT) {
		needToCheckLength += 1
		tmpObj.bt = false
		tmpObj.btStatus = 'NEED_SEND_TO_EMR'
	}
	if (!!treatmentPlan.BG) {
		needToCheckLength += 1
		tmpObj.bg = false
		tmpObj.bgStatus = 'NEED_SEND_TO_EMR'
	}
	if (!!treatmentPlan.SPO2) {
		needToCheckLength += 1
		tmpObj.spo2 = false
		tmpObj.spo2Status = 'NEED_SEND_TO_EMR'
	}
	if (!!treatmentPlan.BP) {
		needToCheckLength += 1
		tmpObj.bp = false
		tmpObj.bpStatus = 'NEED_SEND_TO_EMR'
	}
	if (!!treatmentPlan.HR) {
		needToCheckLength += 1
		tmpObj.hr = false
		tmpObj.hrStatus = 'NEED_SEND_TO_EMR'
	}
	if (!!treatmentPlan.RR) {
		needToCheckLength += 1
		tmpObj.rr = false
		tmpObj.rrStatus = 'NEED_SEND_TO_EMR'
	}

	return {
		needToCheckLength,
		tmpObj
	}
}

function setShiftCheckVitalsTreatmentPlanSetting(
	currentShiftExportData: PatientExportDTO[],
	reportTreatmentPlan: CheckShiftSentEmrInformationInterface,
	needToCheckLength: number
): CheckShiftSentEmrInformationInterface {
	let checkedLength = 0
	currentShiftExportData.forEach((e) => {
		if (
			e.bodyTemperature &&
			reportTreatmentPlan.btStatus === 'NEED_SEND_TO_EMR'
		) {
			reportTreatmentPlan.bt = true
			// if (
			// 	currentShiftExportData[currentShiftExportData.length - 1]
			// 		?.export_status !== 'failed'
			// ) {
			// 	reportTreatmentPlan.btStatus = 'SENT_TO_EMR'
			// }
			reportTreatmentPlan.btStatus = 'SENT_TO_EMR'
		}
		if (
			e.systolicPressure &&
			e.diastolicPressure &&
			reportTreatmentPlan.bpStatus === 'NEED_SEND_TO_EMR'
		) {
			reportTreatmentPlan.bp = true
			// if (
			// 	currentShiftExportData[currentShiftExportData.length - 1]
			// 		?.export_status !== 'failed'
			// ) {
			// 	reportTreatmentPlan.bpStatus = 'SENT_TO_EMR'
			// }
			reportTreatmentPlan.bpStatus = 'SENT_TO_EMR'
		}
		if (e.heartRate && reportTreatmentPlan.hrStatus === 'NEED_SEND_TO_EMR') {
			reportTreatmentPlan.hr = true
			// if (
			// 	currentShiftExportData[currentShiftExportData.length - 1]
			// 		?.export_status !== 'failed'
			// ) {
			// 	reportTreatmentPlan.hrStatus = 'SENT_TO_EMR'
			// }
			reportTreatmentPlan.hrStatus = 'SENT_TO_EMR'
		}
		if (e.bloodGlucose && reportTreatmentPlan.bgStatus === 'NEED_SEND_TO_EMR') {
			reportTreatmentPlan.bg = true
			// if (
			// 	currentShiftExportData[currentShiftExportData.length - 1]
			// 		?.export_status !== 'failed'
			// ) {
			// 	reportTreatmentPlan.bgStatus = 'SENT_TO_EMR'
			// }
			reportTreatmentPlan.bgStatus = 'SENT_TO_EMR'
		}
		if (
			e.respirationRate &&
			reportTreatmentPlan.rrStatus === 'NEED_SEND_TO_EMR'
		) {
			reportTreatmentPlan.rr = true
			// if (
			// 	currentShiftExportData[currentShiftExportData.length - 1]
			// 		?.export_status !== 'failed'
			// ) {
			// 	reportTreatmentPlan.rrStatus = 'SENT_TO_EMR'
			// }
			reportTreatmentPlan.rrStatus = 'SENT_TO_EMR'
		}
		if (e.spo2 && reportTreatmentPlan.spo2Status === 'NEED_SEND_TO_EMR') {
			reportTreatmentPlan.spo2 = true
			// if (
			// 	currentShiftExportData[currentShiftExportData.length - 1]
			// 		?.export_status !== 'failed'
			// ) {
			// 	reportTreatmentPlan.spo2Status = 'SENT_TO_EMR'
			// }
			reportTreatmentPlan.spo2Status = 'SENT_TO_EMR'
		}
	})
	reportTreatmentPlan.hasSomeVitals = false
	if (
		currentShiftExportData.length &&
		currentShiftExportData[currentShiftExportData.length - 1].export_status !==
			'failed'
	) {
		const exp = currentShiftExportData[currentShiftExportData.length - 1]
		reportTreatmentPlan.hasSomeVitals = !!(
			exp.spo2 ||
			exp.respirationRate ||
			exp.bloodGlucose ||
			exp.heartRate ||
			exp.systolicPressure ||
			exp.diastolicPressure ||
			exp.bodyTemperature
		)
	}
	Object.keys(reportTreatmentPlan).forEach((k) => {
		if (
			k === 'status' ||
			k === 'rrStatus' ||
			k === 'hrStatus' ||
			k === 'bpStatus' ||
			k === 'btStatus' ||
			k === 'spo2Status' ||
			k === 'bgStatus' ||
			k === 'hasSomeVitals' ||
			// @ts-ignore
			!reportTreatmentPlan[k]
		)
			return
		checkedLength += 1
		if (needToCheckLength === checkedLength) {
			// if (
			// 	currentShiftExportData[currentShiftExportData.length - 1]
			// 		?.export_status !== 'failed'
			// ) {
			// 	reportTreatmentPlan.status = SentEmrInformation.Completed
			// }
			reportTreatmentPlan.status = SentEmrInformation.Completed
		} else if (checkedLength !== 0 && needToCheckLength !== checkedLength) {
			// if (
			// 	currentShiftExportData[currentShiftExportData.length - 1]
			// 		?.export_status !== 'failed'
			// ) {
			// 	reportTreatmentPlan.status = SentEmrInformation.PartialExport
			// }
			reportTreatmentPlan.status = SentEmrInformation.PartialExport
		} else {
			reportTreatmentPlan.status = SentEmrInformation.NeedExportToEmr
		}
	})
	return { ...reportTreatmentPlan, category: ShiftCategory.VitalsRequired }
}

function checkNotRequiredPatientToSendShiftSentEmrInformation(
	shiftTimeCheck: boolean,
	currentShiftExportData: PatientExportDTO[]
) {
	if (shiftTimeCheck) {
		if (
			!currentShiftExportData.length
			// ||
			// (currentShiftExportData.length &&
			// 	currentShiftExportData[currentShiftExportData.length - 1]
			// 		?.export_status === 'failed')
		) {
			return {
				status: SentEmrInformation.NotRequired,
				category: ShiftCategory.VitalsNotRequired
			} as CheckShiftSentEmrInformationInterface
		}

		return {
			status: SentEmrInformation.Completed,
			category: ShiftCategory.VitalsNotRequired
		} as CheckShiftSentEmrInformationInterface

		// if (
		// 	currentShiftExportData[currentShiftExportData.length - 1]
		// 		?.export_status !== 'failed'
		// ) {
		// 	return {
		// 		status: SentEmrInformation.Completed,
		// 		category: ShiftCategory.VitalsNotRequired
		// 	} as CheckShiftSentEmrInformationInterface
		// }
	}
	return null
}
