import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeaderComponent } from './components/header/header.component'
import { InputsModule } from '../UI/inputs/inputs.module'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { CoreModule } from '../../../core/core.module'
import { ModalsModule } from '../modals/modals.module'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { FormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { NzDrawerModule } from 'ng-zorro-antd/drawer'
import { RouterLink } from '@angular/router'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzFormModule } from 'ng-zorro-antd/form'

@NgModule({
	declarations: [HeaderComponent],
	imports: [
		CommonModule,
		InputsModule,
		NzIconModule,
		NzLayoutModule,
		NzButtonModule,
		CoreModule,
		ModalsModule,
		NzPopoverModule,
		NzSelectModule,
		FormsModule,
		TranslateModule,
		NzDrawerModule,
		RouterLink,
		NzAvatarModule,
		NzFormModule
	],
	exports: [HeaderComponent]
})
export class HeadersModule {}
