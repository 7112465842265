export enum PccCloseWindowReason {
	SuccessfullLogin = 'SuccessfullLogin',
	UserManuallyClosedWindow = 'UserManuallyClosedWindow',
	PatientPreviewPopupWarningAboutNotAllVitalsCloseWasClicked = 'PatientPreviewPopupWarningAboutNotAllVitalsCloseWasClicked',
	MainPageWasReloadedLeadingOpenedWindowToClose = 'MainPageWasReloadedLeadingOpenedWindowToClose',
	WindowWasClosedBecauseOfTimeout = 'WindowWasClosedBecauseOfTimeout',
	ButtonToLoginWithPccWasClickedAgain = 'ButtonToLoginWithPccWasClickedAgain',
	LoginViewWithPccButtonWasClosed = 'LoginViewWithPccButtonWasClosed',
	PccLoginWithCNAuser = 'PccLoginWithCNAuser',
	PccTabWasClosedEitherAfterSuccessfulLoginOrJustClosed = 'PccTabWasClosedEitherAfterSuccessfulLoginOrJustClosed',
	LoginWithAiomedCredentialsAndPccTokenReceived = 'LoginWithAiomedCredentialsAndPccTokenReceived',
	PccReturnedError = 'PccReturnedError'
}
