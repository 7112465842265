import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export function pinValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const pin0 = control.get('pin0')?.value || ''
		const pin1 = control.get('pin1')?.value || ''
		const pin2 = control.get('pin2')?.value || ''
		const pin3 = control.get('pin3')?.value || ''
		const pin = pin0 + pin1 + pin2 + pin3
		return pin.length === 4 ? null : { pinLength: true }
	}
}
