export const encodeString = (str: string, key: string): string => {
  const length = key.length
  const keyList = key.split('')
  let s = '',
    bit,
    bit1,
    bit2,
    bit3,
    bit4
  for (let i = 0; i < str.length; i++) {
    bit = str.charCodeAt(i)
    bit1 = bit % length
    bit = (bit - bit1) / length
    bit2 = bit % length
    bit = (bit - bit2) / length
    bit3 = bit % length
    bit = (bit - bit3) / length
    bit4 = bit % length
    s += keyList[bit4] + keyList[bit3] + keyList[bit2] + keyList[bit1]
  }
  return s
}
export const decodeString = (str: string, key: string): string => {
  if (!str) {
    return ''
  }
  let length = key.length
  let s = new Array(Math.floor(str.length / 4))
  let bit,
    bit1,
    bit2,
    bit3,
    bit4,
    j = 0
  let result = []
  bit = s.length
  for (var i = 0; i < bit; i++) {
    bit1 = key.indexOf(str.charAt(j))
    j++
    bit2 = key.indexOf(str.charAt(j))
    j++
    bit3 = key.indexOf(str.charAt(j))
    j++
    bit4 = key.indexOf(str.charAt(j))
    j++
    s[i] =
      bit1 * length * length * length +
      bit2 * length * length +
      bit3 * length +
      bit4
    result.push(String.fromCharCode(s[i]))
  }
  return result.join('')
}
