import {
	CheckShiftSentEmrInformationInterface,
	VitalStatusIndicator
} from './report.model'
import { TreatmentPlanDTO } from './treatment-plan'

export interface ShiftPlanerDTO {
	notRequired: ShiftPlanerPatientInterface[]
	vitalsRound: ShiftPlanerPatientInterface[]
	date: string
	textFilter: string
	currentShift: ShiftType
}

export interface ShiftPlanerPatientInterface {
	avatarUrl?: string
	name: string
	id: string | undefined
	room: string | undefined
	treatmentPlan: TreatmentPlanDTO[] | undefined
	checkShiftSentEmrInformation:
		| CheckShiftSentEmrInformationInterface
		| null
		| undefined
	isVitalComplete: boolean
	isVitalPartial: boolean
	emrStatus: VitalStatusIndicator | null
	notRequiredStatus?: {
		isComplete?: boolean
		isGray?: boolean
	}
	_ownerOrganization: any
}

export enum ShiftSections {
	VitalsRound = 'Vitals round',
	NotRequired = 'Not required'
}

export enum ShiftType {
	NightShift = 'night_shift',
	DayShift = 'day_shift',
	EveningShift = 'evening_shift'
}

export const SHIFT_RANGES = {
	[ShiftType.DayShift]: { min: 7, max: 15 },
	[ShiftType.EveningShift]: { min: 15, max: 23 },
	[ShiftType.NightShift]: { min: 23, max: 7 }
}
