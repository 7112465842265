import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { createEntityCollections } from '@angular-ru/cdk/entity'
import { Injectable, NgZone } from '@angular/core'
import {
	BehaviorSubject,
	EMPTY,
	ignoreElements,
	mergeMap,
	Observable,
	of,
	Subscription,
	take,
	tap,
	timer
} from 'rxjs'
import {
	BackendDepartmentDTO,
	DepartmentConfigurationInterface,
	DepartmentDTO
} from '../../shared/model/permission.model'
import { mapToVoid } from '@angular-ru/cdk/rxjs'
import { BackendService } from '../../shared/services/backend.service'
import { UserState } from '../user/user.state'
import { UserInterface } from '../../shared/model/user.model'
import { cloneDeep, isEqual, orderBy, uniq } from 'lodash-es'
import { AuthState } from '../auth/auth.state'
import { StoreEventsService } from '../store-events.service'
import {
	defaultAllDepartment,
	DepartmentFilter,
	DepartmentType
} from '../../shared/model/departments.model'
import { PreferenceState } from '../preference/preference.state'
import { MeasurementState } from '../measurement/measurement.state'
import { Router } from '@angular/router'
import { checkSemiAutomaticDepartmentAllowedRoute } from '../../core/helpers/check-semiautomatic-allowed-route'

export const departmentFeatureName = 'department'

@StateRepository()
@State<CollatableEntityCollections<DepartmentDTO>>({
	name: departmentFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class DepartmentState extends CollatableEntityCollectionsRepository<
	DepartmentDTO,
	EntityCollation
> {
	private departmentStateSubscription: Subscription
	private departmentChange$ = new BehaviorSubject<boolean>(false)
	public readonly departmentChangeObs$ = this.departmentChange$.asObservable()
	private readonly isMobile = this.preferenceState.isMobile;

	constructor(
		private backendService: BackendService,
		private userState: UserState,
		private authState: AuthState,
		private storeEvents: StoreEventsService,
		private preferenceState: PreferenceState,
		private measurementsState: MeasurementState,
		private router: Router,
		private ngZone: NgZone
	) {
		super()
	}

	@Selector()
	public static departments(
		state: CollatableEntityCollections<DepartmentDTO>
	): DepartmentDTO[] {
		return Object.values(state.entities)
	}

	@Selector()
	public static allDepartments(
		state: CollatableEntityCollections<DepartmentDTO>
	): DepartmentDTO[] {
		const departmentsExceptAll = state.allDepartments.filter(
			(d) => d.id !== DepartmentFilter.All
		)
		return [
			defaultAllDepartment as DepartmentDTO,
			...orderBy(Object.values(departmentsExceptAll), 'name', 'asc')
		]
	}

	@Selector([UserState.medicalAssistants])
	static departmentMedicalAssistants(
		state: CollatableEntityCollections<DepartmentDTO>,
		medicalAssistants: UserInterface[]
	): UserInterface[] {
		const departmentMedicalAssistants: UserInterface[] = []
		if (
			Object.values(state.entities) &&
			Object.values(state.entities).length &&
			Object.values(state.entities)[0].onDutyAssistantIds &&
			medicalAssistants.length
		) {
			Object.values(state.entities)[0].onDutyAssistantIds?.forEach((id) => {
				const mA = medicalAssistants.find((ma) => ma.id === id)
				if (mA) {
					departmentMedicalAssistants.push(mA)
				}
			})
		}
		return uniq(departmentMedicalAssistants)
	}

	@Selector([UserState.medicalAssistants])
	static medicalAssistantWithoutDepartments(
		state: CollatableEntityCollections<DepartmentDTO>,
		medicalAssistants: UserInterface[]
	): UserInterface[] {
		let medicalAssistantWithoutDepartments: UserInterface[] = []
		if (
			Object.values(state.entities) &&
			Object.values(state.entities).length &&
			Object.values(state.entities)[0].onDutyAssistantIds
		) {
			medicalAssistants.forEach((ma) => {
				const idx = Object.values(
					state.entities
				)[0].onDutyAssistantIds?.findIndex((dId) => dId === ma.id)
				if (idx !== -1) return
				medicalAssistantWithoutDepartments.push(ma)
			})
		} else {
			if (!Object.values(state.entities).length)
				return medicalAssistantWithoutDepartments
			medicalAssistantWithoutDepartments = cloneDeep([...medicalAssistants])
		}
		return medicalAssistantWithoutDepartments
	}

	@Selector()
	public static department(
		state: CollatableEntityCollections<DepartmentDTO>
	): DepartmentDTO | null {
		return !!state.currentDepartment
			? state.currentDepartment
			: Object.values(state.entities).length
			? Object.values(state.entities)[0]
			: null
	}

	@Selector()
	public static withoutDepartment(
		state: CollatableEntityCollections<DepartmentDTO>
	): boolean {
		return state.withoutDepartment
	}

	@Selector()
	public static currentDepartment(
		state: CollatableEntityCollections<DepartmentDTO>
	): DepartmentDTO | null {
		return state.currentDepartment
	}

	@Selector()
	public static currentDepartmentConfiguration(
		state: CollatableEntityCollections<DepartmentDTO>
	): DepartmentConfigurationInterface | undefined {
		return state.currentDepartment?.configuration
	}

	@Selector([UserState.currentUser])
	public static departmentType(
		state: CollatableEntityCollections<DepartmentDTO>,
		user: UserInterface
	) {
		let departmentType: DepartmentType | null = null
		const currentDepartment =
			state.currentDepartment ||
			(JSON.parse(
				localStorage.getItem('preference.department')!
			) as unknown as DepartmentDTO)
		if (!currentDepartment) return
		if (state?.currentDepartment?.id !== DepartmentFilter.All) {
			departmentType = currentDepartment?.isAutomatic
				? DepartmentType.Automatic
				: DepartmentType.SemiAutomatic
		} else {
			const userDefaultDepartment = state.allDepartments.find(
				(d) => d.id === user?.onDutyDepartment?.id
			)
			if (!userDefaultDepartment) {
				departmentType = state.allDepartments.filter(
					(d) => d.id !== DepartmentFilter.All
				)[0].isAutomatic
					? DepartmentType.Automatic
					: DepartmentType.SemiAutomatic
			} else {
				departmentType = userDefaultDepartment.isAutomatic
					? DepartmentType.Automatic
					: DepartmentType.SemiAutomatic
			}
		}
		return departmentType
	}

	@DataAction()
	setDepartmentChangeSetting() {
		this.departmentChange$.next(true)
		timer(1000)
			.pipe(take(1))
			.subscribe(() => this.departmentChange$.next(false))
	}

	public isShiftManager(): boolean {
		return !!Object.values(this.snapshot.entities).length
	}

	// @DataAction()
	public updateOnDutyAssistantIds(
		@Payload('entityId') idCNA: string,
		@Payload('departmentId') departmentId?: string
	): Observable<any> {
		if (departmentId === DepartmentFilter.All) return of('')
		if (!departmentId) return of('')
		// @ts-ignore
		const { id, onDutyAssistantIds } = !departmentId
			? cloneDeep(Object.values(this.snapshot.entities)[0])
			: cloneDeep(
					this.snapshot.allDepartments.find((d) => d.id === departmentId)
			  )

		const assistantIdsString =
			onDutyAssistantIds && onDutyAssistantIds?.length
				? cloneDeep(
						[...cloneDeep(onDutyAssistantIds), cloneDeep(idCNA)].join(',')
				  )
				: cloneDeep([idCNA]).join(',')
		return this.backendService.updateOnDutyAssistantIds(
			!departmentId ? id : departmentId,
			{
				onDutyAssistantIds: assistantIdsString
			}
		)
	}

	@DataAction()
	public getAllDepartments(): Observable<void> {
		return this.backendService.getAllDepartments().pipe(
			tap((res) => {
				this.patchState({
					allDepartments: [...this.getState().allDepartments, ...res]
				})
			}),
			mapToVoid()
		)
	}

	@DataAction()
	public getCurrentDepartments(): Observable<void> {
		return this.backendService.getAllDepartments().pipe(
			tap((res) => {
				let currentDepartment: DepartmentDTO | null = null
				res.forEach((d) => {
					if (
						d.onDutyAssistantIds &&
						d.onDutyAssistantIds?.find(
							(id) => id === this.userState.snapshot.user?.id
						)
					) {
						currentDepartment = d
					}
				})
				this.patchState({
					currentDepartment
				})
			}),
			mapToVoid()
		)
	}

	@DataAction()
	public updateShiftManagerDepartment(
		@Payload('entityId') entityId: string,
		@Payload('entityDiff') entityDiff: Partial<BackendDepartmentDTO>,
		@Payload('isAuthenticated') isAuthenticated: boolean = false,
		@Payload('department') department?: DepartmentDTO
	) {
		if (department?.id === DepartmentFilter.All) {
			this.departmentChange(department)
			return
		}
		return this.backendService
			.updateOnDutyAssistantIds(entityId, entityDiff)
			.pipe(
				tap((res) => {
					this.upsertOne(res)
					if (isAuthenticated) {
						this.authState.logout()
					}
					if (department) {
						this.departmentChange(department)
					}
				}),
				ignoreElements()
			)
	}

	@DataAction()
	public removeOnDutyAssistantIds(
		@Payload('entityId') idCNA: string,
		@Payload('departmentId') departmentId?: string,
		@Payload('department') department?: DepartmentDTO,
		@Payload('isAuthenticated') isAuthenticated: boolean = false
	): Observable<void> {
		let id: string = ''
		let onDutyAssistantIds: string[] = []
		if (department) {
			id = department.id
			onDutyAssistantIds = !department.onDutyAssistantIds
				? []
				: department.onDutyAssistantIds
		} else {
			const currentDepartment = !departmentId
				? cloneDeep(Object.values(this.snapshot.entities)[0])
				: cloneDeep(
						Object.values(this.snapshot.entities).find(
							(d) => d.id === departmentId
						)
				  )
			// @ts-ignore
			id = currentDepartment.id
			onDutyAssistantIds = !currentDepartment?.onDutyAssistantIds
				? []
				: currentDepartment?.onDutyAssistantIds
		}
		const assistantIdsString =
			onDutyAssistantIds && onDutyAssistantIds?.length
				? cloneDeep(
						[...onDutyAssistantIds].filter((ids) => ids !== idCNA).join(',')
				  )
				: ''
		return this.backendService
			.updateOnDutyAssistantIds(id, { onDutyAssistantIds: assistantIdsString })
			.pipe(
				mergeMap((res) => {
					this.upsertOne(res)
					if (isAuthenticated) {
						this.authState.logout()
					}
					return of()
				}),
				ignoreElements()
			)
	}

	@DataAction()
	public logout(): Observable<void> {
		this.authState.logout()
		// this.preferenceState.isMobile$.pipe(take(1)).subscribe((isMobile) => {
			// if (this.isMobile) {
			// 	return;
			// }
		// })
		return of()
	}

	// @DataAction()
	public updateWithModifiedDepartments() {
		return this.backendService.findAllDepartments().pipe(
			tap((res) => {
				const uniqueDepartments = [
					...this.getState().allDepartments,
					...(res as DepartmentDTO[])
				].reduce(
					(prev: DepartmentDTO[], cur) => [
						...prev.filter((d) => d.id !== cur.id),
						cur
					],
					[]
				)
				this.patchState({
					allDepartments: uniqueDepartments as DepartmentDTO[]
				})

				if (Object.values(this.entities).length) {
					const currentShift = res
						.filter((d) => d.shiftManager && d.shiftManager.id)
						.find(
							(d) => d.shiftManager?.id === this.userState?.snapshot?.user?.id
						)
					if (!!currentShift) {
						this.setAll([currentShift])
					} else {
						this.setAll([])
					}
				}

				let currentDepartment = (JSON.parse(localStorage.getItem('preference.department')!) as unknown as DepartmentDTO);
				if (currentDepartment && !this.getState().allDepartments.find((department) => department.id === currentDepartment.id)) {
					currentDepartment = null as unknown as DepartmentDTO;
				}
				if (this.isMobile) {
					if (currentDepartment) {
						this.patchState({ currentDepartment });
						this.dispatch({ type: 'DEPARTMENT UPDATE' })
					} else {
						this.preferenceState.setPreferenceDepartment(null);
					}
					return;
				} 
				if (currentDepartment) {
					this.patchState({ currentDepartment });
					this.preferenceState.setPreferenceDepartment(currentDepartment)
				} else {
					const initialDepartment = this.getState().allDepartments.filter(d => d.id !== defaultAllDepartment.id).sort((a,b) => {
							if (a.name > b.name) {
								return 1;
							}
							if (a.name < b.name) {
								return -1;
							}
							return 0;
						})[0];
						this.patchState({ currentDepartment: initialDepartment || defaultAllDepartment as unknown as DepartmentDTO });
					this.preferenceState.setPreferenceDepartment(initialDepartment || defaultAllDepartment as unknown as DepartmentDTO);
				}
				this.dispatch({ type: 'DEPARTMENT UPDATE' })
					
			})
		)
	}

	public override ngxsOnInit() {
		this.storeEvents.userModified$
			.pipe(
				tap(() => {
					if (this.departmentStateSubscription)
						this.departmentStateSubscription.unsubscribe()
					this.departmentStateSubscription =
						this.updateWithModifiedDepartments().subscribe()
				})
			)
			.subscribe()

		this.storeEvents.logout$
			.pipe(
				tap(() => {
					this.patchState({
						allDepartments: [],
						withoutDepartment: false
					})
					this.reset()
					if (this.departmentStateSubscription)
						this.departmentStateSubscription.unsubscribe()
				})
			)
			.subscribe()
	}

	@DataAction()
	public departmentChange(
		@Payload('department') department: DepartmentDTO
	): void {
		this.patchState({ currentDepartment: department })
		this.preferenceState.setPreferenceDepartment(department)
		this.preferenceState.setAbsenceOfMeasurementsHintOpened(true)
		this.measurementsState.resetMeasurements()
		this.redirectSemiAutomaticDepartmentFromRestrictedRoutes(department)
	}

	@DataAction()
	public setCurrentDepartment(
		@Payload('departmentId') currentDepartmentId: DepartmentDTO['id']
	): void {
		const currentDepartment = this.getState().allDepartments.find(department => department.id === currentDepartmentId);
		if (!currentDepartment) {
			return;
		}

		this.patchState({ currentDepartment });
		this.preferenceState.setPreferenceDepartment(currentDepartment);
		this.dispatch({ type: 'DEPARTMENT UPDATE' })
		this.measurementsState.setLoading()
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	private redirectSemiAutomaticDepartmentFromRestrictedRoutes(
		department: DepartmentDTO
	): void {
		if (!department.isAutomatic) {
			const urlAllowed = checkSemiAutomaticDepartmentAllowedRoute(
				this.router.url
			)
			if (!urlAllowed) {
				this.ngZone.run(() => this.router.navigate(['/']))
			}
		}
	}
}
