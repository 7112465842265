import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import { StateRepository } from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, Observable } from 'rxjs'
import { InsightDTO } from '../../shared/model/insight.model'
import { InsightState } from '../insight/insight.state'
import { PatientState } from '../patient/patient.state'
import { FileState } from '../file/file.state'
import { DepartmentState } from '../department/department.state'
import { PatientDTO } from '../../shared/model/patient'
import { FileDTO } from '../../shared/model/file'
import { DepartmentDTO } from '../../shared/model/permission.model'
import { DepartmentFilter } from '../../shared/model/departments.model'

export const insightFeatureName = 'insights'

@StateRepository()
@State<CollatableEntityCollections<InsightDTO>>({
	name: insightFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class InsightsState extends CollatableEntityCollectionsRepository<
	InsightDTO,
	EntityCollation
> {
	constructor() {
		super()
	}

	@Selector([
		InsightState.entities,
		PatientState.entities,
		FileState.files,
		DepartmentState.department
	])
	public static activeInsights(
		state: CollatableEntityCollections<InsightDTO>,
		insights: CollatableEntityCollections<InsightDTO>,
		patient: CollatableEntityCollections<PatientDTO>,
		files: EntityDictionary<string, FileDTO>,
		department: DepartmentDTO | undefined
	): InsightDTO[] {
		let patients = Object.values(patient)
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const currentInsights: InsightDTO[] = []
		Object.values(insights).forEach((insight) => {
			if (insight.patient && insight.patient.id) {
				const patient: PatientDTO | undefined = Object.values(patients).find(
					(p) => p.id === insight.patient?.id
				)
				if (patient) {
					currentInsights.push({
						...insight,
						patient: {
							...patient,
							avatar:
								patient.avatar &&
								files[patient.avatar.id] &&
								files[patient.avatar.id]?.signedUrl
									? files[patient.avatar.id]
									: null
						}
					})
				}
			}
		})
		return currentInsights.filter((insight) => !insight.insightEndTime)
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}
}
