import { Component, EventEmitter, Output } from '@angular/core'
import { NgTemplateOutlet } from '@angular/common'
import { NzButtonComponent } from 'ng-zorro-antd/button'
import { NzIconDirective } from 'ng-zorro-antd/icon'

@Component({
	selector: 'aiomed-clock-update-modal',
	standalone: true,
	imports: [NgTemplateOutlet, NzButtonComponent, NzIconDirective],
	templateUrl: './clock-update-modal.component.html',
	styleUrl: './clock-update-modal.component.scss'
})
export class ClockUpdateModalComponent {
	@Output('closeModal') closeModalEmitter = new EventEmitter()
}
