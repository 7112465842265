<section class='clock-update'>
  <div class='clock-update__container'>
    <div class='clock-update__title-box'>
      <i nz-icon nzType='icons:clock-update'></i>
      <div class='title'>Clock update required</div>
    </div>
    <div class='text'>
      Your computer's clock is not set correctly. Please contact IT support to update your system clock. In the
      meantime, please access our system from another computer
    </div>
    <div class='clock-update__button-box'>
      <button (click)='closeModalEmitter.emit(false)' nz-button
              nzType='primary'>
        OK
      </button>
    </div>
  </div>
</section>
