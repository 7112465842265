<section [class.has-focus]='hasFocus' [class.tablet]='isMobile' class='screen-saver'>
  <div class='screen-saver__container'>
    @if (isShowPreScreenSaverMode) {
      <div
        (click)='focusInitialPin()' (mousemove)='focusInitialPin()'
        [class.blue]='screenBlockedStatus === ScreenBlockedStatus.BlueBlocked'
        [class.red]='screenBlockedStatus === ScreenBlockedStatus.RedBlocked'
        class='screen-blocked'>
        AiOmed
      </div>
    }
    @if (isAuthenticated) {
      <i class='screen-saver__icon' nz-icon nzType='images:AiOmed_logo_small'></i>
      <div class='screen-saver__text'>Enter pin code</div>
      <form (keydown)='handlerKeydownSubmit($event)' (ngSubmit)='onSubmit()' [formGroup]='pinForm'
            class='screen-saver__form'>
        <div class='screen-saver__form-container'>
          <input #pin0 (blur)='onBlur($event, 0)' (focus)='onFocus($event, 0)' (input)='onInput($event, 0)'
                 (keydown)='onKeyDown($event, 0)' [class.error]='!!errorMessage'
                 formControlName='pin0'
                 inputmode='numeric' maxlength='1'
                 type='text'>
          <input #pin1 (blur)='onBlur($event, 1)' (focus)='onFocus($event, 1)' (input)='onInput($event, 1)'
                 (keydown)='onKeyDown($event, 1)' [class.error]='!!errorMessage'
                 formControlName='pin1'
                 inputmode='numeric' maxlength='1'
                 type='text'>
          <input #pin2 (blur)='onBlur($event, 2)' (focus)='onFocus($event, 2)' (input)='onInput($event, 2)'
                 (keydown)='onKeyDown($event, 2)' [class.error]='!!errorMessage'
                 formControlName='pin2'
                 inputmode='numeric' maxlength='1'
                 type='text'>
          <input #pin3 (blur)='onBlur($event, 3)' (focus)='onFocus($event, 3)' (input)='onInput($event, 3)'
                 (keydown)='onKeyDown($event, 3)' [class.error]='!!errorMessage'
                 formControlName='pin3'
                 inputmode='numeric' maxlength='1'
                 type='text'>
        </div>
        @if (errorMessage) {
          <div class='error'>
            <i nz-icon nzType='icons:warning'></i> {{ errorMessage }}
          </div>
        }
        @if (isShowButton) {
          <button type='submit'>Submit</button>
        }
      </form>
    }
  </div>
</section>
