import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'aiomed-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportIssueComponent {
  @Input() isVisible: boolean = false;
  @Output() cancelClick = new EventEmitter<boolean>();
  @Output() confirmClick = new EventEmitter<string>();

  inputValue: string = '';

  handlerCancelClick(): void {
		this.cancelClick.emit(false);
    this.inputValue = '';
	}

  handlerOkClick(): void {
    if (!this.inputValue.length) return;
		this.confirmClick.emit(this.inputValue);
    this.inputValue = '';
	}
}
