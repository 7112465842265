import { AlertItem } from "../types/alert-item.type";

export const ALERT_ITEMS: AlertItem = {
  alerted: {
    label: 'Alerted',
    iconName: 'aio:alert',
    iconTheme: 'outline',
  },
  "alerted-devices": {
    label: 'Alerted devices',
    iconName: 'aio:alerted-devices',
    iconTheme: 'outline',
  },
  "follow-up": {
    label: 'Follow-ups',
    iconName: 'aio:followup',
    iconTheme: 'fill',
  },
  overdue: {
    label: 'Overdue',
    iconName: 'clock-circle',
    iconTheme: 'fill',
  },
  patients: {
    label: 'Total Patients',
    iconName: 'aio:patients',
    iconTheme: 'fill',
  },
  tasks: {
    label: 'Tasks',
    iconName: 'aio:tasks-list',
    iconTheme: 'fill',
  },
  ongoing: {
    label: 'Ongoing',
    iconName: 'aio:ongoing',
    iconTheme: 'fill',
  },
  muted: {
    label: 'Muted',
    iconName: 'aio:mute',
    iconTheme: 'fill',
  },
  'not-connected': {
    label: 'Disconnected',
    iconName: 'aio:not-connected',
    iconTheme: 'fill',
  },
  'total-devices': {
    label: 'Total Devices',
    iconName: 'aio:header-total',
    iconTheme: 'fill'
  },
  'total-alerts': {
    label: 'Total Alerts',
    iconName: 'aio:alert',
    iconTheme: 'outline',
  },
};