import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import { createEntityCollections } from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, Subscription, tap } from 'rxjs'
import { BackendService } from '../../shared/services/backend.service'
import {
	CreateTreatmentPlanDTO,
	TreatmentPlanDTO,
	UpdateTreatmentPlanDTO
} from '../../shared/model/treatment-plan'
import { NotificationService } from '../../shared/services/notification.service'
import { StoreEventsService } from '../store-events.service'
import { DepartmentFilter } from '../../shared/model/departments.model'
import { DepartmentState } from '../department/department.state'
import { PatientState } from '../patient/patient.state'

export const treatmentPlanFeatureName = 'treatmentPlan'

@StateRepository()
@State<CollatableEntityCollections<TreatmentPlanDTO>>({
	name: treatmentPlanFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class TreatmentPlanState extends CollatableEntityCollectionsRepository<
	TreatmentPlanDTO,
	EntityCollation
> {
	subscriptionBackendUpdates$: Subscription
	subscriptionGetAllTreatmentPlan$: Subscription
	private treatmentPlanStateSubscription: Subscription

	constructor(
		private backendService: BackendService,
		private actions: Actions,
		private ntfService: NotificationService,
		private storeEvents: StoreEventsService,
		private departmentState: DepartmentState,
		private patientState: PatientState,
	) {
		super()
	}

	@Selector()
	public static treatmentPlans(
		state: CollatableEntityCollections<TreatmentPlanDTO>
	): TreatmentPlanDTO[] {
		return Object.values(state.entities)
	}

	updateWithModifiedTreatmentPlan(patientIds?: string[]) {
		return this.backendService.findAllTreatmentPlans(patientIds).pipe(
			tap((treatmentPlan) => {
				if (!treatmentPlan) {
					return;
				}
				this.upsertMany(treatmentPlan);
			})
		)
	}

	public override ngxsOnInit() {

		this.storeEvents.patientsModifiedOrDepartmentChange$.pipe(
			tap(() => {
				if (this.treatmentPlanStateSubscription) this.treatmentPlanStateSubscription.unsubscribe();
				this.treatmentPlanStateSubscription = this.updateWithModifiedTreatmentPlan(this.selectCurrentDepartmentPatientIds()).subscribe();
			})
		).subscribe();


		this.storeEvents.logout$
			.pipe(
				tap(() => {
					this.reset()
					if (this.treatmentPlanStateSubscription)
						this.treatmentPlanStateSubscription.unsubscribe()
				})
			)
			.subscribe()
	}

	@DataAction()
	deleteTreatmentPlan(@Payload('id') id: string) {
		return this.backendService.deleteTreatmentPlan(id).pipe(
			tap(() => {
				this.removeOne(id)
				this.ntfService.success(`Treatment Plan have been deleted`)
			})
		)
	}

	@DataAction()
	updateTreatmentPlan(@Payload('id') id: string, data: UpdateTreatmentPlanDTO) {
		return this.backendService.updateTreatmentPlan(id, data).pipe(
			tap((data) => {
				this.upsertOne(data)
				this.ntfService.success(`Treatment Plan have been updated`)
			})
		)
	}

	@DataAction()
	createTreatmentPlan(data: CreateTreatmentPlanDTO) {
		return this.backendService.addTreatmentPlan(data).pipe(
			tap((data) => {
				this.upsertOne(data)
				this.ntfService.success(`Treatment Plan have been created`)
			})
		)
	}

	private selectCurrentDepartmentPatientIds(): string[] {
		let patients = Object.values(this.patientState.entities);
		const department = this.departmentState.getState().currentDepartment;

		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				// @ts-ignore
				(patient) =>
					patient.department && patient.department.id === department.id && patient.enabled && patient.emrid
			)
		}
		return patients.map(p => p.id);
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(): Observable<void> {
		return EMPTY
	}
}
