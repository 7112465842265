import { DataAction, StateRepository } from '@angular-ru/ngxs/decorators'
import { State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { EMPTY, Observable } from 'rxjs'
import { NotificationDTO } from '../../shared/model/norification.model'
import { AngularFireMessaging } from '@angular/fire/compat/messaging'
import { UserState } from '../user/user.state'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'

export const notificationFeatureName = 'notification'

@StateRepository()
@State<NotificationDTO>({
	name: notificationFeatureName
})
@Injectable()
export class NotificationState extends NgxsDataRepository<NotificationDTO> {
	constructor(
		private angularFireMessaging: AngularFireMessaging,
		private userState: UserState,
		private notification: NzNotificationService
	) {
		super()
	}

	@DataAction()
	public requestPermission(): void {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.register('./firebase-messaging-sw.js')
				.then(() => {
					Notification.requestPermission().then((result) => {
						if (result === 'granted') {
							this.getMessageToken()
						} else if (result === 'denied') {
							this.userState.updateUserProfile(null)
						}
					})
				})
				.catch(function (err) {
					console.log('Service worker registration failed, error:', err)
				})
		}
	}

	@DataAction()
	public receiveMessage() {
		this.angularFireMessaging.onMessage(({ data, notification }) => {
			new Notification(notification.title, {
				body: notification.body
			})
			// n.onclick = () => this.router.navigateByUrl(`patient/${data.patientId}`)
			this.notification.create(
				'warning',
				`Alert ${notification.title}`,
				notification.body,
				{
					nzDuration: 0,
					nzPauseOnHover: false
				}
			)
		})
	}

	getMessageToken(): void {
		const messagingToken = this.userState.getState().messagingToken
		this.angularFireMessaging.getToken.subscribe((token) => {
			if (!token || (messagingToken && messagingToken === token)) {
				return
			} else if (!messagingToken && this.userState.snapshot.userProfileId) {
				this.userState.updateUserProfile(token)
			} else if (messagingToken && this.userState.snapshot.userProfileId) {
				this.userState.updateUserProfile(token)
				return
			} else if (!this.userState.snapshot.userProfileId) {
				this.userState.createUserProfile(token)
			}
		})
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	protected setPaginationSetting(type: string | undefined): Observable<void> {
		return EMPTY
	}
}
