import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Select, Selector, State, Store } from '@ngxs/store'
import { Injectable } from '@angular/core'
import {
	BehaviorSubject,
	catchError,
	EMPTY,
	exhaustMap,
	filter,
	interval,
	map,
	merge,
	Observable,
	of,
	Subject,
	Subscription,
	switchMap,
	take,
	tap,
	timer
} from 'rxjs'
import {
	PatientDTO,
	PatientInterface,
	PatientVitalsTakenGroup
} from '../../shared/model/patient'
import { MeasurementState } from '../measurement/measurement.state'
import moment from 'moment'
import { PatientState } from '../patient/patient.state'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { cloneDeep, isNull, round, uniq } from 'lodash-es'
import {
	CheckShiftSentEmrInformationInterface,
	ManualVitalsInterface,
	ModeType,
	PatientVItalInsightsObservationFields,
	PatientVItalObservationFields,
	PatientVitalsInterface,
	ReportInterface,
	ReportMode,
	ReportsVitalsInterface,
	ReportType,
	ReportVitalsFields,
	SentEmrInformation,
	VitalsFields,
	VitalStatusIcon,
	VitalStatusIndicator
} from '../../shared/model/report.model'
import { BackendService } from '../../shared/services/backend.service'
import { PccState } from '../pcc/pcc.state'
import { DeviceState } from '../device/device.state'
import { TreatmentPlanDTO } from '../../shared/model/treatment-plan'
import { ExportAllInterface } from '../../shared/model/export'
import { ExportState } from '../export/export.state'
import { PatientExportDTO } from '../../shared/model/pcc.model'
import { NotificationService } from '../../shared/services/notification.service'
import { DepartmentState } from '../department/department.state'
import { entitiesFilter } from '../../core/helpers/filter'
import {
	DeviceCriticalStatus,
	DeviceDTO,
	DeviceInterface,
	DeviceModel
} from '../../shared/model/device.model'
import { StoreEventsService } from '../store-events.service'
import { DepartmentFilter } from '../../shared/model/departments.model'
import { checkEmrRules } from '../../core/helpers/check-emr-rules'
import {
	ObservationField,
	ObservationFields,
	ObservationLatest,
	PatientObservationDTO
} from '../../shared/model/patient-observation'
import { RootStore } from '../root-store'
import { ShiftPlanerState } from '../shift-planer/shift-planer.state'
import { SHIFT_RANGES, ShiftType } from '../../shared/model/shift-planer.model'
import { combineTreatmentPlans } from '../../core/helpers/combine-treatment-plans'
import { checkVitalsToTreatmentPlan } from '../../core/helpers/check-vitals-to-treatment-plan'
import { WebSocketService } from '../../shared/services/web-socket.service'
import { AuthState } from '../auth/auth.state'
import {
	forceReadErrorCode,
	forceReadStatus,
	NewReadSocketModel
} from '../../shared/model/new-read-socket-model'
import { environment } from '../../environments/environment'
import { WarningTitle } from '../../shared/constants/warning.constant'
import { isTakingTodayVitalsRequired } from '../../core/helpers/is-taking-today-vitals-required'
import { UserState } from '../user/user.state'
import { checkShiftTime } from '../../core/helpers/check-shift-time'
import { isInShiftRange } from '../../core/helpers/is-in-shift-range'
import { DepartmentDTO } from '../../shared/model/permission.model'
import { PreferenceState } from '../preference/preference.state'
import { checkVitalToEmrRules } from '../../core/helpers/check-vital-emr-rules'
import { checkFreshVitalCurrentShift } from '../../core/helpers/check-fresh-vital-current-shift'
import { checkIsManual } from '../../core/helpers/check-is-manual'
import { InsightDTO } from '../../shared/model/insight.model'
import { extractSpecificInsightBySubject } from '../../core/helpers/extract-specific-insight-by-subject'
import { AlertSubject } from '../../shared/model/alert'
import { abnormalMissingFilter } from '../../core/helpers/abnormal-missing-filter'
import { CheckMissingVital } from '../../core/helpers/check-missing-vital'
import { DeviceDetectorService } from 'ngx-device-detector'
import { bodyTemperatureSetting } from '../../core/helpers/body-temperature-setting'

export const reportFeatureName = 'report'

@StateRepository()
@State<ReportInterface>({
	name: reportFeatureName,
	defaults: {
		requestPatientsVitals: [],
		requestVitals: [],
		monitorPatientIds: [],
		forceReadDevicesStatus: [],
		textFilter: '',
		isAbnormalMissingFilter: false,
		type: ReportType.CurrentShift,
		mode: ModeType.Latest,
		loading: false,
		isMeasurementSending: false,
		isForceReadProcess: false,
		currentShift: null
	}
})
@Injectable()
export class ReportState extends NgxsDataRepository<ReportInterface> {
	reportsVitalsData: ReportsVitalsInterface = {
		patientActions: []
	}
	updatePatientsIds: string[] = []
	subscriptionNewReadSocket$: Subscription
	timeout: any
	lastPatientReportTime: any = {}
	@Select(UserState.isUserRN)
	isUserRN$: Observable<boolean>
	showed30MinutesMessage = false
	showed10MinutesMessage = false
	private reportStateSubscription: Subscription
	private checkTimeSubscription: Subscription
	private successfullySentCurrentShiftPatientAllMeasurements$ =
		new BehaviorSubject<Partial<PatientVitalsInterface> | null>(null)
	public readonly successfullySentCurrentShiftPatientAllMeasurementsObs$ =
		this.successfullySentCurrentShiftPatientAllMeasurements$.asObservable()
	private currentShiftListenerSubscription: Subscription
	private readonly ABNORMAL_VALUES_MILLISECONDS_TIME_UPDATE = 600 * 1000
	private readonly isMobile = this.preferenceState.isMobile
	private showMessage = new Subject<boolean>()
	showMessage$ = this.showMessage.asObservable()
	private messageClosedByUser = false

	constructor(
		private patientState: PatientState,
		private exportState: ExportState,
		private deviceState: DeviceState,
		private measurementState: MeasurementState,
		private backendService: BackendService,
		private ntfService: NotificationService,
		private pccState: PccState,
		private departmentState: DepartmentState,
		private actions: Actions,
		private storeEvents: StoreEventsService,
		private store: Store,
		private webSocketService: WebSocketService,
		private authState: AuthState,
		private shiftPlannerState: ShiftPlanerState,
		private preferenceState: PreferenceState,
		private deviceService: DeviceDetectorService
	) {
		super()
	}

	@Selector()
	public static isMeasurementSending(state: ReportInterface): boolean {
		return state.isMeasurementSending
	}

	@Selector([
		PatientState.reportPatients,
		ExportState.patientsAllExports,
		DeviceState.reportDevices,
		DepartmentState.department
	])
	public static reportAllPatientLength(
		state: ReportInterface,
		reportPatients: Partial<PatientInterface>[]
	): number {
		if (!reportPatients) return 0
		return reportPatients.filter((p) => p.enabled).length
	}

	@Selector()
	public static reportIsAbnormalMissingFilter(state: ReportInterface): boolean {
		return state.isAbnormalMissingFilter
	}

	@Selector([
		PatientState.reportPatients,
		ExportState.patientsAllExports,
		DeviceState.reportDevices,
		ShiftPlanerState.currentShift,
		DepartmentState.department
	])
	public static isSentPatientsToEMR(
		state: ReportInterface,
		reportPatients: Partial<PatientInterface>[],
		patientsAllExports: ExportAllInterface[],
		devices: DeviceInterface[],
		currentShift: ShiftType,
		department: DepartmentDTO
	): boolean {
		return !!ReportState.hydrate(
			state.monitorPatientIds,
			state.requestPatientsVitals,
			entitiesFilter(
				state.textFilter,
				reportPatients.filter((p) => p.enabled)
			),
			patientsAllExports,
			devices.filter((d) => d.patient && d.patient.id),
			state.forceReadDevicesStatus,
			state.type,
			department,
			currentShift
		).filter((p) => p.sentToEMR).length
	}

	@Selector([
		PatientState.reportPatients,
		ExportState.patientsAllExports,
		DeviceState.reportDevices,
		ShiftPlanerState.currentShift,
		DepartmentState.department
	])
	public static report(
		state: ReportInterface,
		reportPatients: Partial<PatientInterface>[],
		patientsAllExports: ExportAllInterface[],
		devices: DeviceInterface[],
		currentShift: ShiftType,
		department: DepartmentDTO
	): Partial<PatientVitalsInterface>[] {
		const data = ReportState.hydrate(
			state.monitorPatientIds,
			state.requestPatientsVitals,
			entitiesFilter(
				state.textFilter,
				reportPatients.filter((p) => p.enabled)
			),
			patientsAllExports,
			devices.filter((d) => d.patient && d.patient.id),
			state.forceReadDevicesStatus,
			state.type,
			department,
			currentShift
		)
		return !data
			? []
			: abnormalMissingFilter(data, state.isAbnormalMissingFilter)
	}

	@Selector()
	public static forceReadProcess(state: ReportInterface) {
		return state.isForceReadProcess
	}

	@Selector([
		PatientState.reportPatients,
		ExportState.patientsAllExports,
		DeviceState.reportDevices,
		DepartmentState.department
	])
	public static reportShift(
		state: ReportInterface,
		reportPatients: Partial<PatientInterface>[],
		patientsAllExports: ExportAllInterface[],
		devices: DeviceInterface[],
		department: DepartmentDTO
	): PatientVitalsInterface[] {
		const data = ReportState.hydrate(
			state.monitorPatientIds,
			state.requestPatientsVitals,
			entitiesFilter(
				state.textFilter,
				reportPatients.filter((p) => p.enabled)
			),
			patientsAllExports,
			devices.filter((d) => d.patient && d.patient.id),
			state.forceReadDevicesStatus,
			state.type,
			department
		)
		return !data ? [] : data
	}

	@Selector()
	public static textFilter(state: ReportInterface) {
		return state.textFilter
	}

	@Selector([
		PatientState.reportPatients,
		ExportState.patientsAllExports,
		DeviceState.reportDevices,
		RootStore.pccSentPatientVitals,
		ShiftPlanerState.currentShift,
		DepartmentState.department
	])
	public static reportAbnormalOrBlank(
		state: ReportInterface,
		reportPatients: Partial<PatientInterface>[],
		patientsAllExports: ExportAllInterface[],
		devices: DeviceInterface[],
		pccSentPatientVitals: string[],
		currentShift: ShiftType,
		department: DepartmentDTO
	): PatientVitalsInterface[] {
		const data = ReportState.hydrate(
			state.monitorPatientIds,
			state.requestPatientsVitals,
			// @ts-ignore
			pccSentPatientVitals.length
				? reportPatients.filter(
						(p) => p.enabled && !pccSentPatientVitals.find((id) => id === p.id)
				  )
				: reportPatients.filter((p) => p.enabled),
			patientsAllExports,
			devices.filter((d) => d.patient && d.patient.id),
			state.forceReadDevicesStatus,
			state.type,
			department,
			currentShift
		)
		return !data
			? []
			: data.filter((p) => {
					return (
						p.checked &&
						(this.hasAbnormal(p) ||
							!!abnormalMissingFilter([p], true) ||
							p.checkShiftSentEmrInformation?.status !== 'COMPLETED')
					)
			  })
	}

	private static checkingLastEmrTime(data: PatientExportDTO) {
		const differenceInMinutes = moment().diff(
			moment(data.creationTime),
			'minutes',
			true
		)
		return differenceInMinutes < 1
	}

	private static requestPatientsVitalsSetting(
		requestPatientsVitals: string[],
		patients: PatientVitalsInterface[],
		reportsVitalsData: ReportsVitalsInterface
	) {
		requestPatientsVitals.forEach((id) => {
			const patient = patients.find((p) => p.id === id)
			if (patient) {
				if (
					patient.vitals &&
					!Object.values(patient.vitals).filter((v) => v).length
				) {
					const dataPatientIdx = reportsVitalsData.patientActions.findIndex(
						(d) => d.patientId === patient.id
					)
					if (dataPatientIdx !== -1) {
						reportsVitalsData.patientActions[dataPatientIdx].treatmentActions =
							[ReportVitalsFields.MeasureAllVitals]
					} else {
						reportsVitalsData.patientActions.push({
							patientId: patient.id,
							treatmentActions: [ReportVitalsFields.MeasureAllVitals]
						})
					}
				} else {
					const data: {
						patientId: string
						treatmentActions: string[]
					} = {
						patientId: patient.id,
						treatmentActions: []
					}
					if (!patient.vitals?.bodyTemperature) {
						data.treatmentActions = uniq([
							...data.treatmentActions,
							ReportVitalsFields.MeasureAllVitals
						])
					}
					if (!patient.vitals?.heartRate) {
						data.treatmentActions = uniq([
							...data.treatmentActions,
							ReportVitalsFields.MeasureAllVitals
						])
					}
					if (!patient.vitals?.respirationRate) {
						data.treatmentActions = uniq([
							...data.treatmentActions,
							ReportVitalsFields.MeasureAllVitals
						])
					}
					if (
						!patient.vitals?.systolicPressure ||
						!patient.vitals?.diastolicPressure
					) {
						data.treatmentActions = uniq([
							...data.treatmentActions,
							ReportVitalsFields.MeasureAllVitals
						])
					}
					if (!patient.vitals?.spo2) {
						data.treatmentActions = uniq([
							...data.treatmentActions,
							ReportVitalsFields.MeasureAllVitals
						])
					}
					const dataPatientIdx = reportsVitalsData.patientActions.findIndex(
						(d) => d.patientId === patient.id
					)

					if (dataPatientIdx !== -1) {
						reportsVitalsData.patientActions[dataPatientIdx].treatmentActions =
							uniq([
								...reportsVitalsData.patientActions[dataPatientIdx]
									.treatmentActions,
								...data.treatmentActions
							])
					} else {
						if (!data.treatmentActions.length) return
						reportsVitalsData.patientActions.push(data)
					}
				}
			}
		})
	}

	private static hasAbnormal(patient: PatientVitalsInterface): boolean {
		const emrRules = cloneDeep(checkEmrRules)
		emrRules.set(
			!patient.patientAlertRules
				? patient.defaultAlertRules
				: patient.patientAlertRules
		)
		let hasAbnormal = false

		if (Object.values(patient.vitals).filter((v) => v).length) {
			if (
				patient.vitals?.systolicPressure &&
				patient.vitals?.systolicPressure.value &&
				!(
					(patient.vitals?.systolicPressure.value as number) <=
						emrRules.get(ObservationFields.SystolicPressure).max &&
					(patient.vitals?.systolicPressure.value as number) >=
						emrRules.get(ObservationFields.SystolicPressure).min
				)
			) {
				hasAbnormal = true
			}

			if (
				patient.vitals?.diastolicPressure &&
				patient.vitals?.diastolicPressure.value &&
				!(
					(patient.vitals?.diastolicPressure.value as number) <=
						emrRules.get(ObservationFields.DiastolicPressure).max &&
					(patient.vitals?.diastolicPressure.value as number) >=
						emrRules.get(ObservationFields.DiastolicPressure).min
				)
			) {
				hasAbnormal = true
			}

			if (
				patient.vitals?.heartRate &&
				patient.vitals?.heartRate.value &&
				!(
					(patient.vitals?.heartRate.value as number) <=
						emrRules.get(ObservationFields.HeartRate).max &&
					(patient.vitals?.heartRate.value as number) >=
						emrRules.get(ObservationFields.HeartRate).min
				)
			) {
				hasAbnormal = true
			}

			if (
				patient.vitals?.spo2 &&
				patient.vitals?.spo2.value &&
				!(
					(patient.vitals?.spo2.value as number) <=
						emrRules.get(ObservationFields.SpO2).max &&
					(patient.vitals?.spo2.value as number) >=
						emrRules.get(ObservationFields.SpO2).min
				)
			) {
				hasAbnormal = true
			}

			if (
				patient.vitals?.bodyTemperature &&
				patient.vitals?.bodyTemperature.value &&
				!(
					round(patient.vitals?.bodyTemperature.value as number, 1) <=
						emrRules.get(ObservationFields.BodyTemperature).max &&
					round(patient.vitals?.bodyTemperature.value as number, 1) >=
						emrRules.get(ObservationFields.BodyTemperature).min
				)
			) {
				hasAbnormal = true
			}

			if (
				patient.vitals?.respirationRate &&
				patient.vitals?.respirationRate.value &&
				!(
					(patient.vitals?.respirationRate.value as number) <=
						emrRules.get(ObservationFields.RespirationRate).max &&
					(patient.vitals?.respirationRate.value as number) >=
						emrRules.get(ObservationFields.RespirationRate).min
				)
			) {
				hasAbnormal = true
			}
		}

		return hasAbnormal
	}

	private static setAllToEmrSetting(
		patient: PatientVitalsInterface,
		exports: PatientExportDTO[]
	) {
		const emrRules = cloneDeep(checkEmrRules)
		emrRules.set(
			!patient.patientAlertRules
				? patient.defaultAlertRules
				: patient.patientAlertRules
		)
		const patientExport: PatientExportDTO | undefined = exports.find(
			(e: PatientExportDTO) => e.observedPatient === patient.id
		)
		let data: {
			observedPatient?: string
			systolicPressure?: number
			diastolicPressure?: number
			heartRate?: number
			respirationRate?: number
			spo2?: number
			bodyTemperature?: number
			bloodGlucose?: number
			bloodPressureMethod?: string
			heartRateMethod?: string
			spo2Method?: string
			bodyTemperatureMethod?: string
		} = {}

		if (this.hasAbnormal(patient)) {
			return null
		}

		if (Object.values(patient.vitals).filter((v) => v).length) {
			if (
				patient.vitals?.systolicPressure &&
				patient.vitals?.systolicPressure?.value &&
				(patient.vitals?.systolicPressure?.value as number) <=
					emrRules.get(ObservationFields.SystolicPressure).max &&
				(patient.vitals?.systolicPressure.value as number) >=
					emrRules.get(ObservationFields.SystolicPressure).min
			) {
				data.systolicPressure = patient.vitals?.systolicPressure
					?.value as number
			}

			if (
				patient.vitals?.diastolicPressure &&
				patient.vitals?.diastolicPressure?.value &&
				(patient.vitals?.diastolicPressure.value as number) <=
					emrRules.get(ObservationFields.DiastolicPressure).max &&
				(patient.vitals?.diastolicPressure.value as number) >=
					emrRules.get(ObservationFields.DiastolicPressure).min
			) {
				data.diastolicPressure = patient.vitals?.diastolicPressure
					?.value as number
			}

			if (
				patient.vitals?.heartRate &&
				patient.vitals?.heartRate?.value &&
				(patient.vitals?.heartRate.value as number) <=
					emrRules.get(ObservationFields.HeartRate).max &&
				(patient.vitals?.heartRate.value as number) >=
					emrRules.get(ObservationFields.HeartRate).min
			) {
				data.heartRate = patient.vitals?.heartRate?.value as number
			}

			if (
				patient.vitals?.spo2 &&
				patient.vitals?.spo2?.value &&
				(patient.vitals?.spo2.value as number) <=
					emrRules.get(ObservationFields.SpO2).max &&
				(patient.vitals?.spo2.value as number) >=
					emrRules.get(ObservationFields.SpO2).min
			) {
				data.spo2 = patient.vitals?.spo2?.value as number
			}

			if (
				patient.vitals?.bodyTemperature &&
				patient.vitals?.bodyTemperature?.value &&
				round(patient.vitals?.bodyTemperature?.value as number, 1) <=
					emrRules.get(ObservationFields.BodyTemperature).max &&
				round(patient.vitals?.bodyTemperature?.value as number, 1) >=
					emrRules.get(ObservationFields.BodyTemperature).min
			) {
				data.bodyTemperature = patient.vitals?.bodyTemperature?.value as number
			}

			if (
				patient.vitals?.respirationRate &&
				patient.vitals?.respirationRate?.value &&
				(patient.vitals?.respirationRate.value as number) <=
					emrRules.get(ObservationFields.RespirationRate).max &&
				(patient.vitals?.respirationRate.value as number) >=
					emrRules.get(ObservationFields.RespirationRate).min
			) {
				data.respirationRate = patient.vitals?.respirationRate?.value as number
			}
		}

		data.bloodPressureMethod = !patientExport?.bloodPressureMethod
			? 'Sitting r/arm'
			: patientExport?.bloodPressureMethod
		data.heartRateMethod = !patientExport?.heartRateMethod
			? 'regular'
			: patientExport?.heartRateMethod
		data.spo2Method = !patientExport?.spo2Method
			? 'Room Air'
			: patientExport?.spo2Method
		data.bodyTemperatureMethod = !patientExport?.bodyTemperatureMethod
			? 'Forehead (non-contact)'
			: patientExport?.bodyTemperatureMethod

		if (
			(!data.spo2 &&
				(patient.checkShiftSentEmrInformation?.spo2 ||
					patient.checkShiftSentEmrInformation?.spo2 === false)) ||
			(!data.diastolicPressure &&
				(patient.checkShiftSentEmrInformation?.bp ||
					patient.checkShiftSentEmrInformation?.bp === false)) ||
			(!data.systolicPressure &&
				(patient.checkShiftSentEmrInformation?.bp ||
					patient.checkShiftSentEmrInformation?.bp === false)) ||
			(!data.heartRate &&
				(patient.checkShiftSentEmrInformation?.hr ||
					patient.checkShiftSentEmrInformation?.hr === false)) ||
			(!data.bodyTemperature &&
				(patient.checkShiftSentEmrInformation?.bt ||
					patient.checkShiftSentEmrInformation?.bt === false)) ||
			(!data.bloodGlucose &&
				(patient.checkShiftSentEmrInformation?.bg ||
					patient.checkShiftSentEmrInformation?.bg === false))
		) {
			return null
		}

		return { observedPatient: patient.id, ...data }
	}

	private static setTreatmentPlanSetting(treatmentPlan: TreatmentPlanDTO[]): {
		needToCheckLength: number
		tmpObj: CheckShiftSentEmrInformationInterface
	} {
		let needToCheckLength = 0
		const tmpObj: CheckShiftSentEmrInformationInterface = {
			status: 'NEED_EXPORT_TO_EMR'
		}

		treatmentPlan.forEach((t) => {
			if (t.BT) {
				needToCheckLength += 1
				tmpObj.bt = false
				tmpObj.btStatus = 'NEED_SEND_TO_EMR'
			}
			if (t.BG) {
				needToCheckLength += 1
				tmpObj.bg = false
				tmpObj.bgStatus = 'NEED_SEND_TO_EMR'
			}
			if (t.SPO2) {
				needToCheckLength += 1
				tmpObj.spo2 = false
				tmpObj.spo2Status = 'NEED_SEND_TO_EMR'
			}
			if (t.BP) {
				needToCheckLength += 1
				tmpObj.bp = false
				tmpObj.bpStatus = 'NEED_SEND_TO_EMR'
			}
			if (t.HR) {
				needToCheckLength += 1
				tmpObj.hr = false
				tmpObj.hrStatus = 'NEED_SEND_TO_EMR'
			}
			if (t.RR) {
				needToCheckLength += 1
				tmpObj.rr = false
				tmpObj.rrStatus = 'NEED_SEND_TO_EMR'
			}
		})

		return {
			needToCheckLength,
			tmpObj
		}
	}

	private static vitalsSubmissionHydrate(
		patients: PatientInterface[],
		patientsAllExports: ExportAllInterface[],
		department: DepartmentDTO
	) {
		return patients.map((p) => {
			const patientAllExport = patientsAllExports.find(
				(e) => e.patientId === p.id
			)
			const measurement = ReportState.checkCurrentShiftReportTime(
				department,
				ReportType.CurrentShift,
				p.measurement
			)
			const { vitals } = ReportState.toPatientVitals(
				p,
				measurement,
				department,
				ReportType.CurrentShift
			)
			const checkShiftSentEmrInformation = p.treatmentPlan
				? ReportState.toReportShiftCheckVitalsToTreatmentPlan(
						p.treatmentPlan.filter(
							(t) => !t.endTime || new Date(t.endTime) > new Date()
						),
						// @ts-ignore
						!patientAllExport ? { patientId: p.id, data: [] } : patientAllExport
				  )
				: null
		})
	}

	private static hydrate(
		monitorPatientIds: string[],
		requestPatientsVitals: string[],
		patients: PatientInterface[],
		patientsAllExports: ExportAllInterface[],
		devices: DeviceInterface[],
		forceReadDevicesStatus: NewReadSocketModel[],
		type: ReportType,
		department: DepartmentDTO,
		currentShift?: ShiftType
	): PatientVitalsInterface[] {
		return patients.map((p) => {
			const currentDevices = devices
				.filter((d) => d.model === DeviceModel.BiobeatWatch)
				.find((d) => d.patient.id === p.id)
			const patientAllExport = patientsAllExports.find(
				(e) => e.patientId === p.id
			)

			const patientForceReadDevicesStatus = forceReadDevicesStatus.filter(
				(fr) => fr.deviceId === currentDevices?.id
			)
			const checkShiftSentEmrInformation = p.treatmentPlan
				? ReportState.toReportShiftCheckVitalsToTreatmentPlan(
						p.treatmentPlan.filter(
							(t) => !t.endTime || new Date(t.endTime) > new Date()
						),
						// @ts-ignore
						!patientAllExport ? { patientId: p.id, data: [] } : patientAllExport
				  )
				: null
			const measurement = ReportState.checkCurrentShiftReportTime(
				department,
				type,
				p.measurement
			)
			const { vitals } = ReportState.toPatientVitals(
				p,
				measurement,
				department,
				type
			)

			p.requiredVitals = {}
			p.hasSomeVitalsTaken = false
			let isAnyVitalsMissing: boolean = false

			p.treatmentPlan =
				p.treatmentPlan && p.treatmentPlan.length
					? [
							...p?.treatmentPlan.filter(
								(tp) =>
									!tp?.endTime ||
									(tp.endTime && tp.endTime > new Date().toISOString())
							)
					  ]
					: []

			let vitalsTakenGroup: PatientVitalsTakenGroup =
				!p?.treatmentPlan || !p.treatmentPlan.length
					? PatientVitalsTakenGroup.NotRequired
					: !p.treatmentPlan.some(
							(tp) =>
								tp.frequency?.includes(currentShift!) &&
								isTakingTodayVitalsRequired(tp)
					  )
					? PatientVitalsTakenGroup.NotRequired
					: p.treatmentPlan.some((tp) => {
							if (!tp.additionalInformation) {
								return false
							}

							tp.additionalInformation.forEach((vital) => {
								const isTodayRequired = isTakingTodayVitalsRequired(tp)
								if (
									(vital === 'HR' &&
										tp.frequency?.includes(currentShift!) &&
										isTodayRequired &&
										!p?.measurement?.latestPerVital?.heart_rate ||
										vitals.heartRate?.isOldVital) ||
									(vital === 'SPO2' &&
										tp.frequency?.includes(currentShift!) &&
										isTodayRequired &&
										!p?.measurement?.latestPerVital?.spo2 ||
										vitals.spo2?.isOldVital) ||
									(vital === 'BG' &&
										tp.frequency?.includes(currentShift!) &&
										isTodayRequired &&
										!p?.measurement?.latestPerVital?.bloodGlucose ||
										vitals.bloodGlucose?.isOldVital) ||
									(vital === 'BT' &&
										tp.frequency?.includes(currentShift!) &&
										isTodayRequired &&
										!p?.measurement?.latestPerVital?.body_temperature ||
										vitals.bodyTemperature?.isOldVital) ||
									(vital === 'RR' &&
										tp.frequency?.includes(currentShift!) &&
										isTodayRequired &&
										!p?.measurement?.latestPerVital?.respiration_rate ||
										vitals.respirationRate?.isOldVital) ||
									(vital === 'BP' &&
										tp.frequency?.includes(currentShift!) &&
										isTodayRequired &&
											!p?.measurement?.latestPerVital?.systolicPressure &&
											!p?.measurement?.latestPerVital?.diastolicPressure ||
											vitals.systolicPressure?.isOldVital ||
											vitals.diastolicPressure?.isOldVital
										)
								) {
									isAnyVitalsMissing = true
								}
							})
							return isAnyVitalsMissing
					  })
					? PatientVitalsTakenGroup.VitalsRound
					: PatientVitalsTakenGroup.Recorded

			if (vitalsTakenGroup !== PatientVitalsTakenGroup.NotRequired) {
				if (
					p?.measurement?.latestPerVital?.heart_rate &&
					!checkFreshVitalCurrentShift(
						p?.measurement?.latestPerVital?.heart_rate.timestamp as string
					)
				) {
					p.hasSomeVitalsTaken = true
				}
				if (
					p?.measurement?.latestPerVital?.spo2 &&
					!checkFreshVitalCurrentShift(
						p?.measurement?.latestPerVital?.spo2.timestamp as string
					)
				) {
					p.hasSomeVitalsTaken = true
				}
				if (
					p?.measurement?.latestPerVital?.bloodGlucose &&
					!checkFreshVitalCurrentShift(
						p?.measurement?.latestPerVital?.bloodGlucose.timestamp as string
					)
				) {
					p.hasSomeVitalsTaken = true
				}
				if (
					p?.measurement?.latestPerVital?.body_temperature &&
					!checkFreshVitalCurrentShift(
						p?.measurement?.latestPerVital?.body_temperature.timestamp as string
					)
				) {
					p.hasSomeVitalsTaken = true
				}
				if (
					p?.measurement?.latestPerVital?.respiration_rate &&
					!checkFreshVitalCurrentShift(
						p?.measurement?.latestPerVital?.respiration_rate.timestamp as string
					)
				) {
					p.hasSomeVitalsTaken = true
				}
				if (
					p?.measurement?.latestPerVital?.systolicPressure &&
					!checkFreshVitalCurrentShift(
						p?.measurement?.latestPerVital?.systolicPressure.timestamp as string
					)
				) {
					p.hasSomeVitalsTaken = true
				}
				p.treatmentPlan.forEach((tp) => {
					const isTodayRequired = isTakingTodayVitalsRequired(tp)
					if (!tp.additionalInformation) {
						return
					}

					tp.additionalInformation.forEach((vital) => {
						if (
							vital === 'HR' &&
							isTodayRequired &&
							tp.frequency?.includes(currentShift!)
						) {
							p.requiredVitals['heart_rate'] = true
						}
						if (
							vital === 'SPO2' &&
							isTodayRequired &&
							tp.frequency?.includes(currentShift!)
						) {
							p.requiredVitals['spo2'] = true
						}
						if (
							vital === 'BG' &&
							isTodayRequired &&
							tp.frequency?.includes(currentShift!)
						) {
							p.requiredVitals['bloodGlucose'] = true
						}
						if (
							vital === 'BT' &&
							isTodayRequired &&
							tp.frequency?.includes(currentShift!)
						) {
							p.requiredVitals['body_temperature'] = true
						}
						if (
							vital === 'RR' &&
							isTodayRequired &&
							tp.frequency?.includes(currentShift!)
						) {
							p.requiredVitals['respiration_rate'] = true
						}
						if (
							vital === 'BP' &&
							isTodayRequired &&
							tp.frequency?.includes(currentShift!)
						) {
							p.requiredVitals['systolicPressure'] = true
							p.requiredVitals['diastolicPressure'] = true
						}
					})
				})
			}

			if (vitalsTakenGroup === PatientVitalsTakenGroup.Recorded) {
				const currentShiftTreatmentPlan = p.treatmentPlan.filter(
					(tp) =>
						tp.frequency?.includes(currentShift!) &&
						isTakingTodayVitalsRequired(tp)
				)
				if (
					currentShiftTreatmentPlan.length &&
					!currentShiftTreatmentPlan.every(
						(tp) => tp.additionalInformation && tp.additionalInformation.length
					)
				) {
					vitalsTakenGroup = PatientVitalsTakenGroup.NotRequired
				}
			}

			if (vitalsTakenGroup === PatientVitalsTakenGroup.NotRequired) {
				if (
					(p?.measurement?.latestPerVital?.heart_rate &&
						!checkFreshVitalCurrentShift(
							p?.measurement?.latestPerVital?.heart_rate.timestamp as string
						)) ||
					(p?.measurement?.latestPerVital?.spo2 &&
						!checkFreshVitalCurrentShift(
							p?.measurement?.latestPerVital?.spo2.timestamp as string
						)) ||
					(p?.measurement?.latestPerVital?.bloodGlucose &&
						!checkFreshVitalCurrentShift(
							p?.measurement?.latestPerVital?.bloodGlucose.timestamp as string
						)) ||
					(p?.measurement?.latestPerVital?.body_temperature &&
						!checkFreshVitalCurrentShift(
							p?.measurement?.latestPerVital?.body_temperature
								.timestamp as string
						)) ||
					(p?.measurement?.latestPerVital?.respiration_rate &&
						!checkFreshVitalCurrentShift(
							p?.measurement?.latestPerVital?.respiration_rate
								.timestamp as string
						)) ||
					(p?.measurement?.latestPerVital?.systolicPressure &&
						!checkFreshVitalCurrentShift(
							p?.measurement?.latestPerVital?.systolicPressure
								.timestamp as string
						))
				) {
					vitalsTakenGroup = PatientVitalsTakenGroup.Recorded
				}
			}

			return {
				measurement: ReportState.checkCurrentShiftReportTime(
					department,
					type,
					p.measurement
				),
				hasSomeVitalsTaken: p.hasSomeVitalsTaken,
				firstName: p.firstName,
				lastName: p.lastName,
				sentToEMR:
					patientAllExport && patientAllExport.data.length
						? ReportState.checkingLastEmrTime(
								patientAllExport.data[patientAllExport.data.length - 1]
						  )
						: false,
				checked: !!requestPatientsVitals.filter((id) => id === p.id).length,
				vitalsTakenGroup: vitalsTakenGroup,
				id: p.id,
				enabled: p.enabled,
				emrid: p.emrid || null,
				room: p.room,
				forceReadLoading: ReportState.forceReadLoadingSetting(
					patientForceReadDevicesStatus.length
						? patientForceReadDevicesStatus[0]
						: null
				),
				lastEMRUpdateTime: p.lastEMRUpdateTime,
				name: `${p.lastName}, ${p.firstName}`,
				avatarUrl: p.avatar?.signedUrl,
				statusDetailInformation:
					currentDevices?.statusDetailInformation || 'Active',
				monitored: monitorPatientIds.includes(p.id),
				patientAlertRules: p.patientAlertRules,
				requiredVitals: p.requiredVitals,
				wasSentToEMR:
					patientAllExport && patientAllExport.data.length
						? ReportState.toPatientWasSentToEmr(
								patientAllExport.data[patientAllExport.data.length - 1]
									.creationTime,
								measurement?.lastMeasurementTime as string
						  )
						: false,
				defaultAlertRules: p.defaultAlertRules,
				...ReportState.toPatientVitalsMissing(
					checkShiftSentEmrInformation,
					vitals,
					type
				),
				...ReportState.toPatientVitalsEmrStatus(checkShiftSentEmrInformation),
				...ReportState.toPatientVitalsInsight(p.insights),
				...ReportState.toPatientVitalsMeasurementTime(measurement, type),
				checkShiftSentEmrInformation: p.treatmentPlan
					? ReportState.toReportShiftCheckVitalsToTreatmentPlan(
							p.treatmentPlan.filter(
								(t) => !t.endTime || new Date(t.endTime) > new Date()
							),
							// @ts-ignore
							!patientAllExport
								? { patientId: p.id, data: [] }
								: patientAllExport
					  )
					: null
			}
		})
	}

	private static forceReadLoadingSetting(
		forceReadDeviceStatus: NewReadSocketModel | null
	) {
		return !(
			!forceReadDeviceStatus ||
			forceReadDeviceStatus.forceReadStatus === forceReadStatus.Monitor ||
			forceReadDeviceStatus.forceReadStatus === forceReadStatus.Removed ||
			forceReadDeviceStatus.forceReadStatus === forceReadStatus.Disconnected ||
			forceReadDeviceStatus.errorCode
		)
	}

	//TODO: We should maintain current shift object with all parsed data like from time / till time in the store and make all calculations in selectors
	private static checkCurrentShiftReportTime(
		department: DepartmentDTO,
		type: string,
		report: PatientObservationDTO | null
	) {
		if (
			type === ReportType.Time ||
			(department && department.id === 'all') ||
			(department && department.id !== 'all' && department.isAutomatic)
		)
			return report
		else if (!report) return null
		const data = new Date().toISOString()
		if (
			checkShiftTime.getShiftByTime(data) === checkShiftTime.morning_shift_name
		) {
			return isInShiftRange(
				report.lastMeasurementTime as string,
				moment({ hour: checkShiftTime.morning_shift.min }).format('HH:mm'),
				moment({ hour: checkShiftTime.morning_shift.max }).format('HH:mm')
			)
				? report
				: null
		} else if (
			checkShiftTime.getShiftByTime(data) === checkShiftTime.day_shift_name
		) {
			return isInShiftRange(
				report.lastMeasurementTime as string,
				moment({ hour: checkShiftTime.day_shift.min }).format('HH:mm'),
				moment({ hour: checkShiftTime.day_shift.max }).format('HH:mm')
			)
				? report
				: null
		} else if (
			checkShiftTime.getShiftByTime(data) === checkShiftTime.night_shift_name
		) {
			return isInShiftRange(
				report.lastMeasurementTime as string,
				moment({ hour: checkShiftTime.night_shift.min }).format('HH:mm'),
				'23:59'
			) ||
				isInShiftRange(
					report.lastMeasurementTime as string,
					'00:00',
					moment({ hour: checkShiftTime.night_shift.max }).format('HH:mm')
				)
				? report
				: null
		}
		return null
	}

	private static deviceStatusDetailInformation(
		forceReadDeviceStatus: NewReadSocketModel | null,
		statusDetailInformation: string | undefined
	): string | undefined {
		if (
			(forceReadDeviceStatus &&
				forceReadDeviceStatus.forceReadStatus === forceReadStatus.Monitor) ||
			(forceReadDeviceStatus &&
				forceReadDeviceStatus.forceReadStatus ===
					forceReadStatus.Disconnected) ||
			(forceReadDeviceStatus &&
				forceReadDeviceStatus.errorCode &&
				forceReadDeviceStatus.errorCode !== forceReadErrorCode.Timeout &&
				forceReadDeviceStatus.forceReadStatus === forceReadStatus.BadSignal)
		) {
			if (
				forceReadDeviceStatus.forceReadStatus === forceReadStatus.Monitor &&
				statusDetailInformation !== DeviceCriticalStatus.LowBattery &&
				statusDetailInformation !== DeviceCriticalStatus.NoBattery
			) {
				return DeviceCriticalStatus.Monitor
			} else if (
				forceReadDeviceStatus.forceReadStatus ===
					forceReadStatus.Disconnected &&
				statusDetailInformation !== DeviceCriticalStatus.LowBattery &&
				statusDetailInformation !== DeviceCriticalStatus.NoBattery
			) {
				return DeviceCriticalStatus.NoConnection
			} else if (
				forceReadDeviceStatus.forceReadStatus === forceReadStatus.BadSignal &&
				statusDetailInformation !== DeviceCriticalStatus.LowBattery &&
				statusDetailInformation !== DeviceCriticalStatus.NoBattery
			) {
				return DeviceCriticalStatus.BadReading
			} else {
				return statusDetailInformation
			}
		} else {
			return statusDetailInformation
		}
	}

	private static toPatientVitalsEmrStatus(
		checkShiftSentEmrInformation: CheckShiftSentEmrInformationInterface | null
	) {
		const statusMapping = {
			[SentEmrInformation.Completed]: {
				emrStatus: VitalStatusIndicator.Completed,
				isVitalComplete: true,
				vitalStatusIcon: VitalStatusIcon.Completed
			},
			[SentEmrInformation.NotRequired]: {
				emrStatus: null,
				isVitalComplete: false,
				vitalStatusIcon: VitalStatusIcon.NotRequired
			},
			[SentEmrInformation.NeedExportToEmr]: {
				emrStatus: VitalStatusIndicator.NeedExportToEmr,
				isVitalComplete: false,
				vitalStatusIcon: VitalStatusIcon.NeedExportToEmr
			},
			[SentEmrInformation.CriticalReportTime]: {
				emrStatus: VitalStatusIndicator.NeedExportToEmr,
				isVitalComplete: false,
				vitalStatusIcon: VitalStatusIcon.NeedExportToEmr
			},
			[SentEmrInformation.FailedEmr]: {
				emrStatus: VitalStatusIndicator.FailedExportToEmr,
				isVitalComplete: false,
				vitalStatusIcon: VitalStatusIcon.NotRequired
			},
			[SentEmrInformation.PartialExport]: {
				emrStatus: VitalStatusIndicator.PartialExportEmr,
				isVitalComplete: true,
				vitalStatusIcon: VitalStatusIcon.PartialExportEmr
			}
		}

		const defaultStatus = {
			emrStatus: null,
			isVitalComplete: false,
			vitalStatusIcon: VitalStatusIcon.NotRequired
		}

		const status = checkShiftSentEmrInformation?.status

		const { emrStatus, isVitalComplete, vitalStatusIcon } = status
			? statusMapping[status] || defaultStatus
			: defaultStatus

		return {
			emrStatus,
			isVitalComplete,
			vitalStatusIcon,
			checkShiftSentEmrInformation
		}
	}

	private static toPatientVitalsInsight(insight: InsightDTO[] | null): {
		insights: PatientVItalInsightsObservationFields
	} {
		const fields: {
			key: keyof PatientVItalInsightsObservationFields
			subject: AlertSubject
		}[] = [
			{ key: 'systolicPressure', subject: AlertSubject.SystolicPressure },
			{ key: 'diastolicPressure', subject: AlertSubject.DiastolicPressure },
			{ key: 'heartRate', subject: AlertSubject.HeartRate },
			{ key: 'respirationRate', subject: AlertSubject.RespirationRate },
			{ key: 'spo2', subject: AlertSubject.SPO2 },
			{ key: 'bodyTemperature', subject: AlertSubject.BodyTemperature }
		]

		const result = fields.reduce((acc, field) => {
			acc[field.key] = insight
				? extractSpecificInsightBySubject(insight, field.subject)
				: null
			return acc
		}, {} as PatientVItalInsightsObservationFields)

		return { insights: result }
	}

	private static toPatientVitalsMeasurementTime(
		measurement: PatientObservationDTO | null,
		reportType: string
	) {
		const isOldMeasurementTime = checkFreshVitalCurrentShift(
			measurement?.lastMeasurementTime as string
		)
		const isActualLastMeasurementTime = (measurement?.lastMeasurementTime &&
			(!isOldMeasurementTime || reportType === ReportType.Time)) as boolean
		return {
			isOldMeasurementTime,
			lastMeasurementTime: measurement?.lastMeasurementTime || null,
			isActualLastMeasurementTime
		}
	}

	private static toPatientWasSentToEmr(
		lastExportTime: string,
		lastMeasurementTime: string
	) {
		if (!lastMeasurementTime) return false
		return lastMeasurementTime <= lastExportTime
	}

	private static toPatientVitalsMissing(
		checkShiftSentEmrInformation: CheckShiftSentEmrInformationInterface | null,
		v: PatientVItalObservationFields,
		reportType: ReportType
	) {
		if (Object.values(v).filter((v) => v).length) {
			Object.keys(v).forEach((key) => {
				if (
					isNull(v[key as VitalsFields]) &&
					!isNull(checkShiftSentEmrInformation) &&
					checkShiftSentEmrInformation.status !== SentEmrInformation.Completed
				) {
					v[key as VitalsFields] = {
						isMissing: CheckMissingVital(
							v,
							checkShiftSentEmrInformation,
							key as VitalsFields,
							reportType
						)
					}
				}
			})
		}
		return { vitals: { ...v } }
	}

	private static toPatientVitals(
		patient: PatientInterface,
		measurement: PatientObservationDTO | null,
		department: DepartmentDTO,
		reportType: string
	): { vitals: PatientVItalObservationFields } {
		const vitals: {
			key: keyof PatientVItalObservationFields
			subject: ObservationFields
		}[] = [
			{
				key: VitalsFields.SystolicPressure,
				subject: ObservationFields.SystolicPressure
			},
			{
				key: VitalsFields.DiastolicPressure,
				subject: ObservationFields.DiastolicPressure
			},
			{ key: VitalsFields.HeartRate, subject: ObservationFields.HeartRate },
			{
				key: VitalsFields.RespirationRate,
				subject: ObservationFields.RespirationRate
			},
			{ key: VitalsFields.SpO2, subject: ObservationFields.SpO2 },
			{
				key: VitalsFields.BodyTemperature,
				subject: ObservationFields.BodyTemperature
			},
			{
				key: VitalsFields.BloodGlucose,
				subject: ObservationFields.BloodGlucose
			}
		]

		const result = vitals.reduce((acc, vital) => {
			const v =
				measurement?.latestPerVital?.[
					vital.subject as keyof PatientObservationDTO['latestPerVital']
				]
			acc[vital.key] = v
				? ReportState.setPatientVitalSetting(
						v,
						department,
						patient,
						reportType,
						vital.subject
				  )
				: null
			return acc
		}, {} as PatientVItalObservationFields)

		return { vitals: result }
	}

	private static toReportShiftCheckVitalsToTreatmentPlan(
		treatmentPlans: TreatmentPlanDTO[],
		{ data }: ExportAllInterface
	) {
		return checkVitalsToTreatmentPlan(
			combineTreatmentPlans(treatmentPlans),
			data
		)
	}

	private static setPatientVitalSetting(
		vital: ObservationLatest,
		department: DepartmentDTO,
		patient: PatientInterface,
		reportType: string,
		vitalType: string
	) {
		const isOldVital = checkFreshVitalCurrentShift(vital?.timestamp as string)
		const isCriticalVital =
			checkVitalToEmrRules(
				// vitalType !== ObservationFields.BodyTemperature
				// 	? vital?.value
				// 	: bodyTemperatureSetting.toTransformCelsiusToFahrenhei(vital?.value),
				vital?.value,
				vitalType,
				patient
			) &&
			(!isOldVital || reportType === ReportType.Time)
		return {
			value: vital.value,
			isManual: checkIsManual(vital?.isManual as boolean, department),
			timestamp: vital?.timestamp,
			isActualValue:
				vital.value && (!isOldVital || reportType === ReportType.Time),
			isCriticalVital,
			isOldVital,
			isMissing: false,
			checked: false
		}
	}

	public override ngxsOnInit() {
		this.storeEvents.devicesModified$
			.pipe(
				tap((devicesAction) => {
					this.getMonitoredPatients(devicesAction.action.entities)
					if (this.reportStateSubscription)
						this.reportStateSubscription.unsubscribe()
					this.reportStateSubscription =
						this.backendService.updatePatientVitalsManualWSOnMessage$.subscribe(
							(res: any) => {
								clearTimeout(this.timeout)
								if (this.getState().type !== ReportType.CurrentShift) return
								if (
									(this.checkVitalsInData(cloneDeep(res)) &&
										!this.lastPatientReportTime[res.patientId]) ||
									(this.checkVitalsInData(cloneDeep(res)) &&
										this.lastPatientReportTime[res.patientId] !== res.timestamp)
								) {
									this.updatePatientsIds = uniq([
										...this.updatePatientsIds,
										res.patientId
									])
									this.lastPatientReportTime[res.patientId] = res.timestamp
								}
							}
						)
				})
			)
			.subscribe()

		this.storeEvents.patientsModified$
			.pipe(
				tap((action) => {
					this.getReportsData(action.action.entities)
				})
			)
			.subscribe()

		if (!this.isMobile) {
			merge(
				this.storeEvents.patientsModified$,
				this.storeEvents.departmentChange$,
				this.storeEvents.shiftChanged$,
				this.storeEvents.homePageNavigated$,
				interval(this.ABNORMAL_VALUES_MILLISECONDS_TIME_UPDATE)
			)
				.pipe(
					switchMap(() => {
						if (this.authState.isAuthenticated()) {
							return this.getPreviousShiftsAbnormalData()
						}
						return EMPTY
					}),
					catchError(() => {
						this.ntfService.error(
							`Error in getting latest abnormal values. Please, try reload page.`
						)
						return EMPTY
					})
				)
				.subscribe()
		}

		this.storeEvents.establishMqttConnection$
			.pipe(
				tap(() => {
					if (this.authState.isAuthenticated()) {
						this.getReportsData()
						this.measurementState.setLoading()
					}
				})
			)
			.subscribe()

		this.storeEvents.departmentChange$
			.pipe(
				tap(() => {
					this.measurementState.patchState({ isLoading: true })
					this.getReportsData()
					this.setTextFilter('')
					this.measurementState.setMeasurementWithAbnormalInPreviousShiftsLoading(
						true
					)
				})
			)
			.subscribe()
		this.currentShiftListenerSubscription =
			this.setCurrentShiftListener().subscribe()

		this.subscribeCompletedPccSentData()
		this.setCheckShiftChangeInterval()

		this.storeEvents.logout$
			.pipe(
				tap(() => {
					this.updatePatientsIds = []
					clearTimeout(this.timeout)
					this.reset()
					this.lastPatientReportTime = {}
					if (this.checkTimeSubscription) {
						this.checkTimeSubscription.unsubscribe()
					}
					if (this.reportStateSubscription)
						this.reportStateSubscription.unsubscribe()
					if (this.currentShiftListenerSubscription)
						this.currentShiftListenerSubscription.unsubscribe()
				})
			)
			.subscribe()
	}

	setCheckShiftChangeInterval() {
		if (this.checkTimeSubscription) this.checkTimeSubscription.unsubscribe()
		this.checkTimeSubscription = timer(5000, 50000).subscribe(() =>
			this.checkCurrentShiftTime()
		)
	}

	closeMessage() {
		this.messageClosedByUser = true
		this.showMessage.next(false)
		this.setCheckShiftChangeInterval()
	}

	resetMessages() {
		this.messageClosedByUser = false
		this.showed10MinutesMessage = false
		this.showed30MinutesMessage = false
		this.showMessage.next(false)
	}

	@DataAction()
	setNewRead() {
		let patients = Object.values(this.patientState.entities)
		const department = Object.values(this.departmentState.entities).length
			? Object.values(this.departmentState.entities)[0]
			: this.departmentState.getState().currentDepartment
		if (department) {
			patients = patients.filter(
				// @ts-ignore
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const currentDevices = Object.values(this.deviceState.entities).filter(
			(d) =>
				d.model === DeviceModel.BiobeatWatch &&
				d.forceReadEnabled &&
				d.statusInformation !== DeviceCriticalStatus.NoConnection &&
				d.patient &&
				patients.find((p) => p.id === d.patient.id)
		)
		if (!currentDevices) return
		this.ntfService.warning(
			'The system is retrieving fresh data from devices. This process may take a few minutes'
		)
		this.forceReadSocketInitialization(currentDevices.map((d) => d.id))
	}

	@DataAction()
	setReportsAllToEmrData(
		@Payload('patients') patients: PatientVitalsInterface[],
		@Payload('exports') exports: PatientExportDTO[]
	) {
		this.pccState.setLoading(true)
		const observations = patients
			.filter(
				(p) =>
					!p.sentToEMR &&
					p.emrid &&
					p.vitals &&
					Object.values(p.vitals).filter((v) => v).length
			)
			.map((p) => ReportState.setAllToEmrSetting(p, exports))
			.filter((d) => !!d)
		if (!observations.length) {
			setTimeout(() => {
				this.pccState.setLoading(false)
				this.ntfService.error(
					`There are no good patients for reporting to the EMR`
				)
			}, 0)
			return
		}

		this.pccState.updatePccEmrMeasurementBulk(
			{
				// @ts-ignore
				observations
			},
			patients.length
		)
	}

	@DataAction()
	setIsAbnormalMissingFilter(
		@Payload('isAbnormalMissingFilter') isAbnormalMissingFilter: boolean
	) {
		this.patchState({
			isAbnormalMissingFilter
		})
	}

	public setCurrentShiftListener(): Observable<number> {
		this.patchState({
			currentShift: checkShiftTime.getShiftByTime(new Date().toISOString())
		})
		return timer(10000, 10000).pipe(
			switchMap((n) => {
				if (
					!this.departmentState.snapshot.currentDepartment ||
					(this.departmentState.snapshot.currentDepartment &&
						this.departmentState.snapshot.currentDepartment.id === 'all') ||
					(this.departmentState.snapshot.currentDepartment &&
						this.departmentState.snapshot.currentDepartment.id !== 'all' &&
						this.departmentState.snapshot.currentDepartment.isAutomatic)
				)
					return of(n)
				if (
					this.getState().currentShift !==
					checkShiftTime.getShiftByTime(new Date().toISOString())
				) {
					this.patchState({
						currentShift: checkShiftTime.getShiftByTime(
							new Date().toISOString()
						)
					})
					this.measurementState.refreshMeasurement()
					this.exportState.refreshExport()
				}
				return of(n)
			})
		)
	}

	@DataAction()
	setTextFilter(@Payload('text') text: string) {
		this.patchState({ textFilter: text })
	}

	@DataAction()
	exportPDFSetting(@Payload('patients') patients: PatientVitalsInterface[]) {
		let department = !!this.departmentState.getState().currentDepartment
			? this.departmentState.getState().currentDepartment
			: Object.values(this.departmentState.entities)[0]
		const rows = abnormalMissingFilter(
			patients,
			this.getState().isAbnormalMissingFilter
		)
			.filter((p) => p.enabled)
			.map((p) => ({
				name: p.name,
				room: !p.room ? '' : p.room,
				bp: this.checkFreshVitalTimeSetting(
					p.vitals &&
						p.vitals?.systolicPressure &&
						p.vitals?.systolicPressure?.value
						? String(Math.round(p.vitals?.systolicPressure?.value as number)) +
								'/' +
								String(Math.round(<number>p.vitals?.diastolicPressure?.value))
						: '',
					p.vitals?.diastolicPressure?.timestamp as any
				),
				hr: this.checkFreshVitalTimeSetting(
					p.vitals && p.vitals?.heartRate && p.vitals?.heartRate?.value
						? String(Math.round(p.vitals?.heartRate?.value as number))
						: '',
					p.vitals?.heartRate?.timestamp as any
				),
				rr: this.checkFreshVitalTimeSetting(
					p.vitals &&
						p.vitals?.respirationRate &&
						p.vitals?.respirationRate?.value
						? String(Math.round(p.vitals?.respirationRate?.value as number))
						: '',
					p.vitals?.respirationRate?.timestamp as any
				),
				spo: this.checkFreshVitalTimeSetting(
					p.vitals && p.vitals?.spo2 && p.vitals?.spo2?.value
						? String(Math.round(p.vitals?.spo2?.value as number))
						: '',
					p.vitals?.spo2?.timestamp as any
				),
				temp: this.checkFreshVitalTimeSetting(
					p.vitals &&
						p.vitals?.bodyTemperature &&
						p.vitals?.bodyTemperature?.value
						? String(
								bodyTemperatureSetting.toTransformCelsiusToFahrenhei(
									p.vitals?.bodyTemperature?.value as number
								)
						  )
						: '',
					p.vitals?.bodyTemperature?.timestamp as any
				),
				lastMeasurements: bodyTemperatureSetting.isUserLocaleUS
					? p.lastMeasurementTime
						? moment(p.lastMeasurementTime).format('MM/DD/yy HH:mm')
						: ''
					: p.lastMeasurementTime
					? moment(p.lastMeasurementTime).format('DD/MM/yy HH:mm')
					: ''
			}))
		if (!rows) {
			return
		}
		return this.backendService.reports({
			// @ts-ignore
			facilityName: !department.departmentEmrId
				? ''
				: // @ts-ignore
				  department.departmentEmrId,
			// @ts-ignore
			departmentName: department.name,
			rows
		})
	}

	@DataAction()
	setReportsVitalsData(
		@Payload('patients') patients: PatientVitalsInterface[],
		@Payload('idx') ids?: string[]
	) {
		this.reportsVitalsData = {
			patientActions: []
		}
		const requestPatientsVitals: string[] = ids
			? cloneDeep(ids)
			: this.ctx.getState().requestPatientsVitals
		if (requestPatientsVitals.length) {
			ReportState.requestPatientsVitalsSetting(
				requestPatientsVitals,
				patients,
				this.reportsVitalsData
			)
		}
		return this.backendService
			.setManualVitals(this.reportsVitalsData)
			.pipe(tap(() => this.ntfService.success('Task(s) were created')))
	}

	// @DataAction()
	setManualCNAReport(
		@Payload('data') data: ManualVitalsInterface,
		@Payload('type') type: ReportType,
		@Payload('isLastEmitted') isLastEmitted: boolean = true
	) {
		this.patchState({ isMeasurementSending: true })
		return this.backendService.setCNAManualVitals(data).pipe(
			tap((measurementResponse: { stringPayload: string }) => {
				const measurement = this.convertMeasurementDataFromStringPayload({
					stringPayload: measurementResponse.stringPayload,
					patientId: data.observedPatient,
					deviceId: data.deviceId
				})
				this.measurementState.measurementsWSOnMessageCallback(measurement)

				const patient = this.store.selectSnapshot(PatientState.entities)[
					data.observedPatient
				]
				if (isLastEmitted) {
					this.patchState({ type })
					this.patchState({ isMeasurementSending: false })
				}

				this.isUserRN$.pipe(take(1)).subscribe((isUserRn) => {
					if (
						(this.authState.getState().CNAAccessJwt ||
							(isUserRn && this.isMobile)) &&
						data?.title !== WarningTitle.Warning
					) {
						return
					}

					if (!isLastEmitted) {
						return
					}

					const reportPatients = this.store.selectOnce(ReportState.report)
					reportPatients.pipe(take(1)).subscribe((patients) => {
						let patient = {
							...patients.find((p) => p.id === data.observedPatient)
						}
						if (patient?.checkShiftSentEmrInformation?.status) {
							patient = {
								...patient,
								checkShiftSentEmrInformation: {
									...patient.checkShiftSentEmrInformation,
									status: 'NOT_REQUIRED'
								}
							}
						}
						if (
							patient?.vitalsTakenGroup === PatientVitalsTakenGroup.Recorded &&
							patient.requiredVitals &&
							Object.keys(patient.requiredVitals).length &&
							this.isMobile
						) {
							this.setSuccessfullySentCurrentShiftPatientAllMeasurements(
								patient
							)
						} else {
							if (patient) {
								this.ntfService.success(
									`${patient.lastName}, ${
										(patient?.firstName || '')[0]
									}. vitals recorded`
								)
							} else {
								this.ntfService.success(`Update Manual Vitals completed`)
							}
						}
					})
				})
			}),
			catchError(() => {
				this.patchState({ isMeasurementSending: false })
				this.ntfService.error(`Update Manual Vitals Failed`)
				return EMPTY
			})
		)
	}

	@DataAction()
	getReportsData(patientsData?: PatientDTO[]): void {
		let patients = patientsData || Object.values(this.patientState.entities)
		const department = Object.values(this.departmentState.entities).length
			? Object.values(this.departmentState.entities)[0]
			: this.departmentState.getState().currentDepartment

		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				// @ts-ignore
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const activePatientIds = patients.filter((p) => p.enabled).map((p) => p.id)
		if (!activePatientIds.length) return
		if (this.getState().type === ReportType.CurrentShift) {
			this.getCurrentShiftLatestData(activePatientIds)
		} else {
			const preShiftName = checkShiftTime.getPreviousShift(
				checkShiftTime.getShiftByTime(new Date().toString())
			)
			const { min, max } = checkShiftTime.getShiftTime(
				checkShiftTime.getPreviousShift(
					checkShiftTime.getShiftByTime(new Date().toString())
				)
			)

			if (preShiftName === checkShiftTime.night_shift_name) {
				this.measurementState.getMeasurementSummary(
					activePatientIds,
					moment()
						.subtract(1, 'day')
						.set('hours', min)
						.set('seconds', 0)
						.set('minutes', 0)
						.toISOString(),
					moment()
						.set('hours', max)
						.set('seconds', 0)
						.set('minutes', 0)
						.toISOString(),
					ReportMode.Latest
				)
			} else {
				this.measurementState.getMeasurementSummary(
					activePatientIds,
					moment()
						.set('hours', min)
						.set('seconds', 0)
						.set('minutes', 0)
						.toISOString(),
					moment()
						.set('hours', max)
						.set('seconds', 0)
						.set('minutes', 0)
						.toISOString(),
					ReportMode.Latest
				)
			}
		}
		this.dispatch({ type: 'CAN TREATMENT_PLANS LOAD' })
	}

	@DataAction()
	getMonitoredPatients(devices: DeviceDTO[]) {
		let patients = Object.values(this.patientState.entities)
		const department = Object.values(this.departmentState.entities).length
			? Object.values(this.departmentState.entities)[0]
			: this.departmentState.getState().currentDepartment

		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				// @ts-ignore
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const monitorPatientIds = devices
			.filter((d) => d.patient)
			.map((d) => d.patient.id)

		this.ctx.patchState({
			monitorPatientIds: [
				...new Set([...monitorPatientIds, ...this.getState().monitorPatientIds])
			]
		})
	}

	@DataAction()
	setDevicesForceRead(@Payload('type') type = 'default') {
		let patients = Object.values(this.patientState.entities)
		const department = Object.values(this.departmentState.entities).length
			? Object.values(this.departmentState.entities)[0]
			: this.departmentState.getState().currentDepartment
		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				// @ts-ignore
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		const currentDevices = Object.values(this.deviceState.entities).filter(
			(d) =>
				d.model === DeviceModel.BiobeatWatch &&
				d.forceReadEnabled &&
				d.patient &&
				patients.find((p) => p.id === d.patient.id)
		)
		return this.backendService
			.setDevicesForceRead(currentDevices.map((d) => d.id))
			.pipe(
				tap(
					(
						res: {
							deviceId: string
							forceReadStatus: string
						}[]
					) => {
						if (type !== 'default') return
						res.forEach((r) => {
							const device = currentDevices.find((d) => d.id === r.deviceId)
							if (r.forceReadStatus === 'DONE') {
								this.ntfService.success(`${device?.serialNumber} New Read Done`)
							} else {
								this.ntfService.error(`${device?.serialNumber} New Read Failed`)
							}
						})
					}
				)
			)
	}

	@DataAction()
	updatePatientVitalsManualWSOnMessageSetting(
		@Payload('patientIds') ids: string[]
	) {
		if (this.getState().type === ReportType.CurrentShift) {
			this.measurementState.getMeasurementSummary(
				ids,
				moment(new Date()).subtract(8, 'hours').toISOString(),
				moment(new Date()).toISOString(),
				ModeType.Latest
			)
		} else if (this.getState().type === ReportType.Time) {
			this.measurementState.getMeasurementSummary(
				ids,
				moment(new Date()).subtract(1, 'hours').toISOString(),
				moment(new Date()).toISOString(),
				ModeType.Latest
			)
			// this.getCurrentShiftLatestData(ids)
		}
	}

	@DataAction()
	setSuccessfullySentCurrentShiftPatientAllMeasurements(
		patient: Partial<PatientVitalsInterface>
	): Observable<Partial<PatientVitalsInterface>> {
		this.successfullySentCurrentShiftPatientAllMeasurements$.next(patient)
		timer(3000)
			.pipe(take(1))
			.subscribe(() =>
				this.successfullySentCurrentShiftPatientAllMeasurements$.next(null)
			)
		return of(patient)
	}

	@DataAction()
	showCompletedPccSentData(patient: Partial<PatientVitalsInterface>) {
		const isVitalsRecorded =
			patient?.vitalsTakenGroup === PatientVitalsTakenGroup.Recorded &&
			patient.requiredVitals &&
			Object.keys(patient.requiredVitals).length

		if (isVitalsRecorded) {
			if (this.isMobile) {
				this.setSuccessfullySentCurrentShiftPatientAllMeasurements(patient)
			} else {
				this.ntfService.success(
					`${patient.lastName}, ${
						(patient.firstName || '')[0]
					}. vitals saved and reported`
				)
			}
		} else {
			this.store
				.select(ReportState.isMeasurementSending)
				.pipe(
					filter((isSending) => !isSending),
					take(1),
					switchMap(() =>
						this.store
							.selectOnce(ReportState.report)
							.pipe(
								map((patients) => patients.find((p) => p.id === patient.id))
							)
					)
				)
				.subscribe((pat) => {
					if (
						!this.isMobile ||
						!(
							pat?.vitalsTakenGroup === PatientVitalsTakenGroup.Recorded &&
							pat.requiredVitals &&
							Object.keys(pat.requiredVitals).length
						)
					) {
						this.ntfService.success(
							`${pat?.lastName}, ${
								(pat?.firstName || '')[0]
							}. vitals saved and reported`
						)
					} else {
						this.setSuccessfullySentCurrentShiftPatientAllMeasurements(pat!)
					}
				})
		}
	}

	checkCurrentShiftTime() {
		const date = new Date()
		if (
			moment(date).get('hours') >=
				checkShiftTime.getShiftTime('morning_shift').min &&
			moment(date).get('hours') <
				checkShiftTime.getShiftTime('morning_shift').max
		) {
			this.checkTime(
				new Date(
					moment(date)
						.set('hours', checkShiftTime.getShiftTime('morning_shift').max)
						.set('minutes', 0)
						.toISOString()
				)
			)
		} else if (
			moment(date).get('hours') >=
				checkShiftTime.getShiftTime('day_shift').min &&
			moment(date).get('hours') < checkShiftTime.getShiftTime('day_shift').max
		) {
			this.checkTime(
				new Date(
					moment(date)
						.set('hours', checkShiftTime.getShiftTime('day_shift').max)
						.set('minutes', 0)
						.toISOString()
				)
			)
		} else if (
			moment(date).get('hours') >=
				checkShiftTime.getShiftTime('night_shift').min ||
			moment(date).add(1, 'days').get('hours') <
				checkShiftTime.getShiftTime('night_shift').max
		) {
			this.checkTime(
				new Date(
					moment(date)
						.add(1, 'days')
						.set('hours', checkShiftTime.getShiftTime('night_shift').max)
						.set('minutes', 0)
						.toISOString()
				)
			)
		} else if (
			moment(date).subtract(1, 'days').get('hours') >=
				checkShiftTime.getShiftTime('night_shift').min ||
			moment(date).get('hours') < checkShiftTime.getShiftTime('night_shift').max
		) {
			this.checkTime(
				new Date(
					moment(date)
						.set('hours', checkShiftTime.getShiftTime('night_shift').max)
						.set('minutes', 0)
						.toISOString()
				)
			)
		}
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	private checkTime(date: Date) {
		if (!date) return
		const currentTime = new Date().getTime()
		const endOfShiftTime = date.getTime()
		const timeUntilShiftEnd = endOfShiftTime - currentTime
		if (timeUntilShiftEnd > 30 * 60 * 1000) {
			this.resetMessages()
		} else if (
			timeUntilShiftEnd <= 30 * 60 * 1000 &&
			timeUntilShiftEnd > 10 * 60 * 1000 &&
			!this.showed30MinutesMessage
		) {
			this.showMessage.next(true)
			this.showed30MinutesMessage = true
		} else if (
			(timeUntilShiftEnd < 30 * 60 * 1000 &&
				timeUntilShiftEnd <= 10 * 60 * 1000 &&
				this.showed30MinutesMessage &&
				!this.showed10MinutesMessage &&
				this.messageClosedByUser) ||
			(timeUntilShiftEnd < 30 * 60 * 1000 &&
				timeUntilShiftEnd <= 10 * 60 * 1000 &&
				!this.showed30MinutesMessage &&
				!this.showed10MinutesMessage &&
				!this.messageClosedByUser)
		) {
			this.showMessage.next(true)
			this.showed30MinutesMessage = true
			this.showed10MinutesMessage = true
			return
		}
	}

	private checkFreshVitalTimeSetting(
		value: string,
		time: string | undefined
	): string {
		if (!time) return ''
		if (!this.departmentState.getState().currentDepartment?.isAutomatic) {
			return value
		}
		return moment(new Date()).diff(moment(time), 'hours') >= 4 ? '' : value
	}

	private forceReadSocketInitialization(deviceIds: string[]) {
		this.webSocketService.setUrl(
			`${environment.forceReadWsUrl}/v2/device/force-read?authorization=${this.authState.snapshot.accessJwt?.token}`
		)
		this.webSocketService.send({
			deviceIds,
			timeout: 180
		})
		this.patchState({ isForceReadProcess: true, forceReadDevicesStatus: [] })
		let finishedDevicesLength = 0
		this.subscriptionNewReadSocket$ = this.webSocketService
			.connect()
			.subscribe((data: NewReadSocketModel) => {
				let forceReadDevicesStatus = cloneDeep(
					this.getState().forceReadDevicesStatus
				)
				const idx = forceReadDevicesStatus.findIndex(
					(item) => item.deviceId === data.deviceId
				)
				if (idx !== -1) {
					forceReadDevicesStatus[idx] = data
				} else {
					forceReadDevicesStatus = [...forceReadDevicesStatus, data]
				}
				this.patchState({ forceReadDevicesStatus })
				if (
					data.forceReadStatus === forceReadStatus.Monitor ||
					data.forceReadStatus === forceReadStatus.Removed ||
					data.forceReadStatus === forceReadStatus.Disconnected ||
					data.errorCode
				) {
					finishedDevicesLength += 1
					if (finishedDevicesLength === deviceIds.length) {
						this.webSocketService.close()
						this.patchState({
							isForceReadProcess: false,
							forceReadDevicesStatus: []
						})
						this.subscriptionNewReadSocket$.unsubscribe()
					}
				}
			})
	}

	private checkVitalsInData(res: any) {
		if (res.context) {
			delete res.context
		}
		if (res.deviceId) {
			delete res.deviceId
		}
		if (res.monitoringStatus) {
			delete res.monitoringStatus
		}
		if (res.patientId) {
			delete res.patientId
		}
		if (res.sessionId) {
			delete res.sessionId
		}
		if (res.timestamp) {
			delete res.timestamp
		}
		return !!Object.values(res).length
	}

	private getCurrentShiftLatestData(activePatientIds: string[]) {
		const currentShift = this.shiftPlannerState.getState().currentShift
		const now = new Date()
		const day = now.getHours() < 7 ? now.getDate() - 1 : now.getDate()
		const shiftStartDate = new Date(
			now.getFullYear(),
			now.getMonth(),
			day,
			SHIFT_RANGES[currentShift].min
		)

		this.measurementState.getMeasurementSummary(
			activePatientIds,
			moment(shiftStartDate).toISOString(),
			moment(new Date()).add(20, 'minutes').toISOString(),
			ReportMode.Latest
		)
	}

	private getPreviousShiftsAbnormalData(pats?: PatientInterface[]) {
		let patients = pats || Object.values(this.patientState.entities)
		const department = Object.values(this.departmentState.entities).length
			? Object.values(this.departmentState.entities)[0]
			: this.departmentState.getState().currentDepartment

		if (department && department.id !== DepartmentFilter.All) {
			patients = patients.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}

		const activePatientIds = patients.filter((p) => p.enabled).map((p) => p.id)

		if (activePatientIds.length === 0) {
			return EMPTY
		}

		return this.measurementState.getAbnormalMeasurementSummary(
			activePatientIds,
			moment().subtract(72, 'hour').toISOString(),
			moment().toISOString(),
			ReportMode.Recent_Abnormal
		)
	}

	private convertMeasurementDataFromStringPayload(data: {
		stringPayload: string
		patientId: string
		deviceId: string | undefined
	}): ObservationField {
		const stringPayload = JSON.parse(data.stringPayload)
		const measurement = {
			...stringPayload.data,
			timestamp: new Date(stringPayload.metadata.timestamp).toISOString(),
			deviceId: data.deviceId || 'manual',
			patientId: data.patientId
		} as ObservationField
		return measurement
	}

	private subscribeCompletedPccSentData(): void {
		this.pccState.successfullySentToPccPatientObs$
			.pipe(
				switchMap((patient) => {
					if (!patient) {
						return of(null)
					}
					return this.store
						.selectOnce(ReportState.report)
						.pipe(
							map((patients) =>
								patients.find((p) => p.id === (patient as any).id)
							)
						)
				}),
				tap((patient) => {
					if (!patient) {
						return
					}
					this.showCompletedPccSentData(patient)
				})
			)
			.subscribe()
	}
}
