@if (tab === 'role') {
  <section class='login-tablet'>
    <img class='logo' src='assets/images/AiOmed_logo_small.svg' alt='AiOmed_logo'>
    <h5 class='role-title'>Select your role</h5>
    <div class='role-wrapper'>
      <div role='button' tabindex='0' class='role-card role-nurse' (click)="setTab('rn')">
        <img src='assets/images/stethoscope.svg' alt='stethoscope'>
        <h6 class='role-card__title'>I am a nurse</h6>
      </div>
      <div class='role-separator'>-or-</div>
      <div role='button' tabindex='0' class='role-card role-cna' (click)="setTab('cna')">
        <img src='assets/images/hands-with-heart.svg' alt='hands with heart'>
        <h6 class='role-card__title'>I am a CNA</h6>
      </div>
    </div>
    <div class='policy-support'>
      <a href='https://www.aiomed.com/privacy-policy' target='_blank'>Privacy Policy</a>
      <a href='https://aiomed.atlassian.net/rest/collectors/1.0/template/form/9374ba31' target='_blank'>Support</a>
    </div>
  </section>
} @else {
  @if (!(isOnline$ | async)) {
    <aiomed-information-modal title="Oops, you’re offline!" description="Please move to a location with better WiFi to log in. Once you're connected, this message will disappear and the login form will appear" />
  }
  <section class='login-tablet role rn'>
    <button class='role-back' type='button' (click)="setTab('role')"><span nz-icon nzType='arrow-left'
                                                                           nzTheme='outline'></span>Back
    </button>
    <div class='role-left'>
      <img [src]="tab === 'cna' ? 'assets/images/hands-with-heart.svg' : 'assets/images/stethoscope.svg'"
           [alt]="tab === 'cna' ? 'hands with heart' : 'stethoscope'">
      <h6 class='role-card__title'>{{ tab === 'rn' ? 'NURSE' : 'CNA' }} LOG IN</h6>
      <p
        class='role-card__description'>{{ tab === 'rn' ? 'Nurses, please log in with PCC credentials first' : 'Sign in with AiOmed user name and password' }} </p>
      <div class='policy-support'>
        <a href='https://www.aiomed.com/privacy-policy' target='_blank'>Privacy Policy</a>
        <a href='https://aiomed.atlassian.net/rest/collectors/1.0/template/form/9374ba31' target='_blank'>Support</a>
      </div>
    </div>
    <aiomed-login-tablet-nurse (formSubmitEmitter)='formSubmit.emit($event)' (loginWithPcc)='loginWithPcc.emit()'
                               [hasError]='hasError' [tab]='tab'
                               [isLoading]='!!(isLoading$ | async)' (loginWithCredEmitter)="closeOpenedWindow()"></aiomed-login-tablet-nurse>
  </section>
}
