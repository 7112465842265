import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State, Store } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import {
	combineLatest,
	EMPTY,
	finalize,
	ignoreElements,
	Observable,
	of,
	Subscription,
	switchMap,
	take,
	tap,
	timer
} from 'rxjs'
import { BackendService } from '../../shared/services/backend.service'
import {
	OverdueTaskPatientInterface,
	TaskBackend,
	TaskDTO,
	TaskPatientInterface,
	TaskStatus,
	TaskTreatmentAction,
	UpdateTaskInterface
} from '../../shared/model/task.model'
import { UserState } from '../user/user.state'
import { UserInterface } from '../../shared/model/user.model'
import { PatientState } from '../patient/patient.state'
import { FileState } from '../file/file.state'
import { FileDTO } from '../../shared/model/file'
import { PatientDTO } from '../../shared/model/patient'
import { isEqual, orderBy, uniq } from 'lodash-es'
import moment from 'moment'
import { DepartmentState } from '../department/department.state'
import { DeviceDetectorService } from 'ngx-device-detector'
import { DepartmentDTO } from '../../shared/model/permission.model'
import { StoreEventsService } from '../store-events.service'
import { DepartmentFilter } from '../../shared/model/departments.model'

export const taskFeatureName = 'task'

@StateRepository()
@State<CollatableEntityCollections<TaskDTO>>({
	name: taskFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation(),
		isLoading: false
	}
})
@Injectable()
export class TaskState extends CollatableEntityCollectionsRepository<
	TaskDTO,
	EntityCollation
> {
	subscriptionBackendUpdates$: Subscription
	subscriptionOverdueTaskMobileUpdates$: Subscription
	subscriptionBackendTaskTimeUpdates$: Subscription
	subscriptionTasksRecursively$: Subscription
	private taskStateSubscription: Subscription;

	constructor(
		private backendService: BackendService,
		private actions: Actions,
		private patientState: PatientState,
		private fileState: FileState,
		private deviceService: DeviceDetectorService,
		private storeEvents: StoreEventsService,
		private store: Store
	) {
		super()
	}

	public get backendMobileTaskTimeUpdates$(): Observable<number> {
		return timer(16000, 16000).pipe(
			switchMap((n) => {
				this.setAll(
					Object.values(this.getState().entities).filter(
						(data) =>
							moment(data.expirationTime).valueOf() >=
								moment(new Date().toISOString()).valueOf() &&
							data.taskStatus === TaskStatus.Active
					)
				)
				return of(n)
			})
		)
	}

	public get backendTaskTimeUpdates$(): Observable<number> {
		return timer(16000, 16000).pipe(
			switchMap((n) => {
				const data = Object.values(this.getState().entities).filter(
					(data) =>
						moment(data.expirationTime).valueOf() <
							moment(new Date().toISOString()).valueOf() &&
						data.taskStatus === TaskStatus.Active
				)
				if (!isEqual(Object.values(this.entities), data)) {
					this.setAll(data)
				}
				return of(n)
			})
		)
	}

	public get backendOverdueTaskMobileUpdates$(): Observable<number> {
		return timer(16000, 16000).pipe(
			switchMap((n) => {
				this.ctx.patchState({
					overduePatientsTaskList: uniq(
						this.ctx
							.getState()
							.overduePatientsTaskList.filter(
								(data) =>
									moment(data.expirationTime).valueOf() <
										moment(new Date().toISOString()).valueOf() &&
									data.taskStatus === TaskStatus.Active
							)
					)
				})
				return of(n)
			})
		)
	}

	// public get backendUpdates$(): Observable<void> {
	// 	this.subscriptionTasksRecursively$ = this.backendService
	// 		.getAllTaskRecursively()
	// 		.pipe(take(2))
	// 		.subscribe((res) => {
	// 			if (!res) {
	// 				this.subscriptionTasksRecursively$.unsubscribe()
	// 				return
	// 			}
	// 			this.upsertMany(res.data.filter((r) => r.taskPatient))
	// 			res.data
	// 				.filter((r) => r.taskPatient && !!r.expirationTime)
	// 				.forEach((t) => {
	// 					const idx = this.getState().overduePatientsTaskList.findIndex(
	// 						(e) => e.id === t.id
	// 					)
	// 					if (idx !== -1) return
	// 					this.ctx.patchState({
	// 						overduePatientsTaskList: [
	// 							...this.getState().overduePatientsTaskList,
	// 							t
	// 						]
	// 					})
	// 				})
	// 		})
	// 	return this.backendService.subscribeAllTask().pipe(
	// 		// delay(1500),
	// 		tap((res) => {
	// 			if (!res.taskPatient) return
	// 			this.upsertOne(res)
	// 			if (!!res.expirationTime) {
	// 				this.ctx.patchState({
	// 					overduePatientsTaskList: [
	// 						// @ts-ignore
	// 						...this.getState().overduePatientsTaskList,
	// 						// @ts-ignore
	// 						res
	// 					]
	// 				})
	// 			}
	// 		}),
	// 		ignoreElements()
	// 	)
	// }

	@Selector()
	public static isLoading(state: CollatableEntityCollections<TaskDTO>) {
		return state.isLoading;
	}

	@Selector([UserState.currentUser, PatientState.entities, FileState.files])
	public static currentPatientOverdueTasks(
		state: CollatableEntityCollections<TaskDTO>,
		user: UserInterface,
		patients: EntityDictionary<string, PatientDTO>,
		files: EntityDictionary<string, FileDTO>
	): TaskPatientInterface[] {
		if (!state.focusOnId) return []
		return TaskState.mobileHydrate(
			state.overduePatientTaskList.filter((entity) => {
				if (
					user._degree !== 'NO_DEGREE' &&
					user._degree !== 'MEDICAL_ASSISTANT'
				) {
					return (
						moment(entity.expirationTime).valueOf() <
							moment(new Date().toISOString()).valueOf() &&
						entity.taskStatus === TaskStatus.Active &&
						entity.taskPatient.id === state.focusOnId
					)
				} else {
					return []
				}
			}),
			Object.values(patients),
			files
		)
	}

	@Selector([
		UserState.currentUser,
		DepartmentState.departmentMedicalAssistants,
		DepartmentState.department,
		PatientState.entities,
		FileState.files
	])
	public static overduePatientTasks(
		state: CollatableEntityCollections<TaskDTO>,
		user: UserInterface,
		medicalAssistants: UserInterface[],
		department: DepartmentDTO,
		patients: EntityDictionary<string, PatientDTO>,
		files: EntityDictionary<string, FileDTO>
	): OverdueTaskPatientInterface[] {
		let p = Object.values(patients)
		if (department && department.id !== DepartmentFilter.All) {
			p = p.filter(
				(patient) =>
					patient.department && patient.department.id === department.id
			)
		}
		return TaskState.hydrate(
			medicalAssistants,
			Object.values(state.overduePatientsTaskList)
				.filter(
					(t) =>
						t.taskTreatmentAction === TaskTreatmentAction.ChangeDiaper ||
						t.taskTreatmentAction === TaskTreatmentAction.RepositionPatient
				)
				.filter((entity) => {
					return (
						moment(entity.expirationTime).valueOf() <
							moment(new Date().toISOString()).valueOf() &&
						entity.taskStatus === TaskStatus.Active
					)
				}),
			p,
			files
		)
	}

	@Selector([
		UserState.currentUser,
		DepartmentState.departmentMedicalAssistants,
		PatientState.entities,
		FileState.files
	])
	public static overdueMobilePatientTasks(
		state: CollatableEntityCollections<TaskDTO>,
		user: UserInterface,
		medicalAssistants: UserInterface[],
		patients: EntityDictionary<string, PatientDTO>,
		files: EntityDictionary<string, FileDTO>
	): OverdueTaskPatientInterface[] {
		return TaskState.hydrate(
			medicalAssistants,
			state.overduePatientsTaskList.filter((entity) => {
				return (
					moment(entity.expirationTime).valueOf() <
						moment(new Date().toISOString()).valueOf() &&
					entity.taskStatus === TaskStatus.Active
				)
			}),
			Object.values(patients),
			files
		)
	}

	@Selector([UserState.currentUser, PatientState.entities, FileState.files])
	public static currentPatientTasks(
		state: CollatableEntityCollections<TaskDTO>,
		user: UserInterface,
		patients: EntityDictionary<string, PatientDTO>,
		files: EntityDictionary<string, FileDTO>
	): TaskPatientInterface[] {
		if (!state.focusOnId) return []
		return TaskState.mobileHydrate(
			Object.values(state.entities).filter((entity) => {
				if (
					user._degree !== 'NO_DEGREE' &&
					user._degree !== 'MEDICAL_ASSISTANT'
				) {
					return (
						moment(entity.expirationTime).valueOf() >=
							moment(new Date().toISOString()).valueOf() &&
						entity.taskStatus === TaskStatus.Active &&
						entity.taskPatient.id === state.focusOnId
					)
				}
				return (
					(entity.assignee?.id === user.id &&
						moment(entity.expirationTime).valueOf() >=
							moment(new Date().toISOString()).valueOf() &&
						entity.taskStatus === TaskStatus.Active &&
						entity.taskPatient.id === state.focusOnId) ||
					(!entity.assignee &&
						moment(entity.expirationTime).valueOf() >=
							moment(new Date().toISOString()).valueOf() &&
						entity.taskStatus === TaskStatus.Active &&
						entity.taskPatient.id === state.focusOnId)
				)
			}),
			Object.values(patients),
			files
		)
	}

	@Selector([UserState.currentUser, PatientState.entities, FileState.files])
	public static currentTasks(
		state: CollatableEntityCollections<TaskDTO>,
		user: UserInterface,
		patients: EntityDictionary<string, PatientDTO>,
		files: EntityDictionary<string, FileDTO>
	): TaskPatientInterface[] {
		return TaskState.mobileHydrate(
			Object.values(state.entities).filter((entity) => {
				if (
					user._degree !== 'NO_DEGREE' &&
					user._degree !== 'MEDICAL_ASSISTANT'
				) {
					return (
						moment(entity.expirationTime).valueOf() >=
							moment(new Date().toISOString()).valueOf() &&
						entity.taskStatus === TaskStatus.Active
					)
				}
				return (
					(entity.assignee?.id === user.id &&
						moment(entity.expirationTime).valueOf() >=
							moment(new Date().toISOString()).valueOf() &&
						entity.taskStatus === TaskStatus.Active) ||
					(!entity.assignee &&
						moment(entity.expirationTime).valueOf() >=
							moment(new Date().toISOString()).valueOf() &&
						entity.taskStatus === TaskStatus.Active)
				)
			}),
			Object.values(patients),
			files
		)
	}

	private static mobileHydrate(
		tasks: TaskDTO[],
		patients: PatientDTO[],
		files: EntityDictionary<string, FileDTO>
	): TaskPatientInterface[] {
		const tmpTasks: TaskPatientInterface[] = []
		let lastTimeLine: string = new Date().toISOString()
		orderBy([...tasks], 'taskStartTime', 'asc')
			.map((data) => {
				const patient = patients.find(
					(patient) => patient.id === data.taskPatient.id
				)
				return {
					id: `${data.id}-${patient?.id}`,
					timeLine: data.taskStartTime,
					patient: {
						id: patient?.id,
						firstName: patient?.firstName,
						lastName: patient?.lastName,
						gender: patient?.gender,
						room: patient?.room,
						avatar: patient?.avatar?.id ? files[patient.avatar.id] : null
					},
					taskData: {
						id: data.id,
						expirationTime: data.expirationTime,
						taskCompletedBy: data.taskCompletedBy || null,
						taskStartTime: data.taskStartTime,
						taskStatus: data.taskStatus,
						taskTreatmentAction: data.taskTreatmentAction,
						assignee: data.assignee
					}
				}
			})
			.forEach(({ id, timeLine, patient, taskData }) => {
				lastTimeLine = !lastTimeLine ? timeLine : lastTimeLine
				const timeLineIdx = tmpTasks.findIndex((el) => {
					return (
						moment(el.timeLine).hours() === moment(timeLine).hours() &&
						moment(el.timeLine).minutes() === moment(timeLine).minutes()
					)
				})
				if (timeLineIdx !== -1) {
					const patientIdx = tmpTasks[timeLineIdx].tasks.findIndex(
						(el) => el.patient.id === patient.id
					)
					if (patientIdx !== -1) {
						tmpTasks[timeLineIdx].length =
							taskData.taskStatus === TaskStatus.Active
								? tmpTasks[timeLineIdx].length + 1
								: tmpTasks[timeLineIdx].length
						tmpTasks[timeLineIdx].tasks[patientIdx].data = [
							...tmpTasks[timeLineIdx].tasks[patientIdx].data,
							{ ...taskData }
						]
					} else {
						tmpTasks[timeLineIdx].length =
							taskData.taskStatus === TaskStatus.Active
								? tmpTasks[timeLineIdx].length + 1
								: tmpTasks[timeLineIdx].length
						tmpTasks[timeLineIdx].tasks = [
							...tmpTasks[timeLineIdx].tasks,
							{ id, patient, data: [{ ...taskData }] }
						]
					}
				} else {
					tmpTasks.push({
						id,
						length: taskData.taskStatus === TaskStatus.Active ? 1 : 0,
						timeLine,
						isLaterTimeLine:
							moment(timeLine).valueOf() > moment(lastTimeLine).valueOf(),
						tasks: [
							{
								id,
								patient,
								data: [{ ...taskData }]
							}
						]
					})
					lastTimeLine =
						moment(timeLine).valueOf() > moment(lastTimeLine).valueOf()
							? timeLine
							: lastTimeLine
				}
			})

		const activeTimeTasks = orderBy([...tmpTasks], 'timeLine', 'asc').filter(
			(el) => !el.isLaterTimeLine
		)
		const featureTask = orderBy([...tmpTasks], 'timeLine', 'asc').filter(
			(el) =>
				el.isLaterTimeLine &&
				moment(el.timeLine).diff(new Date(), 'minute') <= 60
		)

		if (featureTask.length) {
			return [...activeTimeTasks, ...featureTask]
		}
		return [...activeTimeTasks]
	}

	private static hydrate(
		medicalAssistants: UserInterface[],
		tasks: TaskDTO[],
		patients: PatientDTO[],
		files: EntityDictionary<string, FileDTO>
	): OverdueTaskPatientInterface[] {
		return orderBy([...tasks], 'taskStartTime', 'asc')
			.map((data) => {
				const patient = patients.find(
					(patient) => patient.id === data.taskPatient.id
				)
				return {
					id: `${data.id}-${patient?.id}`,
					expirationTime: data.expirationTime,
					patient: patient
						? {
								id: patient?.id,
								firstName: patient?.firstName,
								lastName: patient?.lastName,
								gender: patient?.gender,
								room: patient?.room,
								avatar: patient?.avatar?.id ? files[patient.avatar.id] : null
						  }
						: null,
					assignee: data.assignee
						? medicalAssistants.find((ma) => ma.id === data?.assignee?.id)
						: null,
					task: {
						id: data.id,
						expirationTime: data.expirationTime,
						taskCompletedBy: data.taskCompletedBy || null,
						taskStartTime: data.taskStartTime,
						taskStatus: data.taskStatus,
						taskTreatmentAction: data.taskTreatmentAction
					}
				}
			})
			.filter((i) => i.patient)
	}

	@DataAction()
	getOverduePatientTasks(@Payload('id') id: string): Observable<void> {
		return this.backendService.getTaskBrowsing(id).pipe(
			tap((res: TaskBackend) => {
				this.patchState({
					overduePatientTaskList: !this.getState().focusOnId
						? []
						: res.data.filter((entity) => {
								return (
									moment(entity.expirationTime).valueOf() <
										moment(new Date().toISOString()).valueOf() &&
									entity.taskStatus === TaskStatus.Active &&
									entity.taskPatient.id === this.getState().focusOnId
								)
						  })
				})
			}),
			ignoreElements()
		)
	}

	@DataAction()
	focusOnTaskPatient(@Payload('id') id: string): Observable<void> {
		this.ctx.patchState({
			focusOnId: id
		})
		return of()
	}

	@DataAction()
	public updateTask(
		@Payload('entityId') id: string,
		@Payload('entityDiff') entityDiff: UpdateTaskInterface
	): Observable<void> {
		return this.backendService.updateTask(id, entityDiff).pipe(
			tap((task) => {
				this.removeOne(task.id)
				this.ctx.patchState({
					overduePatientsTaskList: [
						...this.getState().overduePatientsTaskList.filter(
							(t) => t.id !== task.id
						)
					]
				})
			}),
			ignoreElements()
		)
	}

	updateWithModifiedTasks(patientIds?: string[]) {
		this.patchState({ isLoading: true });
		return this.backendService.findAllTasks(patientIds).pipe(
			tap(res => {
				if (!res) return;
				this.upsertMany(res.filter((r) => r.taskPatient));
				res.filter((r) => r.taskPatient && !!r.expirationTime)
					.forEach((t) => {
						const idx = this.getState().overduePatientsTaskList.findIndex(
							(e) => e.id === t.id
						)
						if (idx !== -1) return
						this.ctx.patchState({
							overduePatientsTaskList: [
								...this.getState().overduePatientsTaskList,
								t
							]
						})
					})
			}),
			finalize(() => this.patchState({ isLoading: false }))
		)
	}

	public override ngxsOnInit() {
		this.storeEvents.patientsModified$.pipe(
			tap(() => {
				if (this.taskStateSubscription) this.taskStateSubscription.unsubscribe();
				const patientIds = this.store.selectSnapshot(PatientState.ids);
				// just uncomment it and proper data for existing patients will be received
				// this.taskStateSubscription = combineLatest([this.updateWithModifiedTasks(patientIds), this.backendTaskTimeUpdates$]).subscribe();
			})
    ).subscribe();

		this.storeEvents.logout$.pipe(
      tap(() => {
        this.reset();
				if (this.taskStateSubscription) this.taskStateSubscription.unsubscribe();
      })
    ).subscribe();
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}
}
