import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import {
	createEntityCollections,
	EntityDictionary
} from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import {
	EMPTY,
	ignoreElements,
	interval,
	Observable,
	of,
	Subject,
	Subscription,
	take,
	takeUntil,
	tap,
	timer
} from 'rxjs'
import { FileDTO } from '../../shared/model/file'
import { BackendService } from '../../shared/services/backend.service'
import { StoreEventsService } from '../store-events.service'
import moment from 'moment'

export const fileFeatureName = 'file'

@StateRepository()
@State<CollatableEntityCollections<FileDTO>>({
	name: fileFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class FileState extends CollatableEntityCollectionsRepository<
	FileDTO,
	EntityCollation
> {
	unsubscribeLoadProcessFile$ = new Subject()
	private loadProcessFileIdx: string[] = []
	private newFileIdx: string[] = []
	private isFirstLoading: boolean = true
	private fileUpdatesSubscription: Subscription

	constructor(
		private backendService: BackendService,
		private actions: Actions,
		private storeEvents: StoreEventsService
	) {
		super()
	}

	@Selector()
	public static files(
		state: CollatableEntityCollections<FileDTO>
	): EntityDictionary<string, FileDTO> {
		return state.entities
	}

	@Selector()
	public static pccPatientImage(
		state: CollatableEntityCollections<FileDTO>
	): Blob | null {
		return state.pccCurrentPatientImage
	}

	@Selector()
	public static createdFileId(
		state: CollatableEntityCollections<FileDTO>
	): string | null {
		return state.newFileId
	}

	public override ngxsOnInit() {
		this.storeEvents.loggedInAndRefreshToken$
			.pipe(
				tap(() => {
					if (this.fileUpdatesSubscription) {
						this.fileUpdatesSubscription.unsubscribe()
					}
					this.fileUpdatesSubscription =
						this.checkExpiredImageAndUpdate().subscribe()
				})
			)
			.subscribe()

		this.storeEvents.logout$
			.pipe(
				tap(() => {
					this.unsubscribeLoadProcessFile$.next(null)
					this.unsubscribeLoadProcessFile$.complete()
					this.loadProcessFileIdx = []
					this.newFileIdx = []
					this.isFirstLoading = true
					this.reset()
					if (this.fileUpdatesSubscription) {
						this.fileUpdatesSubscription.unsubscribe()
					}
				})
			)
			.subscribe()
	}

	@DataAction()
	public async loadFiles(@Payload('id') id: string) {
		if (!id) return
		return this.backendService.getFile(id).pipe(
			tap((res) => {
				const expiresValue = res.signedUrl
					.split('Expires')[1]
					.split('&')[0]
					.split('=')[1]
				const expiredTime = moment().add(expiresValue, 'seconds').toISOString()
				this.upsertOne({ ...res, expiredTime })
			})
		)
	}

	@DataAction()
	public getPccPatientImage(@Payload('id') id: string) {
		if (!id) return
		return this.backendService
			.getPccImage(id)
			.pipe(
				tap((pccCurrentPatientImage) =>
					this.patchState({ pccCurrentPatientImage })
				)
			)
	}

	@DataAction()
	public createNewPatientFile(
		@Payload('data')
		data: {
			name: string
			mimeType: string
		},
		@Payload('file') pccFile: Blob
	) {
		return this.backendService.createFile(data).pipe(
			tap((file) => {
				// @ts-ignore
				this.updateCreatedFile(file.signedUrl, pccFile)
				this.patchState({ newFileId: file.id })
			})
		)
	}

	@DataAction()
	public updateCreatedFile(
		@Payload('url') url: string,
		@Payload('data') data: Blob
	) {
		return this.backendService.updateFileBody(url, data).pipe(ignoreElements())
	}

	@DataAction()
	public logout() {
		this.loadProcessFileIdx = []
		this.newFileIdx = []
		this.isFirstLoading = true
		this.patchState({
			newFileId: null,
			pccCurrentPatientImage: null
		})
	}

	protected setPaginationSetting(): Observable<any> {
		throw new Error('Method not implemented.')
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		if (!ids) return EMPTY

		for (const id of ids) {
			if (!this.loadProcessFileIdx.includes(id)) {
				if (this.isFirstLoading) {
					this.loadProcessFileIdx.push(id)
				} else {
					this.newFileIdx.push(id)
				}
			}
		}

		timer(3000).subscribe(() => {
			if (!this.isFirstLoading) {
				if (this.newFileIdx.length) {
					this.loadProcessFileIdx.push(...this.newFileIdx)
					this.newFileIdx = []
				}
				return
			}
			this.isFirstLoading = false
			const loadProcessFileObserver = {
				next: async (x: number) => {
					await this.loadFiles(this.loadProcessFileIdx[x])
				}
			}
			interval(3000)
				.pipe(
					takeUntil(this.unsubscribeLoadProcessFile$),
					take(this.loadProcessFileIdx.length)
				)
				.forEach(loadProcessFileObserver.next)
		})
		return of()
	}

	private checkExpiredImageAndUpdate() {
		return this.backendService.intervalUpdates$.pipe(
			tap(() => {
				if (!Object.keys(this.entities).length) {
					return
				}

				Object.values(this.entities).forEach((file) => {
					if (!file.expiredTime) {
						return
					}

					if (file.expiredTime <= new Date().toISOString()) {
						this.loadFiles(file.id)
					}
				})
			})
		)
	}

	private upsertAllFiles(res: FileDTO[]) {
		this.upsertMany(res)
	}
}
