import { Router } from "@angular/router";
import { isNativeDevice } from "../helpers/is-native-device";
import { inject } from "@angular/core";
import { isForcedResolution } from "../helpers/is-forced-resolutions";

export function nativeDeviceGuard() {
  if (isNativeDevice() && !(isForcedResolution() === false)) {
    inject(Router).navigateByUrl('/reports');
    return false;
  }
  return true;
}