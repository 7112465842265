import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzShapeSCType, NzSizeLDSType } from 'ng-zorro-antd/core/types';
import { ShortNamePipe } from '../shared/pipes/short-name.pipe';

@Component({
  selector: 'lib-avatar',
  standalone: true,
  imports: [NzAvatarModule, ShortNamePipe],
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
  @Output() nzError: EventEmitter<Event> = new EventEmitter<Event>();
  @Input() nzShape: NzShapeSCType = 'circle';
  @Input() nzSize: number | NzSizeLDSType = 'default';
  @Input() nzGap: number = 4;
  @Input() nzText?: string | undefined;
  @Input() nzSrc?: string | undefined;
  @Input() nzSrcSet?: string | undefined;
  @Input() nzAlt?: string | undefined;
  @Input() nzIcon?: string | undefined;
  @Input() textColor: string = 'var(--avatar-text-color)';
  @Input() bgColor: string = 'var(--avatar-bg-color)';
  @Input() shortenText: boolean = true;
}
