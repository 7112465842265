import { computed, inject, Injectable, signal } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { IntegrationsService } from './integrations.service';

export type CurrentAppVersion = string | 'Unknown';

@Injectable({ providedIn: 'root' })
export class NewVersionCheckerService {
  private readonly swUpdate = inject(SwUpdate);
  private readonly integrationsService = inject(IntegrationsService);
  private currentVersionTimestamp = signal<CurrentAppVersion>('Unknown');
  public readonly getCurrentVersionTimestamp = computed(() => this.currentVersionTimestamp() === 'Unknown' ? 'Unknown' : new Date(this.currentVersionTimestamp()).toISOString());

  constructor() {
    this.checkForUpdate();
    this.loadSwVersion();
  }

  private checkForUpdate(): void {
    if (this.swUpdate.isEnabled) {
      const intervalTime$ = interval(30 * 1000);
      const intervalTimeOnceAppIsStable$ = concat(intervalTime$);
      intervalTimeOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await this.swUpdate.checkForUpdate();
          if (updateFound) {
            console.log('A new version is available.');
            window.location.reload();
          }
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }
  }

  private async loadSwVersion(): Promise<void> {
    try {
      const response = await fetch('/ngsw.json');
      const json = await response.json();
      this.currentVersionTimestamp.set(json.timestamp || 'Unknown');
      this.integrationsService.logrocketInit(this.getCurrentVersionTimestamp());
    } catch (error) {
      console.log('Error with Service Worker', error);
    }
  }
}