import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	OnInit
} from '@angular/core'
import { routingAnimation } from '../../../../../shared/animations/routing-animation'
import { AuthState } from '../../../../../store/auth/auth.state'
import { Select } from '@ngxs/store'
import { ErrorState } from '../../../../../store/error/error.state'
import { Observable, take } from 'rxjs'
import { MfaLoginRequestV2 } from '@biot-client/biot-client-ums'
import { NzMessageService } from 'ng-zorro-antd/message'
import { PreferenceState } from '../../../../..//store/preference/preference.state'
import { DeviceState } from '../../../../..//store/device/device.state'
import { DeviceDTO } from '../../../../..//shared/model/device.model'
import { DepartmentDTO } from 'projects/aiomed/src/shared/model/permission.model'
import { ActivatedRoute, Router } from '@angular/router'
import { filter, tap } from 'rxjs/operators'
import LogRocket from 'logrocket'
import { PCC_CLOSE_WINDOW_KEY, PCC_CLOSE_WINDOW_REASON_KEY } from 'projects/aiomed/src/shared/constants/pcc-close-window-duration.constant'
import { PccCloseWindowReason } from 'projects/aiomed/src/shared/enums/pcc-window-close-reason.enum'

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	animations: [routingAnimation],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
	@Select(ErrorState.errorMessage)
	errorMessage$: Observable<string | null>
	@Select(AuthState.isMfaRequired)
	isMfaRequired$: Observable<boolean>
	@Select(AuthState.needChooseDepartment)
	needChooseDepartment$: Observable<boolean>
	@Select(AuthState.needChooseDevice)
	needChooseDevice$: Observable<boolean>
	@Select(DeviceState.allDevices)
	sharedDevices$: Observable<DeviceDTO[]>
	@Select(DeviceState.isLoading)
	deviceLoading$: Observable<boolean>
	@Select(AuthState.isLoading)
	isAuthLoading$: Observable<boolean>
	source$: Observable<Event>
	public selectedDeviceId: string = ''
	@HostBinding('@routingAnimation') private routing: any
	public isMobile = this.preferenceState.isMobile;
	public isLoginWithCred = false;

	constructor(
		private authState: AuthState,
		private message: NzMessageService,
		private preferenceState: PreferenceState,
		private route: ActivatedRoute,
		private readonly router: Router,
	) {}

	ngOnInit(): void {
		this.route.queryParams
			.pipe(
				tap(params => {
					if (params?.['error']) {
						this.authState.showPCCAuthorizationError(params['error']);
						setTimeout(() => {
							this.router.navigate([], {
								queryParams: {
									'error': null,
								},
								queryParamsHandling: 'merge'
							});
						}, 500);
					}
				}),
				take(1),
				filter((params) => params['refreshToken'])
			)
			.subscribe((data) => {
				if (!data || (!data['refreshToken'] && this.isMobile)) {
					return
				}
				this.authState.setIfLoggingFromPcc(true);
				this.authState.loginWithPccIntoSystem(data['refreshToken'])
			})
	}

	handlerFormSubmitEmitter($event: any): void {
		this.authState.login($event)
	}

	handlerFormMfaSubmitEmitter($event: MfaLoginRequestV2): void {
		this.authState.mfaLogin($event)
	}

	handlerResendMfaCodeEmitter(): void {
		this.authState.mfaResend()
		this.message.info('New code sent to phone')
	}

	handlerArrowClick(): void {
		this.authState.resetMfaRequired(false)
		this.authState.logout()
	}

	handlerFormDepartmentSubmitEmitter(department: DepartmentDTO) {
		this.authState.canLogIntoSystem(false, department)
	}

	setNeedChooseDepartment(): void {
		this.authState.setNeedChooseDepartment(false)
		if (this.authState.isLoginFromPCC) {
			this.preferenceState.setPccLoginWasPerformedWithCNAUser(true);
			LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
				[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.PccLoginWithCNAuser
			});
		} else {
			this.message.warning('You do not have access rights')
		}
		this.authState.setIfLoggingFromPcc(false);
		this.authState.resetTokens()
	}

	public setCurrentDeviceIdAndLogin(): void {
		this.preferenceState.setCurrentDeviceId(this.selectedDeviceId)
		this.authState.canLogIntoSystem(true)
	}

	handlerLoginWithPcc() {
		this.authState.loginWithPcc(this.isMobile ? 'login-tablet' : 'desktop');
	}
}
