import {
	CheckShiftSentEmrInformationInterface,
	PatientVItalObservationFields,
	ReportType,
	VitalsFields
} from '../../shared/model/report.model'

export function CheckMissingVital(
	vitals: PatientVItalObservationFields,
	checkShiftSentEmrInformation: CheckShiftSentEmrInformationInterface,
	vital: VitalsFields,
	reportType: ReportType
): boolean {
	const checkHaveSomeVitals = (
		vitals: PatientVItalObservationFields
	): boolean =>
		[
			vitals?.bodyTemperature?.value &&
				vitals?.bodyTemperature?.isActualValue &&
				reportType !== ReportType.Time,
			vitals?.systolicPressure?.value &&
				vitals?.systolicPressure?.isActualValue &&
				reportType !== ReportType.Time,
			vitals?.diastolicPressure?.value &&
				vitals?.diastolicPressure?.isActualValue &&
				reportType !== ReportType.Time,
			vitals?.respirationRate?.value &&
				vitals?.respirationRate?.isActualValue &&
				reportType !== ReportType.Time,
			vitals?.spo2?.value &&
				vitals?.spo2?.isActualValue &&
				reportType !== ReportType.Time,
			vitals?.bloodGlucose?.value &&
				vitals?.bloodGlucose?.isActualValue &&
				reportType !== ReportType.Time,
			vitals?.heartRate?.value &&
				vitals?.heartRate?.isActualValue &&
				reportType !== ReportType.Time
		].some(Boolean)

	const vitalChecks: { [key in VitalsFields]?: boolean } = {
		[VitalsFields.BloodGlucose]:
			checkShiftSentEmrInformation.hasOwnProperty('bg'),
		[VitalsFields.BodyTemperature]:
			checkShiftSentEmrInformation.hasOwnProperty('bt'),
		[VitalsFields.DiastolicPressure]:
			checkShiftSentEmrInformation.hasOwnProperty('bp'),
		[VitalsFields.SystolicPressure]:
			checkShiftSentEmrInformation.hasOwnProperty('bp'),
		[VitalsFields.HeartRate]: checkShiftSentEmrInformation.hasOwnProperty('hr'),
		[VitalsFields.RespirationRate]:
			checkShiftSentEmrInformation.hasOwnProperty('rr'),
		[VitalsFields.SpO2]: checkShiftSentEmrInformation.hasOwnProperty('spo2')
	}

	return !!(vitalChecks[vital] && checkHaveSomeVitals(vitals))
}
