import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { ReportState } from './report.state'

@NgModule({
	declarations: [],
	providers: [],
	imports: [NgxsModule.forFeature([ReportState])]
})
export class ReportStoreModule {}
