import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, Subscription, tap } from 'rxjs'
import {
	ShiftPlanerDTO,
	ShiftType
} from '../../shared/model/shift-planer.model'
import { PatientState } from '../patient/patient.state'
import { PatientInterface } from '../../shared/model/patient'
import { ExportState } from '../export/export.state'
import { ExportAllInterface } from '../../shared/model/export'
import { TreatmentPlanDTO } from '../../shared/model/treatment-plan'
import { checkVitalsToTreatmentPlan } from '../../core/helpers/check-vitals-to-treatment-plan'
import { combineTreatmentPlans } from '../../core/helpers/combine-treatment-plans'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { entitiesFilter } from '../../core/helpers/filter'
import { MeasurementState } from '../measurement/measurement.state'
import { EntityDictionary } from '@angular-ru/cdk/entity'
import { PatientObservationDTO } from '../../shared/model/patient-observation'
import { isAnyVitalsMissing } from '../../core/helpers/is-any-shift-vitals-missing'
import { orderBy } from 'lodash-es'
import { BackendService } from '../../shared/services/backend.service'
import { StoreEventsService } from '../store-events.service'
import { checkShiftTime } from '../../core/helpers/check-shift-time'
import {
	CheckShiftSentEmrInformationInterface,
	SentEmrInformation,
	ShiftCategory,
	VitalStatusIndicator
} from '../../shared/model/report.model'

export const shiftPlanerFeatureName = 'shiftPlaner'

@StateRepository()
@State<ShiftPlanerDTO>({
	name: shiftPlanerFeatureName,
	defaults: {
		notRequired: [],
		vitalsRound: [],
		date: new Date().toISOString(),
		textFilter: '',
		currentShift: ShiftType.DayShift
	}
})
@Injectable()
export class ShiftPlanerState extends NgxsDataRepository<ShiftPlanerDTO> {
	private dateUpdatesSubscription: Subscription

	constructor(
		private backendService: BackendService,
		private storeEvents: StoreEventsService
	) {
		super()
	}

	@Selector([
		PatientState.allDepartmentPatients,
		ExportState.patientsAllExports,
		MeasurementState.measurement,
	])
	public static shift(
		state: ShiftPlanerDTO,
		patients: Partial<PatientInterface>[],
		patientsAllExports: ExportAllInterface[],
		measurements: EntityDictionary<string, PatientObservationDTO>,
	): Partial<ShiftPlanerDTO> {
		return ShiftPlanerState.hydrate(
			entitiesFilter(state.textFilter, patients).map((p: PatientInterface) => {
				const patientAllExport = patientsAllExports.find(
					(e) => e.patientId === p.id
				)
				const businessTreatmentPlans = p.treatmentPlan?.filter(
					(t: TreatmentPlanDTO) => t.endTime && new Date(t.endTime) > new Date()
				)

				return {
					...p,
					hasBusinessRule: !!(
						businessTreatmentPlans && businessTreatmentPlans.length
					),
					measurement: measurements[p.id],
					isReadyForReporting: (!p.treatmentPlan || !p.treatmentPlan.length || !measurements[p.id]) ? false : isAnyVitalsMissing(p, state.currentShift),
					checkShiftSentEmrInformation: p.treatmentPlan
						? ShiftPlanerState.toReportShiftCheckVitalsToTreatmentPlan(
								p.treatmentPlan.filter(
									(t: TreatmentPlanDTO) =>
										!t.endTime || new Date(t.endTime) > new Date()
								),
								// @ts-ignore
								!patientAllExport
									? { patientId: p.id, data: [] }
									: patientAllExport,
								state.date
						  )
						: null
				}
			}),
			state.currentShift
		)
	}

	@Selector()
	public static currentShift(state: ShiftPlanerDTO): ShiftType {
		return state.currentShift
	}

	@Selector()
	public static currentShiftName(state: ShiftPlanerDTO): string {
		return state.currentShift === ShiftType.DayShift
			? 'Morning Shift'
			: state.currentShift === ShiftType.EveningShift
			? 'Evening Shift'
			: 'Night Shift'
	}

	private static hydrate(
		patients: Partial<PatientInterface>[],
		currentShift: ShiftType
	): Partial<ShiftPlanerDTO> {
		const mapPatientToDTO = (p: Partial<PatientInterface>) => ({
			id: p.id as string,
			room: p.room,
			avatarUrl: p.avatar?.signedUrl,
			name: `${p.lastName}, ${p.firstName ? p.firstName[0] : p.firstName}`,
			treatmentPlan: p.treatmentPlan,
			...ShiftPlanerState.toShiftPlanerEmrStatus(
				p.checkShiftSentEmrInformation,
				currentShift
			),
			_ownerOrganization: (p as any)._ownerOrganization
		})

		const filterAndMapPatients = (
			patients: Partial<PatientInterface>[],
			predicate: (p: Partial<PatientInterface>) => boolean
		) => orderBy(patients.filter(predicate).map(mapPatientToDTO), 'room', 'asc')

		const vitalsRoundPredicate = (p: Partial<PatientInterface>): boolean =>
			!!p.checkShiftSentEmrInformation &&
			(p.checkShiftSentEmrInformation.category as ShiftCategory) !==
				ShiftCategory.VitalsNotRequired

		const notRequiredPredicate = (p: Partial<PatientInterface>): boolean =>
			!p.checkShiftSentEmrInformation ||
			(p.checkShiftSentEmrInformation.category as ShiftCategory) ===
				ShiftCategory.VitalsNotRequired

		return {
			vitalsRound: filterAndMapPatients(patients, vitalsRoundPredicate),
			notRequired: filterAndMapPatients(patients, notRequiredPredicate)
		}
	}

	private static toShiftPlanerEmrStatus(
		checkShiftSentEmrInformation:
			| CheckShiftSentEmrInformationInterface
			| null
			| undefined,
		currentShift: ShiftType
	) {
		const statusMapping = {
			[SentEmrInformation.Completed]: {
				emrStatus: VitalStatusIndicator.Completed,
				isVitalComplete: true,
				isVitalPartial: false
			},
			[SentEmrInformation.NotRequired]: {
				emrStatus: null,
				isVitalComplete: false,
				isVitalPartial: false
			},
			[SentEmrInformation.NeedExportToEmr]: {
				emrStatus: VitalStatusIndicator.NeedExportToEmr,
				isVitalComplete: false,
				isVitalPartial: false
			},
			[SentEmrInformation.CriticalReportTime]: {
				emrStatus: VitalStatusIndicator.NeedExportToEmr,
				isVitalComplete: false,
				isVitalPartial: false
			},
			[SentEmrInformation.FailedEmr]: {
				emrStatus: VitalStatusIndicator.FailedExportToEmr,
				isVitalComplete: false,
				isVitalPartial: false
			},
			[SentEmrInformation.PartialExport]: {
				emrStatus: VitalStatusIndicator.PartialExportEmr,
				isVitalComplete: false,
				isVitalPartial: true
			}
		}

		const defaultStatus = {
			emrStatus: null,
			isVitalComplete: false,
			isVitalPartial: false
		}

		const status = checkShiftSentEmrInformation?.status
		const { emrStatus, isVitalComplete, isVitalPartial } = status
			? statusMapping[status] || defaultStatus
			: defaultStatus
		const notRequiredStatus = {
			isComplete:
				checkShiftSentEmrInformation?.hasSomeVitals &&
				currentShift ===
					checkShiftTime
						.getShiftByTime(new Date().toISOString())
						.toLocaleLowerCase()
						.split(' ')
						.join('_'),
			isGray: !checkShiftSentEmrInformation?.hasSomeVitals
		}

		return {
			emrStatus,
			isVitalComplete,
			isVitalPartial,
			checkShiftSentEmrInformation,
			notRequiredStatus
		}
	}

	private static toReportShiftCheckVitalsToTreatmentPlan(
		treatmentPlans: TreatmentPlanDTO[],
		{ data }: ExportAllInterface,
		date: string
	) {
		if (!treatmentPlans.length) {
			return null
		}
		return checkVitalsToTreatmentPlan(
			combineTreatmentPlans(treatmentPlans),
			data,
			date
		)
	}

	public override ngxsOnInit() {
		this.storeEvents.loggedInAndRefreshToken$
			.pipe(
				tap(() => {
					if (this.dateUpdatesSubscription) {
						this.dateUpdatesSubscription.unsubscribe()
					}
					this.dateUpdatesSubscription = this.updateCurrentTime().subscribe()
				})
			)
			.subscribe()

		this.storeEvents.logout$
			.pipe(
				tap(() => {
					if (this.dateUpdatesSubscription) {
						this.dateUpdatesSubscription.unsubscribe()
					}
				})
			)
			.subscribe()
	}

	@DataAction()
	setShiftPlanDate(@Payload('date') date: string) {
		this.patchState({ date })
	}

	@DataAction()
	setTextFilter(@Payload('text') text: string) {
		this.patchState({ textFilter: text })
	}

	@DataAction()
	setCurrentShift() {
		const hours = new Date().getHours()
		let currentShift: ShiftType

		const { morning_shift, day_shift } = checkShiftTime

		if (hours >= morning_shift.min && hours < morning_shift.max) {
			currentShift = ShiftType.DayShift
		} else if (hours >= day_shift.min && hours < day_shift.max) {
			currentShift = ShiftType.EveningShift
		} else {
			currentShift = ShiftType.NightShift
		}

		if (this.detectShiftChanged(currentShift)) {
			this.shiftChanged()
		}

		this.patchState({ currentShift })
	}

	@DataAction()
	shiftChanged() {
		return
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}

	private updateCurrentTime() {
		return this.backendService.intervalUpdates$.pipe(
			tap(() => this.setCurrentShift())
		)
	}

	private detectShiftChanged(currentShift: ShiftType): boolean {
		const previousShiftType = localStorage.getItem(
			shiftPlanerFeatureName + '.' + 'currentShift'
		)
		if (!previousShiftType) {
			localStorage.setItem(shiftPlanerFeatureName + '.' + 'currentShift', currentShift)
			return false
		}
		const result = currentShift !== previousShiftType
		if (result) {
			localStorage.setItem(shiftPlanerFeatureName + '.' + 'currentShift', currentShift)
		}
		return result
	}
}
