import { inject } from "@angular/core"
import { isMobile } from "./functions"
import { DeviceDetectorService } from "ngx-device-detector"

export function isNativeDevice() {
  const userAgent =
    window.navigator.userAgent ||
    navigator.userAgent ||
    navigator.vendor ||
    // @ts-ignore
    window.opera
  return (
    !!(!inject(DeviceDetectorService).isDesktop(userAgent) && isMobile.any()) ||
    (!!userAgent.match(/Version\/[\d\.]+.*Safari/) && isMobile.any())
  )
}