import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { ShiftPlanerState } from './shift-planer.state'

@NgModule({
	declarations: [],
	providers: [],
	imports: [NgxsModule.forFeature([ShiftPlanerState])]
})
export class ShiftPlanerStoreModule {}
