import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { UserState } from './user.state'

@NgModule({
	declarations: [],
	imports: [NgxsModule.forFeature([UserState])],
	providers: []
})
export class UserStoreModule {}
