import {
	CollatableEntityCollections,
	CollatableEntityCollectionsRepository,
	defaultEntityCollation,
	EntityCollation
} from '../root-store-common'
import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Actions, Selector, State } from '@ngxs/store'
import { createEntityCollections } from '@angular-ru/cdk/entity'
import { Injectable } from '@angular/core'
import { EMPTY, ignoreElements, Observable, tap } from 'rxjs'
import { BackendService } from '../../shared/services/backend.service'
import {
	TaskBackend,
	TaskDTO,
	TaskStatus,
	UpdateTaskInterface
} from '../../shared/model/task.model'
import moment from 'moment/moment'

export const taskFeatureName = 'taskBrowsing'

@StateRepository()
@State<CollatableEntityCollections<TaskDTO>>({
	name: taskFeatureName,
	defaults: {
		...createEntityCollections(),
		...defaultEntityCollation()
	}
})
@Injectable()
export class TaskBrowsingState extends CollatableEntityCollectionsRepository<
	TaskDTO,
	EntityCollation
> {
	constructor(
		private backendService: BackendService,
		private actions: Actions
	) {
		super()
	}

	@Selector()
	public static currentOverduePatientTasks(
		state: CollatableEntityCollections<TaskDTO>
	): number {
		return state.patientOverdueTask
	}

	@Selector()
	public static currentActivePatientTasks(
		state: CollatableEntityCollections<TaskDTO>
	): number {
		return state.patientActiveTask
	}

	@Selector()
	public static totalCount(
		state: CollatableEntityCollections<TaskDTO>
	): number {
		return state.totalCount
	}

	@Selector()
	public static tasks(state: CollatableEntityCollections<TaskDTO>): TaskDTO[] {
		return Object.values(state.entities)
	}

	@DataAction()
	public updateTask(
		@Payload('entityId') id: string,
		@Payload('entityDiff') entityDiff: UpdateTaskInterface
	): Observable<void> {
		return this.backendService.updateTask(id, entityDiff).pipe(
			tap((task) => {
				const tasks = Object.values(this.entities)
				const idx = tasks.findIndex((t) => t.id === task.id)
				tasks[idx] = task
				this.setAll(tasks)
				this.patchState({
					patientOverdueTask: this.ctx.getState().patientOverdueTask - 1
				})
			}),
			ignoreElements()
		)
	}

	@DataAction()
	getPatientTasksInfo(@Payload('id') id: string): Observable<void> {
		return this.backendService.getTaskBrowsing(id).pipe(
			tap((res: TaskBackend) => {
				this.patchState({
					patientOverdueTask: !this.getState().focusOnId
						? 0
						: res.data.filter((entity) => {
								return (
									moment(entity.expirationTime).valueOf() <
										moment(new Date().toISOString()).valueOf() &&
									entity.taskStatus === TaskStatus.Active &&
									entity.taskPatient.id === this.getState().focusOnId
								)
						  }).length,
					patientActiveTask: !this.getState().focusOnId
						? 0
						: res.data.filter((entity) => {
								return (
									moment(entity.expirationTime).valueOf() >
										moment(new Date().toISOString()).valueOf() &&
									entity.taskStatus === TaskStatus.Active &&
									entity.taskPatient.id === this.getState().focusOnId
								)
						  }).length
				})
			}),
			ignoreElements()
		)
	}

	@DataAction()
	public loadPatientTaskBrowsing(
		@Payload('id') id: string,
		@Payload('page') page: number,
		@Payload('status') status?: string
	): Observable<void> {
		return this.backendService.getPatientTaskBrowsing(id, page, status).pipe(
			tap((res: TaskBackend) => {
				const tasks = Object.values(this.getState().entities)
				// this.removeAll()
				this.setAll(page === 0 ? [...res.data] : [...tasks, ...res.data])
				this.patchState({
					totalCount: res.metadata.page?.totalResults
				})
			}),
			ignoreElements()
		)
	}

	protected setPaginationSetting(): Observable<any> {
		return EMPTY
	}

	protected loadEntitiesFromBackend(
		ids: string[] | undefined
	): Observable<void> {
		return EMPTY
	}
}
