import { Component } from '@angular/core'
import { AlertState } from '../../store/alert/alert.state'
import { AlertRuleState } from '../../store/alert-rule/alert-rule.state'
import { AlertsBrowsingState } from '../../store/alerts-browsing/alerts-browsing.state'
import { ArchivedPatientState } from '../../store/archived-patient/archived-patient.state'
import { AuthState } from '../../store/auth/auth.state'
import { DepartmentState } from '../../store/department/department.state'
import { DeviceState } from '../../store/device/device.state'
import { ExportState } from '../../store/export/export.state'
import { MeasurementState } from '../../store/measurement/measurement.state'
import { PatientState } from '../../store/patient/patient.state'
import { PccState } from '../../store/pcc/pcc.state'
import { ReportState } from '../../store/report/report.state'
import { ShiftPlanerState } from '../../store/shift-planer/shift-planer.state'
import { TreatmentPlanState } from '../../store/treatment-plan/treatment-plan.state'
import { PreferenceState } from '../../store/preference/preference.state'

@Component({
	selector: 'aiomed-test-mode',
	standalone: true,
	imports: [],
	template: ``
})
export class TestModeComponent {
	constructor(
		public alertState: AlertState,
		public alertRule: AlertRuleState,
		public alertBrowsingState: AlertsBrowsingState,
		public archivedPatientState: ArchivedPatientState,
		public auth: AuthState,
		public departmentState: DepartmentState,
		public deviceState: DeviceState,
		public exportState: ExportState,
		public measurementState: MeasurementState,
		public patientState: PatientState,
		public pccState: PccState,
		public reportState: ReportState,
		public shiftPlanerState: ShiftPlanerState,
		public treatmentPlaneState: TreatmentPlanState,
		public preferenceState: PreferenceState
	) {}
}
