<nz-layout class='layout'>
  <nz-sider *ngIf='(urlLogin$ | async) && (isAuthorized$ | async)' [class.desktop-forced]='(isForcedResolution$ | async) === false' [class.tablet-forced]='(isForcedResolution$ | async) === true' [class.tablet]='isMobile'
            class='nz-slider' nzTheme='light'
            nzWidth='96px'>
    <aiomed-menu (collapseMenuEmitter)='handlerCollapseMenuEmitter($event)'
    ></aiomed-menu>
  </nz-sider>
  <nz-layout [class.desktop-forced]='(isForcedResolution$ | async) === false'
             [class.right-layout]='(isAuthorized$ | async)' [class.tablet-forced]='(isForcedResolution$ | async) === true'
             [class.tablet]='isMobile'>
    <nz-content>
      <main>
        <router-outlet></router-outlet>
        <ng-container #testModeContainer></ng-container>
      </main>
    </nz-content>
  </nz-layout>
</nz-layout>
@if (screenSaver$ | async) {
  <aiomed-screen-saver-modal [isAuthenticated]='isAuthorized$ | async'
                             [hasVirtualKeyboard]='hasVirtualKeyboard$ | async' [isMobile]='isMobile'
                             [screenBlockedStatus]='screenBlockedStatus$ | async'
                             (submitEmitter)='handlerScreenSaverSubmitEmitter()'></aiomed-screen-saver-modal>
}
@if (isSessionExpire$ | async) {
  <aiomed-warning-modal [isSessionExpired]='true'
                        [isMobile]='isMobile'
                        [width]='682'
                        [zIndex]='1010'
                        (closeModal)='hideModal()'
                        (saveModalDataEmitter)='hideModal()'
                        closeText='Close'
                        description="You've been logged out due to inactivity. Please log in again to continue"
                        saveText='Log in'
                        title='YOU’VE BEEN LOGGED OUT'></aiomed-warning-modal>
}

@if (pccLoginSettingsTabletErrorShown$ | async; as errorShown) {
  <aiomed-warning-modal [title]="'LOGIN SETTINGS'" [descriptionTemplate]='descriptionTemplate' *ngIf='true'
                        [zIndex]='1001' [isLoading]='false' [hideSave]='true' [closeText]="'OK'" [width]='560'
                        (closeModal)='hidePccLoginSettingsTabletError()'></aiomed-warning-modal>
  <ng-template #descriptionTemplate>
    <span>PointClickCare login is not enabled for your account. <strong>Please log in with your AiOmed user first.</strong> Contact your administrator to enable PointClickCare login for future use.</span>
    <div class='login-settings__error' style='margin-top: 26px'>
      <nz-collapse nzGhost>
        <nz-collapse-panel nzHeader='Technical details' [nzActive]='pccLoginErrorTechnicalDetailsVisible'>
          {{ errorShown }}
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </ng-template>
}
@if (pccLoginWasNotPerformedButClosed$ | async) {
  <aiomed-warning-modal [title]="'EMR log in required'"
                        [description]="'To report to PointClickCare, you must first log in. Please log in to perform actions.'"
                        *ngIf='true' [zIndex]='1001' [isLoading]='false' [saveText]="'Log in'" [closeText]="'Close'"
                        [width]='560' (closeModal)='pccLoginWasNotPerformedButClosed()'
                        (saveModalDataEmitter)='handlerLoginWithPcc()'></aiomed-warning-modal>
  <ng-template #descriptionTemplate>
    <span>PointClickCare login is not enabled for your account. <strong>Please log in with your AiOmed user first.</strong> Contact your administrator to enable PointClickCare login for future use.</span>
  </ng-template>
}

@if (pccLoginWasPerformedWithCNAUser$ | async) {
  <aiomed-warning-modal [title]="'Access Limited to Authorised Accounts'"
                        [isMobile]="isMobile"
                        [descriptionTemplate]="descriptionTemplate"
                        *ngIf='true' [zIndex]='1001' [isLoading]='false' [hideSave]="true" [closeText]="'Close'"
                        [width]='isMobile ? 827 : 863' (closeModal)='pccLoginWasPerformedWithCNAUserClose()'></aiomed-warning-modal>
  <ng-template #descriptionTemplate>
    <p class="cna-login-warning__description" [class.tablet]="isMobile">We're sorry, but only users classified as 'Nurse' can access our system via PCC. If you believe this is an error or need assistance with your account, please contact your administrator for support</p>
  </ng-template>
}

<aiomed-report-loader [isDark]='pccLoginReturning()'
                      [loader]='(pccLogouting$ | async) || pccLoginReturning()'></aiomed-report-loader>
@if (incorrectTimeSystem$ | async) {
  <aiomed-clock-update-modal (closeModal)='handlerCloseClockUpdateModal($event)'></aiomed-clock-update-modal>
}
