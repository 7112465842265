import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { DestroyRef, inject, Injectable, NgZone } from '@angular/core'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import {
	BehaviorSubject,
	catchError,
	EMPTY,
	exhaustMap,
	ignoreElements,
	interval,
	map,
	Observable,
	of,
	retry,
	skip,
	startWith,
	Subscription,
	switchMap,
	take,
	takeUntil,
	tap,
	throwError,
	timeout,
} from 'rxjs'
import {
	ForgotPasswordRequestV2,
	LoginService,
	MfaLoginRequestV2,
	MFALoginService,
	SelfUserService
} from '@biot-client/biot-client-ums'
import { Router } from '@angular/router'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import {
	AuthStateInterface,
	LoginRequestInterface,
	LoginResponseInterface
} from '../../shared/model/auth.model'
import { PreferenceState } from '../preference/preference.state'
import { BackendService } from '../../shared/services/backend.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { DeviceDetectorService } from 'ngx-device-detector'
import { JwtHelperService } from '@auth0/angular-jwt'
import moment from 'moment'
import { environment } from '../../environments/environment'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { DepartmentDTO } from '../../shared/model/permission.model'
import LogRocket from 'logrocket'
import { UserProfileDTO } from '../../shared/model/user.model'
import 'moment-timezone'
import { CNA_STORAGE_KEY } from '../../shared/constants/cna-storage-key.constant'
import { NetworkService } from '../../shared/services/network.service'
import { PCC_CLOSE_WINDOW_KEY, PCC_CLOSE_WINDOW_REASON_KEY, PCC_WINDOW_INTERVAL } from '../../shared/constants/pcc-close-window-duration.constant'
import { WorkerService } from '../../shared/services/worker.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { PccCloseWindowReason } from '../../shared/enums/pcc-window-close-reason.enum'

export const authFeatureName = 'auth'

type TLoginToPCC = 'desktop' | 'tablet' | 'login-tablet'

@StateRepository()
@State<AuthStateInterface>({
	name: authFeatureName,
	defaults: {
		accessJwt: null,
		refreshJwt: null,
		isLoading: false,
		mfaRequired: false,
		needChooseDepartment: false,
		needChooseDevice: false,
		CNAAccessJwt: null,
		CNARefreshJwt: null,
		isPccLogging: false,
		isPCCAccountUsagePopupVisible: false,
		pccLoginSettingsTabletErrorShown: null,
		pccLoginWasNotPerformedButClosed: false,
	}
})
@Injectable()
export class AuthState extends NgxsDataRepository<AuthStateInterface> {
	subscriptionTokenDetect$: Subscription
	openedWindow: Window | null = null
	openedWindowInterval: NodeJS.Timeout;
	aiomedPccChannel: BroadcastChannel;
	private pccLoginTemporaryTokens: LoginResponseInterface
	private isSuccessfullyLoggedInToPcc: boolean = false
	private isLoginFromPcc: boolean = false
	private rnPassword$ = new BehaviorSubject<string | null>(null)
	public readonly rnPasswordObservable$ = this.rnPassword$.asObservable()
	private userProfiles: UserProfileDTO[] = []
	private currentEmrId: string | null = null
	private readonly isMobile = this.preferenceState.isMobile;
	private readonly isUserCNAKey = CNA_STORAGE_KEY;
	private readonly workerService = inject(WorkerService);
	private readonly destroyRef = inject(DestroyRef);

	constructor(
		public loginApiService: LoginService,
		private mfaLoginService: MFALoginService,
		private selfUserAPIService: SelfUserService,
		private preferenceState: PreferenceState,
		private router: Router,
		private zone: NgZone,
		private backendService: BackendService,
		private message: NzMessageService,
		private deviceService: DeviceDetectorService,
		private notificationService: NzNotificationService,
		private readonly networkService: NetworkService,
	) {
		super()
		this.pccBroadcastChannelListener()
		this.listenToWebWorker();
		this.listenToPageReloadEventToClosePccOpenedWindow();
	}

	get isLoginFromPCC() {
		return this.isLoginFromPcc;
	}

	@Selector()
	static isLoading(state: AuthStateInterface): boolean {
		return state.isLoading
	}

	@Selector()
	static isAuthenticated(state: AuthStateInterface): boolean {
		return state.accessJwt != null && !state.mfaRequired
	}

	@Selector()
	static isMfaRequired(state: AuthStateInterface): boolean {
		return state.mfaRequired
	}

	@Selector()
	static needChooseDepartment(state: AuthStateInterface): boolean {
		return state.needChooseDepartment
	}

	@Selector()
	static needChooseDevice(state: AuthStateInterface): boolean {
		return state.needChooseDevice
	}

	@Selector()
	static hasCNAAccessToken(state: AuthStateInterface): boolean {
		return !!state.CNAAccessJwt
	}

	@Selector()
	static isPccLogging(state: AuthStateInterface): boolean {
		return state.isPccLogging
	}

	@Selector()
	static isPCCAccountUsagePopupVisible(state: AuthStateInterface): boolean {
		return state.isPCCAccountUsagePopupVisible
	}

	@Selector()
	static pccLoginSettingsTabletErrorShown(
		state: AuthStateInterface
	): string | null {
		return state.pccLoginSettingsTabletErrorShown
	}

	@Selector()
	static pccLoginWasNotPerformedButClosed(state: AuthStateInterface): boolean {
		return state.pccLoginWasNotPerformedButClosed
	}

	public isAuthenticated(): boolean {
		return this.snapshot.accessJwt != null
	}

	public needChooseDepartment(): boolean {
		return this.snapshot.needChooseDepartment
	}

	public isMfaRequired(): boolean {
		return this.snapshot.mfaRequired
	}

	public accessToken(): string | undefined {
		return this.snapshot.accessJwt?.token
	}

	public refreshToken(): string | undefined {
		return this.snapshot.refreshJwt?.token
	}

	@DataAction()
	public logout(): void {
		this.zone.run(() => {
			this.router.navigateByUrl('/login')
			this.ctx.patchState({ needChooseDepartment: false })
			this.preferenceState.setNeedChooseDepartment(true)
			setTimeout(() => {
				this.preferenceState.setAbsenceOfMeasurementsHintOpened(true)
			}, 2000)
		})
		this.ctx.patchState({ needChooseDepartment: false })
		this.preferenceState.setNeedChooseDepartment(true)
		this.backendService.logout()
		this.backendService.triggerLogout()
		this.reset()
		this.isLoginFromPcc = false;
		this.isSuccessfullyLoggedInToPcc = false;
		this.currentEmrId = null;
		LogRocket.startNewSession();
		this.closeOpenedWindow();
		if (this.subscriptionTokenDetect$) {
			this.subscriptionTokenDetect$.unsubscribe()
		}
	}

	// @DataAction()
	public refreshAccessToken(
		dispatchRefreshToken?: boolean,
		manualRefreshTokenRefresh: boolean = false
	): Observable<string> {
		const refreshToken =
			dispatchRefreshToken && this.snapshot.CNARefreshJwt?.token
				? this.snapshot.CNARefreshJwt?.token
				: this.snapshot.refreshJwt?.token

		if (refreshToken)
			return this.loginApiService
				.refreshToken({
					refreshToken: refreshToken
				})
				.pipe(
					takeUntil(this.backendService.destroy$),
					map((res: LoginResponseInterface) => {
						if (manualRefreshTokenRefresh) {
							this.handleLoginResponse({
								...res,
								CNAAccessJwt: this.snapshot.CNAAccessJwt,
								CNARefreshJwt: this.snapshot.CNARefreshJwt
							})
						} else {
							this.handleLoginResponse(res)
						}
						if (dispatchRefreshToken) {
							this.dispatch({ type: 'REFRESH TOKEN' })
						}
						return res.accessJwt?.token!
					}),
					retry({ count: 10, delay: 2000 }),
					catchError((err) => {
						throw err
					})
				)
		else {
			return throwError(
				() => new HttpErrorResponse({ statusText: 'Missing refresh token' })
			)
		}
	}

	public override ngxsAfterBootstrap() {
		this.checkOnline();
			
		if (
			!this.ctx.getState().accessJwt ||
			this.preferenceState.isMfaRequired() ||
			this.preferenceState.needChooseDepartment()
		) {
			return
		}

		if (this.isAccessTokenValid()) {
			this.dispatch({ type: 'LOGGED IN' })
		} else {
			const isUserCNAStorageValue = localStorage.getItem(this.isUserCNAKey);
			const isUserCNA = (isUserCNAStorageValue && JSON.parse(isUserCNAStorageValue) === true);
			if (!this.isMobile) {
				this.logout();
				this.preferenceState.setSessionExpire(true)
			} else if (this.isMobile && this.getState().CNAAccessJwt) {
				this.rnLogout();
			} else if (this.isMobile && isUserCNA) {
				this.refreshAccessToken(true).pipe(take(1)).subscribe();
			} else if (this.isMobile && !this.getState().CNAAccessJwt && !isUserCNA) {
				this.refreshAccessToken(false).pipe(
					switchMap(() => this.backendService.getUserSelf()),
					tap(user => {
					if (user._degree === 'MEDICAL_ASSISTANT' || user._degree === 'NO_DEGREE') {
						this.refreshAccessToken(true).pipe(take(1)).subscribe();
					} else {
						this.logout();
						this.preferenceState.setSessionExpire(true);
					}
				})).subscribe();
			}
		}
		if (this.subscriptionTokenDetect$) {
			this.subscriptionTokenDetect$.unsubscribe()
		}
		this.subscriptionTokenDetect$ = interval(9000).subscribe(() =>
			this.checkTokenStatus()
		);

		this.networkService.isOnlineObs$.pipe(skip(1)).subscribe((isOnline) => {
			if (isOnline && this.isAuthenticated()) {
				this.dispatch({ type: 'REFRESH TOKEN' });
			}
		});
		
	}

	@DataAction()
	public mfaLogin(
		@Payload('code') code: MfaLoginRequestV2
	): Observable<LoginResponseInterface | null> {
		return this.mfaLoginService.mfaLogin(code).pipe(
			tap((res: LoginResponseInterface) => {
				this.handleLoginResponse(res)
				if (this.isMobile) {
					this.canLogIntoSystem()
					return
				}
				this.ctx.patchState({ needChooseDepartment: true })
			}),
			catchError((err) => {
				throw err
			})
		)
	}

	@DataAction()
	public forgotPassword(
		@Payload('data') data: ForgotPasswordRequestV2
	): Observable<void> {
		return this.selfUserAPIService
			.forgotPassword(
				`
         ${window.location.origin}/reset
				`,
				data
			)
			.pipe(
				catchError((err) => {
					throw err
				}),
				ignoreElements()
			)
	}

	@DataAction()
	public setNewPassword(
		@Payload('token') token: string,
		@Payload('operation') operation: string,
		@Payload('entityId') entityId: string,
		@Payload('password') password: string,
		@Payload('username') username?: string
	): Observable<void> {
		return this.backendService
			.resetPassword(token, operation, entityId, password, username)
			.pipe(
				tap(() => {
					this.message.success('Your password has been changed!')
					this.zone.run(() => {
						this.router.navigateByUrl('/login')
					})
				}),
				catchError((err) => {
					throw err
				}),
				ignoreElements()
			)
	}

	@DataAction()
	public mfaResend(): Observable<LoginResponseInterface | null> {
		return this.mfaLoginService.mfaResend().pipe(
			catchError(() => {
				this.reset()
				return of(null)
			})
		)
	}

	@DataAction()
	public canLogIntoSystem(
		isUserCNA?: boolean,
		department: DepartmentDTO | null = null
	): void {
		
		this.backendService.getUserSelf().pipe(
			take(1),
			catchError((err) => {
				console.error(err);
				return EMPTY;
			})
		).subscribe(res => {
			if (this.isLoginFromPcc && res._degree !== 'MEDICAL_DOCTOR' && res._degree !== 'REGISTERED_NURSE') {
				this.preferenceState.setPccLoginWasPerformedWithCNAUser(true);
				LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
					[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.PccLoginWithCNAuser
				});
				return;
			}
			if (res._degree === 'MEDICAL_DOCTOR' || res._degree === 'REGISTERED_NURSE') {
				this.openPccLoggingWindowAfterInitialLogin(this.getState().accessJwt!.token!);
			}

			this.preferenceState.setNeedChooseDepartment(false)
			this.zone.run(() => {
				this.router.navigateByUrl(
					isUserCNA ? '/reports' : '/'
				)
			})

			LogRocket.startNewSession()
			this.preferenceState.setNeedChooseDepartment(false)
			this.dispatch({ type: 'LOGGED IN' })
			if (this.subscriptionTokenDetect$) {
				this.subscriptionTokenDetect$.unsubscribe()
			}
			this.subscriptionTokenDetect$ = interval(9000)
				.pipe(takeUntil(this.backendService.destroy$))
				.subscribe(() => this.checkTokenStatus())
		});
	}

	@DataAction()
	public resetMfaRequired(
		@Payload('mfaRequired') mfaRequired: boolean
	): Observable<void> {
		this.ctx.patchState({
			mfaRequired,
			accessJwt: null,
			refreshJwt: null,
			isLoading: false
		})
		this.preferenceState.setMfaRequired(false)
		return of()
	}

	@DataAction()
	checkTokenStatus(): any {
		const helper = new JwtHelperService()
		const expirationDate = helper.getTokenExpirationDate(
			// @ts-ignore
			this.getState().accessJwt?.token
		)
		const accessJwt = this.ctx.getState().accessJwt
		const mfaRequired = this.ctx.getState().mfaRequired
		const needChooseDepartment = this.ctx.getState().needChooseDepartment
		const expirationMoment = moment(expirationDate)
		const currentMoment = moment(new Date())
		const differenceInMinutes = expirationMoment.diff(currentMoment, 'minutes')
		if (
			accessJwt != null &&
			!mfaRequired &&
			!needChooseDepartment &&
			differenceInMinutes <= 0
		) {
			this.refreshAccessToken(true).pipe(take(1)).subscribe()
		} else if (
			accessJwt != null &&
			!mfaRequired &&
			!needChooseDepartment &&
			differenceInMinutes <= 5
		) {
			this.refreshAccessToken(false, true).pipe(take(1)).subscribe()
			this.refreshCNAAccessToken().subscribe()
		}
	}

	@DataAction()
	public login(
		@Payload('req') req: LoginRequestInterface
	): Observable<LoginResponseInterface | null> {
		this.ctx.patchState({
			isLoading: true
		})
		this.isLoginFromPcc = false;
		return this.loginApiService.login(req).pipe(
			tap((res: LoginResponseInterface) => {
				this.handleLoginResponse(res)
				if (!res.mfaRequired) {
					if (this.isMobile) {
						this.backendService
							.getUserSelf()
							.pipe(take(1), catchError((err) => {
								this.patchState({ needChooseDepartment: false });
								this.patchState({ isLoading: false });
								return throwError(() => err);
							}))
							.subscribe((res) => {
								if ((req.tab === 'rn' && (res._degree === 'REGISTERED_NURSE' || res._degree === 'MEDICAL_DOCTOR')) || (req.tab === 'cna') && (res._degree === 'MEDICAL_ASSISTANT' || res._degree === 'NO_DEGREE')) {
									this.ctx.patchState({ needChooseDevice: false })
									this.canLogIntoSystem()
								} else {
									this.ctx.patchState({ needChooseDevice: false });
									this.message.warning(`Please login with ${req.tab?.toUpperCase()} permissions.`);
									this.patchState({ accessJwt: null, refreshJwt: null });
								}
								this.patchState({ isLoading: false });
							})
						return
					}
					this.ctx.patchState({ needChooseDepartment: true })
				}
			}),
			catchError(() => {
				this.reset()
				this.patchState({ isLoading: false })
				return of(null)
			})
		)
	}

	@DataAction()
	public rnLogin(
		@Payload('req') req: LoginRequestInterface
	): Observable<LoginResponseInterface | null | 'VALID'> {
		this.ctx.patchState({ isLoading: true })
		this.rnPassword$.next(req.password);
		this.currentEmrId = null;
		const currentAccessToken = this.getState().accessJwt
		return this.loginApiService.login(req).pipe(
			tap((res) => {
				this.patchState({ accessJwt: res.accessJwt! })
			}),
			switchMap((res: LoginResponseInterface) => {
				return this.backendService.getUserSelf().pipe(
					map((user) => {
						return user._degree === 'MEDICAL_DOCTOR' ||
							user._degree === 'REGISTERED_NURSE'
							? res
							: null
					})
				)
			}),
			switchMap((res) => {
				if (res === null) {
					return of(null)
				}

				return this.backendService.getUserProfile(res.userId as string).pipe(
					switchMap((userProfiles) => {
						const matchedUser = userProfiles.length ? userProfiles[0] : null
						if (matchedUser && matchedUser) {
							this.currentEmrId = matchedUser.emr_id
						}
						return this.backendService.getPccIsAuthStatus().pipe(
							take(1),
							tap(() => {
								this.patchState({ accessJwt: currentAccessToken })
							}),
							map((token) => {
								const currentCNAState = this.getState()
								this.pccLoginTemporaryTokens = {
									...res,
									CNAAccessJwt: currentCNAState.accessJwt,
									CNARefreshJwt: currentCNAState.refreshJwt
								}

								if (token === 'INVALID') {
									return res
								}
								return 'VALID'
							})
						)
					})
				)
			}),
			switchMap((res: LoginResponseInterface | null | 'VALID') => {
				if (res === 'VALID') {
					this.setIsPccLogging(false)
					this.handlePccLoginWindowClosing()
					this.isSuccessfullyLoggedInToPcc = true
					return EMPTY
				}
				if (!res) {
					this.notificationService.create(
						'error',
						'Authorization Error',
						'RN Permissions Required.'
					)
					this.ctx.patchState({ isLoading: false })
					return EMPTY
				}

				this.workerService.startWindowOpenedAutoClosingSession();
				this.openedWindow = window.open(
					`${environment.authApiUrl}/pcc/login?return_url=${window.location.origin}/reports?loading=true&token=${res.accessJwt?.token}`
				)

				this.isSuccessfullyLoggedInToPcc = false
				clearInterval(this.openedWindowInterval);
				this.openedWindowInterval = setInterval(() => {
					if (this.openedWindow && this.openedWindow.closed) {
						clearInterval(this.openedWindowInterval)
						this.patchState({ isLoading: false })
						if (!this.isSuccessfullyLoggedInToPcc) {
							this.pccLoginWasNotPerformedButClosed(true);
							this.setIsPccLogging(false);
							LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
								[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.UserManuallyClosedWindow
							});
						}
					}
				}, PCC_WINDOW_INTERVAL)
				return EMPTY
			}),
			catchError((er) => {
				this.patchState({ isLoading: false })
				console.warn(er)
				return of(null)
			})
		)
	}

	@DataAction()
	public loginWithPcc(@Payload('type') type?: TLoginToPCC): void {
		this.isSuccessfullyLoggedInToPcc = false;
		this.isLoginFromPcc = false;
		
		if (this.openedWindow && !this.openedWindow.closed) {
			this.openedWindow.close();
		}

		if (type && type === 'desktop') {
			return window.location.replace(
				`${environment.authApiUrl}/pcc/login?return_url=${window.location.origin}/login?loading=true`
			)
		}
		if (type && type === 'tablet') {
			this.workerService.startWindowOpenedAutoClosingSession();
			this.openedWindow = window.open(
				`${environment.authApiUrl}/pcc/login?return_url=${window.location.origin}/reports?loading=true`
			)
		}
		if (type && type === 'login-tablet') {
			this.isLoginFromPcc = true;
			this.setIsPccLogging(true);
			this.workerService.startWindowOpenedAutoClosingSession();
			this.openedWindow = window.open(
				`${environment.authApiUrl}/pcc/login?return_url=${window.location.origin}/login?loading=true`
			)
		}

		clearInterval(this.openedWindowInterval);
		this.openedWindowInterval = setInterval(() => {
			if (this.openedWindow && this.openedWindow.closed) {
				clearInterval(this.openedWindowInterval);
				if (!this.isSuccessfullyLoggedInToPcc) {
					this.pccLoginWasNotPerformedButClosed(true);
					LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
						[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.UserManuallyClosedWindow
					});
				}
			}
		}, PCC_WINDOW_INTERVAL);
	}

	@DataAction()
	public allowPccAccountUsage(): void {
		const rnPassword = this.rnPassword$.getValue();
		if (rnPassword) {
			this.backendService
				.enableFederatedLogin(rnPassword)
				.pipe(
					take(1),
					catchError(() => {
						console.warn('password was not provided')
						return EMPTY
					})
				)
				.subscribe()
		} else {
			console.warn('password was not provided')
		}
		this.rnPassword$.next(null);
	}

	@DataAction()
	public rnLogout(): void {
		const currentRNState = this.getState()
		if (!currentRNState.CNAAccessJwt) {
			return;
		}
		this.handleLoginResponse({
			...currentRNState,
			accessJwt: { ...currentRNState.CNAAccessJwt! } ,
			refreshJwt: { ...currentRNState.CNARefreshJwt! },
			CNAAccessJwt: null,
			CNARefreshJwt: null
		})
		this.dispatch({ type: 'REFRESH TOKEN' })
	}

	@DataAction()
	public setNeedChooseDepartment(@Payload('need') need: boolean) {
		this.ctx.patchState({ needChooseDepartment: need })
	}

	@DataAction()
	public setIsPccLogging(@Payload('isPccLogging') isPccLogging: boolean) {
		this.ctx.patchState({ isPccLogging: isPccLogging })
	}

	@DataAction()
	public setIsLoading(@Payload('isLoading') isLoading: boolean) {
		this.patchState({ isLoading })
	}

	@DataAction()
	public setIfLoggingFromPcc(@Payload('isLoginFromPcc') isLoginFromPcc: boolean) {
		this.isLoginFromPcc = isLoginFromPcc;
	}

	@DataAction()
	public setIsPCCAccountUsagePopupVisible(
		@Payload('isPCCAccountUsagePopupVisible')
		isPCCAccountUsagePopupVisible: boolean
	) {
		this.ctx.patchState({
			isPCCAccountUsagePopupVisible: isPCCAccountUsagePopupVisible
		})
	}

	@DataAction()
	public resetTokens() {
		this.ctx.patchState({ accessJwt: null, refreshJwt: null })
	}

	@DataAction()
	public loginWithPccIntoSystem(@Payload('refreshToken') refreshToken: string) {
		this.loginApiService
			.refreshToken({ refreshToken })
			.pipe(take(1))
			.subscribe((res) => {
				this.handleLoginResponse(res)
				if (this.isMobile) {
					this.loginWithPccIntoSystemTablet()
					this.isLoginFromPcc = true
				} else {
					this.ctx.patchState({ needChooseDepartment: true })
				}
				LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
					[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.SuccessfullLogin
				});
			})
	}

	@DataAction()
	public loginWithPccIntoSystemTablet() {
		return of('tablet')
	}

	@DataAction()
	public pccLoginSettingsTabletErrorShow(
		@Payload('show') show: string | null
	): void {
		this.patchState({ pccLoginSettingsTabletErrorShown: show })
	}

	@DataAction()
	public pccLoginWasNotPerformedButClosed(
		@Payload('show') show: boolean
	): void {
		this.patchState({ pccLoginWasNotPerformedButClosed: show })
	}

	@DataAction()
	public closeOpenedWindow(): void {
		if (this.openedWindow && !this.openedWindow.closed) {
			this.openedWindow.close();
			clearInterval(this.openedWindowInterval);
			LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
				[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.LoginViewWithPccButtonWasClosed
			});
		}
	}

	public refreshCNAAccessToken(): Observable<LoginResponseInterface | null> {
		const CNARefreshJwt = this.snapshot.CNARefreshJwt?.token
		if (!CNARefreshJwt) {
			return of(null)
		}
		return this.loginApiService
			.refreshToken({ refreshToken: CNARefreshJwt })
			.pipe(
				take(1),
				tap((res) => {
					this.patchState({
						CNAAccessJwt: res.accessJwt,
						CNARefreshJwt: res.refreshJwt
					})
				})
			)
	}

	public handleSleepModeReturning(differenceInMinutes: number): void {
		if (differenceInMinutes >= 15) {
			const isUserCNAStorageValue = localStorage.getItem(this.isUserCNAKey)
			const isUserCNA = isUserCNAStorageValue && JSON.parse(isUserCNAStorageValue) === true
			if (this.getState().CNAAccessJwt) {
				this.rnLogout()
			} else if (isUserCNA) {
				this.refreshAccessToken(true)
			} else if (!this.getState().CNAAccessJwt && !isUserCNA) {
				this.refreshAccessToken(false)
				.pipe(
					switchMap(() => this.backendService.getUserSelf()),
					tap((user) => {
						if (
							user._degree === 'MEDICAL_ASSISTANT' ||
							user._degree === 'NO_DEGREE'
						) {
							this.refreshAccessToken(true).pipe(take(1)).subscribe()
						} else {
							this.logout()
							this.preferenceState.setSessionExpire(true)
						}
					})
				)
				.subscribe()
			}
			return
		} else {
			this.refreshAccessToken(false, true).pipe(take(1)).subscribe()
			this.refreshCNAAccessToken().subscribe((token) => {
				this.dispatch({ type: 'REFRESH TOKEN' })
			})
		}
	}

	public setUserProfiles(userProfiles: UserProfileDTO[]): void {
		this.userProfiles = userProfiles
	}

	private pccBroadcastChannelListener() {
		this.aiomedPccChannel = new BroadcastChannel('aiomed_pcc_channel')
		this.aiomedPccChannel.onmessage = (ev: any) => {
			if (ev.data == 'loggedToPCC') {
				const urlParams = new URLSearchParams(
					this.openedWindow?.document?.location?.search
				)
				const url = this.openedWindow?.document?.location?.href;
				const pccReturnedRefreshToken = urlParams.get('refreshToken')
				const pccReturnedError = urlParams.get('error')
				this.isSuccessfullyLoggedInToPcc = true
				if (!this.openedWindow) return
				if (
					!this.preferenceState.getState().isCollapseMenu 
					|| this.getState().isPccLogging 
					|| this.isLoginFromPCC
				) {
					if ((urlParams.size === 0 && url?.includes('reports') || urlParams.size === 1 && url?.includes('reports?loading=true'))) {
						this.setIsPccLogging(false)
						this.handlePccLoginWindowClosing()
						if (this.currentEmrId !== '0') {
							this.setIsPCCAccountUsagePopupVisible(true)
						}
						LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
							[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.SuccessfullLogin
						});
					}
					if (pccReturnedError) {
						this.showPCCAuthorizationError(pccReturnedError);
						this.openedWindow?.close();
						LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
							[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.PccReturnedError,
							description: pccReturnedError
						});
					}
					if (pccReturnedRefreshToken) {
						const currentCNAState = this.getState();
						this.loginApiService
							.refreshToken({ refreshToken: pccReturnedRefreshToken })
							.pipe(
								take(1),
								tap((res) => {
									this.patchState({ accessJwt: res.accessJwt });
								}),
								switchMap((res) => {
									return this.backendService.getUserSelf().pipe(
										map((user) => ({ res, user })),
										catchError(er => {
											console.error(er);
											return EMPTY;
										})
									)
								}),
								catchError((err) => {
									if (this.openedWindow) {
										this.openedWindow.close();
									}
									this.patchState({ accessJwt: currentCNAState.accessJwt !== null ? {...currentCNAState.accessJwt} : null });
									this.preferenceState.setPreferenceIsCollapseMenu(true);
									this.message.error('Something went wrong');
									console.error(err);
									return EMPTY;
								})
							)
							.subscribe(({ res, user }) => {
								this.setIsPccLogging(false);
								if (user._degree !== 'MEDICAL_DOCTOR' && user._degree !== 'REGISTERED_NURSE') {
									if (this.openedWindow) {
										this.openedWindow.close();
									}
									this.preferenceState.setPreferenceIsCollapseMenu(true);
									this.preferenceState.setPccLoginWasPerformedWithCNAUser(true);
									this.patchState({ accessJwt: currentCNAState.accessJwt !== null ? {...currentCNAState.accessJwt} : null });
									LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
										[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.PccLoginWithCNAuser
									});
									return;
								}
								LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
									[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.SuccessfullLogin
								});
								this.pccLoginTemporaryTokens = {
									...res,
									CNAAccessJwt: currentCNAState.accessJwt,
									CNARefreshJwt: currentCNAState.refreshJwt
								}
								this.handlePccLoginWindowClosing(!url?.includes('login'))
								if (url?.includes('login')) {
									this.canLogIntoSystem()
								}
							})
					}
				} else {
					if (url?.includes('reports')) {
						this.openedWindow.close();
					}
					if (url?.includes('login')) {
						console.log('issue with login via pcc')
					}
				}
			}
		}
	}

	private handleLoginResponse(res: LoginResponseInterface): void {
		// @ts-ignore
		this.preferenceState.setMfaRequired(res.mfaRequired)
		this.ctx.patchState({
			mfaRequired: res.mfaRequired,
			accessJwt: res.accessJwt,
			refreshJwt: res.refreshJwt,
			// isLoading: false,
			CNAAccessJwt: res.CNAAccessJwt,
			CNARefreshJwt: res.CNARefreshJwt
		})
	}

	private isAccessTokenValid(): boolean {
		const helper = new JwtHelperService()
		const expirationDate = helper.getTokenExpirationDate(
			// @ts-ignore
			this.getState().accessJwt?.token
		)
		const accessJwt = this.ctx.getState().accessJwt
		const mfaRequired = this.ctx.getState().mfaRequired
		const needChooseDepartment = this.ctx.getState().needChooseDepartment
		const expirationMoment = moment(expirationDate)
		const currentMoment = moment(new Date())
		const differenceInMinutes = expirationMoment.diff(currentMoment, 'minutes')
		if (
			accessJwt != null &&
			!mfaRequired &&
			!needChooseDepartment &&
			differenceInMinutes <= 0
		) {
			return false
		}
		return true
	}

	public handlePccLoginWindowClosing(isRefreshToken: boolean = true): void {
		if (!this.pccLoginTemporaryTokens || !Object.keys(this.pccLoginTemporaryTokens).length) {
			return;
		}
		this.handleLoginResponse({ ...this.pccLoginTemporaryTokens })
		this.pccLoginTemporaryTokens = {}
		this.preferenceState.setPreferenceIsCollapseMenu(true)
		if (isRefreshToken) {
			this.dispatch({ type: 'REFRESH TOKEN' })
		}
		if (this.openedWindow) {
			this.openedWindow.close();
		}
	}

	public openPccLoggingWindowAfterInitialLogin(token: string) {
		if (!this.isMobile || this.isLoginFromPcc) {
			return;
		}

		this.openedWindow = window.open(
			`${environment.authApiUrl}/pcc/login?return_url=${
				window.location.origin
			}/reports?loading=true&token=${token}`
		);

		this.isSuccessfullyLoggedInToPcc = false;
		clearInterval(this.openedWindowInterval);
		this.workerService.startWindowOpenedAutoClosingSession();
		this.openedWindowInterval = setInterval(() => {
			if (this.openedWindow && this.openedWindow.closed) {
				clearInterval(this.openedWindowInterval);
				this.patchState({ isLoading: false });
				if (!this.isSuccessfullyLoggedInToPcc) {
					if (!this.pccLoginTemporaryTokens || !Object.keys(this.pccLoginTemporaryTokens).length) {
						this.pccLoginWasNotPerformedButClosed(true);
						LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
							[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.PccTabWasClosedEitherAfterSuccessfulLoginOrJustClosed
						});
						return;
					}
					this.handlePccLoginWindowClosing();
				}
				if (this.isSuccessfullyLoggedInToPcc) {
					LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
						[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.LoginWithAiomedCredentialsAndPccTokenReceived
					});
				}
			}
		}, PCC_WINDOW_INTERVAL);
	}

	public showPCCAuthorizationError(pccReturnedError: string): void {
		this.pccLoginSettingsTabletErrorShow(pccReturnedError);
	}

	private checkOnline(): void {
		interval(15000)
		.pipe(
			startWith(0),
			exhaustMap(() =>
				this.backendService.getHealthCheck().pipe(
					tap(() => {
						this.networkService.setOnline();
					}),
					timeout(10000),
					catchError((err) => {
						this.networkService.setOffline();
						console.warn('Error getting online status', err);
						return EMPTY;
					})
				)
			)
		)
		.subscribe()
	}

	private listenToPageReloadEventToClosePccOpenedWindow(): void {
		window.addEventListener("beforeunload", (event) => {
			if (this.openedWindow && !this.openedWindow.closed) {
				LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
					[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.MainPageWasReloadedLeadingOpenedWindowToClose
				});
				this.openedWindow?.close();
			}
		});
	}
	
	private listenToWebWorker(): void {
		this.workerService.closeEmit$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			if (this.openedWindow && !this.openedWindow.closed) {
				this.openedWindow?.close();
				clearInterval(this.openedWindowInterval)
				LogRocket.track(PCC_CLOSE_WINDOW_KEY, {
					[PCC_CLOSE_WINDOW_REASON_KEY]: PccCloseWindowReason.WindowWasClosedBecauseOfTimeout
				});
				this.setIsLoading(false);
			}
    })
  }
}
