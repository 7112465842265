import { NgModule } from '@angular/core'
import { NgxsModule } from '@ngxs/store'
import { TaskState } from './task.state'

@NgModule({
	declarations: [],
	providers: [],
	imports: [NgxsModule.forFeature([TaskState])]
})
export class TaskStoreModule {}
