import {
	Component,
	EventEmitter,
	inject,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Select } from '@ngxs/store'
import {
	setContentOverlays,
	setVisualResizes
} from 'projects/aiomed/src/core/helpers/content-overlays'
import { NetworkService } from 'projects/aiomed/src/shared/services/network.service';
import { AuthState } from 'projects/aiomed/src/store/auth/auth.state'
import { Observable } from 'rxjs'

type Tab = 'role' | 'cna' | 'rn'

interface LoginForm {
	username: FormControl<string>
	password: FormControl<string>
}

@Component({
	selector: 'aiomed-login-tablet',
	templateUrl: './login-tablet.component.html',
	styleUrl: './login-tablet.component.scss'
})
export class LoginTabletComponent implements OnInit, OnDestroy {
  private readonly networkService = inject(NetworkService);
	@Output('formSubmitEmitter')
	formSubmit = new EventEmitter()
	@Output('loginWithPcc')
	loginWithPcc = new EventEmitter()
	@Select(AuthState.isLoading)
	isLoading$: Observable<boolean>
	@Input() hasError: boolean = false
	isLoginWithCred: boolean = false
	protected tab: Tab = 'role'
	protected passwordVisible: boolean = false
	public readonly isOnline$ = this.networkService.isOnlineObs$
	private readonly formBuilder = inject(FormBuilder)
	private readonly authState = inject(AuthState);
	public loginForm: FormGroup<LoginForm> = this.formBuilder.nonNullable.group({
		username: [
			'',
			{
				validators: Validators.compose([Validators.required]),
				nonNullable: true
			}
		],
		password: [
			'',
			{
				validators: Validators.compose([
					Validators.required,
					Validators.minLength(8)
				]),
				nonNullable: true
			}
		]
	})

	ngOnInit(): void {
		setVisualResizes()
	}

	ngOnDestroy(): void {
		setContentOverlays()
	}

	public setTab(tab: Tab): void {
		this.tab = tab
		this.loginForm.reset()
		this.loginForm.updateValueAndValidity()
		this.isLoginWithCred = false
		this.closeOpenedWindow()
	}

	public closeOpenedWindow(): void {
		this.authState.closeOpenedWindow();
	}
}
