<section class='container auth-login__container' [class.tablet]="isMobile && !(needChooseDevice$| async)">
  @if (isLoginWithCred) {
    <button class='role-back' type='button' (click)="isLoginWithCred = false"><span nz-icon nzType='arrow-left'
      nzTheme='outline'></span>Back</button>
  }
  <div [attr.pcc-login-shown]='!isMobile' [class.auth-login__wrapper]="!isMobile || (needChooseDevice$| async)" [class.has-errors]="(errorMessage$ | async)">
    <div [class.auth-login__content-box]="!isMobile || (needChooseDevice$| async)">
      @if(!isMobile || (needChooseDevice$| async)) {
        <div class='auth-login__title' [class.larger-margin]="isLoginWithCred"><span (click)='handlerArrowClick()'
                                             *ngIf='(isMfaRequired$ | async) || (needChooseDepartment$| async)'
                                             class='auth-login__icon' nz-icon
                                             nzTheme='outline' nzType='arrow-left'></span> LOGIN
        </div>
      }
      @if (isMobile) {
        @if (!(needChooseDevice$| async)) {         
          <aiomed-login-tablet [hasError]="!!(errorMessage$ | async)" (formSubmitEmitter)='handlerFormSubmitEmitter($event)'
          (loginWithPcc)='handlerLoginWithPcc()' [isLoading]="isAuthLoading$ | async" />
        }
      } @else {
            @if (!(needChooseDepartment$| async) && !(needChooseDevice$ | async)) {
              
              <aiomed-login-tablet-nurse *ngIf='!(isMfaRequired$ | async)' (formSubmitEmitter)='handlerFormSubmitEmitter($event)' (loginWithPcc)='handlerLoginWithPcc()'
                                 [hasError]='!!(errorMessage$ | async)' tab='rn' [isLoginWithCred]="isLoginWithCred" [showForgotPassword]="false"
                                 [isLoading]='!!(isAuthLoading$ | async)' (loginWithCredEmitter)="isLoginWithCred = true"></aiomed-login-tablet-nurse>
            }
      } 
      <aiomed-login-department (cnaLoginRestrict)='setNeedChooseDepartment()'
                               (formDepartmentSubmitEmitter)='handlerFormDepartmentSubmitEmitter($event)'
                               *ngIf='(needChooseDepartment$| async)'></aiomed-login-department>

      @if (needChooseDevice$ | async) {
        <div class='choose-device'>
          @if (deviceLoading$ | async) {
            <div class='choose-device__spinner'><span nz-icon [nzType]="'loading'"></span></div>
          } @else {
            @if (sharedDevices$ | async; as devices) {
              <nz-select [(ngModel)]='selectedDeviceId' class='small'
                         nzDropdownClassName='custom-login-department-select' nzPlaceHolder='Choose your machine'>
                @for (device of devices; track device.id) {
                  <nz-option [nzValue]='device.id' [nzLabel]='device.id'></nz-option>
                }
              </nz-select>
            }
            <button class='login-form__button' nz-button nzType='primary' [disabled]='!selectedDeviceId.length'
                    (click)='setCurrentDeviceIdAndLogin()'>Connect
            </button>
          }
        </div>
      }
    </div>
    @if (!isMobile) {
      <ul class='privet-policy-support'>
        <li><a href='https://www.aiomed.com/privacy-policy' target='_blank'>Privacy Policy</a></li>
        @if (isLoginWithCred) {
          <li><a [routerLink]="['/reset']" href='javascript:void(0);'>Forgot your password?</a></li>
        }
        <li><a href='https://aiomed.atlassian.net/rest/collectors/1.0/template/form/9374ba31' target='_blank'>Support</a>
        </li>
      </ul>
    }
  </div>
</section>
