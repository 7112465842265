<div class="information-wrapper">
	<ng-container *ngTemplateOutlet="iconTemplate || defaultOfflineModeIconTemplate" />

	<h4 class="information-title">{{ title }}</h4>
	<p class="information-description">{{ description }}</p>
</div>

<ng-template #defaultOfflineModeIconTemplate>
	<span class="information-icon">
		<svg
			width="44"
			height="44"
			viewBox="0 0 44 44"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.25514 4.20496C6.55693 3.48897 5.42499 3.48897 4.72678 4.20496C4.02857 4.92095 4.02857 6.08171 4.72678 6.7977L36.9074 39.7977C37.6056 40.5135 38.7375 40.5135 39.4357 39.7977C40.1338 39.0817 40.1338 37.9209 39.4357 37.205L29.9353 27.4626C30.348 26.7581 30.2584 25.8304 29.666 25.2231C28.3259 23.8488 26.7196 22.9141 25.017 22.4191L21.0691 18.3711C25.0588 18.0828 29.144 19.5026 32.1943 22.6303C32.8924 23.3461 34.0245 23.3461 34.7227 22.6303C35.4208 21.9142 35.4208 20.7535 34.7227 20.0374C30.1713 15.3701 23.7763 13.7454 17.9416 15.1633L15.15 12.3006C22.4939 9.45977 30.9796 11.2766 36.8636 17.7511C37.5367 18.4917 38.6681 18.5327 39.3903 17.8423C40.1125 17.152 40.1525 15.9919 39.4793 15.2512C32.1754 7.21457 21.3845 5.29691 12.4161 9.49707L7.25514 4.20496Z"
				fill="white"
			/>
			<path
				d="M4.68306 15.2496C4.00984 15.9904 4.04978 17.1504 4.77203 17.8408C5.49441 18.5311 6.62563 18.4902 7.29885 17.7495C8.04415 16.9294 8.83118 16.1842 9.65232 15.5136L7.10973 12.9062C6.2661 13.6191 5.45518 14.4001 4.68306 15.2496Z"
				fill="white"
			/>
			<path
				d="M11.962 17.8828C11.0741 18.5093 10.2291 19.2272 9.43967 20.0368C8.74146 20.753 8.74146 21.9136 9.43967 22.6297C10.1377 23.3456 11.2698 23.3456 11.968 22.6297C12.7633 21.814 13.6289 21.1146 14.5448 20.5314L11.962 17.8828Z"
				fill="white"
			/>
			<path
				d="M17.166 23.2188C16.2042 23.7272 15.3019 24.3947 14.4958 25.2214C13.7976 25.9372 13.7976 27.0981 14.4958 27.814C15.1939 28.53 16.326 28.53 17.024 27.814C17.8569 26.9599 18.8442 26.3606 19.8936 26.0159L17.166 23.2188Z"
				fill="white"
			/>
			<path
				d="M22.081 36.6654C20.1062 36.6654 18.5054 35.0238 18.5054 32.9987C18.5054 30.9736 20.1062 29.332 22.081 29.332C24.0558 29.332 25.6566 30.9736 25.6566 32.9987C25.6566 35.0238 24.0558 36.6654 22.081 36.6654Z"
				fill="white"
			/>
		</svg>
	</span>
</ng-template>
