<section class='login-form hide-view'>
  <div class='login-form__department-select'>

    <form nz-form nzLayout='vertical'>
      <nz-form-item class='login-form__item'>
        <nz-form-label class='login-form__label'>Choose department</nz-form-label>
        <nz-form-control class='login-form__control'>
          <nz-select [(ngModel)]='selectedValue' [ngModelOptions]='{standalone: true}'
                     [nzDropdownClassName]='"custom-login-department-select"'
                     nzPlaceHolder='' [nzDisabled]="true">
            <nz-option *ngFor='let department of departments$ | async' [nzLabel]='department.name'
                       [nzValue]='department.id'></nz-option>
          </nz-select>
          <span *ngIf='!selectedValue' class='phone-error'>Please Choose department</span>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <ng-container *ngIf='departments$ | async as departments'>
    <div class='login-form__checkbox'><label [(ngModel)]='checked' nz-checkbox>I am shift manager</label></div>
    <button (click)='submitForm(user, departments)' *ngIf='user$ | async as user' [disabled]='isButtonDisabled'
            class='login-form__button' n nz-button
            nzType='primary'>Log in
    </button>
  </ng-container>
</section>
<aiomed-report-loader [loader]='departmentLogin'></aiomed-report-loader>