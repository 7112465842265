import { Directive, ElementRef, Input, OnChanges, Renderer2, inject } from '@angular/core';

@Directive({
  selector: '[libSelect]',
  standalone: true
})
export class LibSelectDirective implements OnChanges {
  private elRef = inject(ElementRef);
  private renderer = inject(Renderer2);

  @Input('libSelect') libSelect: string = 'Label';

  constructor() {
    this.renderer.addClass(this.elRef.nativeElement, 'lib-select');
  }

  ngOnChanges(): void {
    this.renderSpan(this.libSelect);
  }

  private renderSpan(text: string): void {
    const existingSpanElement = this.elRef.nativeElement.querySelector('.lib-select__label');
    if (existingSpanElement) {
      existingSpanElement.textContent = text;
    } else {
      const span = this.renderer.createElement('span') as HTMLSpanElement;
      span.textContent = text;
      span.className = 'lib-select__label';
      this.renderer.appendChild(this.elRef.nativeElement, span);
    }
  }
}
