import { Injectable } from '@angular/core'
import {
	BackendAlertRuleDTO,
	BackendDeviceDTO,
	BackendPatientDTO
} from '../model/backend-device-model'
import {
	AlertRuleDTO,
	toAlertRuleCreate,
	toAlertRuleUpdate
} from '../model/alert-rules.model'
import {
	GenericEntityResponse,
	GenericEntityService,
	SearchResponseGenericEntityResponse,
	UpdateGenericEntityRequest
} from '@biot-client/biot-client-generic-entity'
import {
	BehaviorSubject,
	catchError,
	concat,
	concatAll,
	distinct,
	EMPTY,
	exhaustMap,
	expand,
	filter,
	forkJoin,
	interval,
	last,
	map,
	mergeMap,
	Observable,
	of,
	scan,
	share,
	Subject,
	switchMap,
	takeUntil,
	tap,
	throwError,
	timer
} from 'rxjs'
import {
	BackendDepartmentDTO,
	DepartmentConfigurationDTOInterface,
	DepartmentConfigurationInterface,
	DepartmentDTO
} from '../model/permission.model'
import {
	DeviceDTO,
	DeviceModality,
	DeviceModel,
	DeviceNames,
	DeviceUpdateMqttMessage,
	LocalDeviceData,
	MqttDeviceDTO
} from '../model/device.model'
import {
	DeviceService,
	SessionResponseV2,
	TemporaryCredentialsService,
	UsageSessionService
} from '@biot-client/biot-client-device'
import { FileAPIService } from '@biot-client/biot-client-file'
import { DownloadFileUrlResponseDTO, FileDTO } from '../model/file'
import { PatientDTO, PatientGender } from '../model/patient'
import {
	CaregiverService,
	PatientService
} from '@biot-client/biot-client-organization'
import {
	CreatePatientLog,
	PatientLogDTO,
	UpdatePatientLogInterface
} from '../model/patient-log.model'
import {
	UsageSessionDTO,
	UsageSessionState as UsageSessionStateEnum
} from '../model/usage-session'
import { HttpClient, HttpParams } from '@angular/common/http'
import { GetCurrentUserResponse } from '../../store/user/types/getCurrentUserResponce'
import { UserDTO, UserInterface, UserProfileDTO } from '../model/user.model'
import { AlertBackend, AlertDTO, UpdateAlertInterface } from '../model/alert'
import {
	GetAggregatedMeasurementsResponse,
	HealthCheckService,
	Measurement,
	MeasurementsService,
	MeasurementsV2Service
} from '@biot-client/biot-client-measurement'
import {
	ObservationFields,
	ObservationsLatestMap,
	ObservationsSortedMap,
	ObservationStats,
	PatientObservationDTO
} from '../model/patient-observation'
import { cloneDeep, orderBy } from 'lodash-es'
import {
	subtractMinusHours,
	subtractPlusHours
} from '../../core/helpers/functions'
import moment from 'moment'
import {
	GetRawMeasurementsResponseDTO,
	ManualMeasurementsDTO,
	MeasurementMessageFromDeviceDTO,
	MeasurementsSummaryDTO
} from '../model/measurement'
import { Changeable, ChangeType } from '../model/common'
import { iot, mqtt } from 'aws-iot-device-sdk-v2'
import { OnMessageCallback, QoS } from 'aws-crt/dist/common/mqtt'
import {
	BackendTaskDTO,
	TaskBackend,
	TaskDTO,
	UpdateTaskInterface
} from '../model/task.model'
import {
	PatientExportDTO,
	PccDTO,
	PccFacilityDTO,
	PccPatientDTO,
	PccUpdateEmrMeasurement
} from '../model/pcc.model'
import { environment } from '../../environments/environment'
import {
	ManualVitalsInterface,
	ReportsVitalsInterface
} from '../model/report.model'
import {
	CreateTreatmentPlanDTO,
	TreatmentPlanDTO,
	UpdateTreatmentPlanDTO
} from '../model/treatment-plan'
import { FrequencyUpdatesTimings } from '../model/frequency-updates-timings'
import { extractEntityByName } from '../../core/helpers/extract-entity'
import { EntitiesByNames } from '../../core/enums/entities.enums'
import { InsightDTO, InsightsBackend } from '../model/insight.model'
import { getLatestTimestampFromMeasurements } from '../../core/helpers/get-latest-timestamp-from-measurements'
import { ShiftType } from '../model/shift-planer.model'
import { NetworkService } from './network.service'

@Injectable()
export class BackendService {
	measurementsWSOnMessage$ = new Subject()
	updatePatientVitalsManualWSOnMessage$ = new Subject()
	deviceStatusFieldsWSOnMessage$ = new Subject()
	localDeviceMeasurementsMessage$: Subject<DeviceDTO> = new Subject()
	incorrectTimeSystemObs$ = new BehaviorSubject<boolean>(false)
	public readonly incorrectTimeSystem$ =
		this.incorrectTimeSystemObs$.asObservable()
	destroy$ = new Subject<void>()
	measurementsWSConnection: any
	public readonly intervalUpdates$ = timer(0, 10000).pipe(
		share(),
		takeUntil(this.destroy$)
	)
	private lastPollDevices: string = new Date().toISOString()
	private allDevicesIntervalFrequency$: BehaviorSubject<FrequencyUpdatesTimings> =
		new BehaviorSubject<FrequencyUpdatesTimings>(10)
	private logoutTrigger = new Subject<void>()
	private readonly LOCAL_DEVICE_URI = 'http://localhost:8080/edan'
	private maxLastModifiedTime = new Date().toISOString()
	private patientMaxLastModifiedTime = new Date().toISOString()
	private devicesMaxLastModifiedTime = new Date().toISOString()
	private getAllEntities$ = interval(10000).pipe(
		switchMap(() =>
			this.genericEntityAPIService
				.searchGenericEntities({
					filter: {
						_lastModifiedTime: {
							from: this.maxLastModifiedTime
						}
					}
				})
				.pipe(
					catchError((err) => {
						console.warn(err)
						return EMPTY
					})
				)
		),
		tap((data) => {
			if (data.data.length) {
				const newTime = new Date(data.data[0]._lastModifiedTime)
				newTime.setSeconds(newTime.getSeconds() + 1)
				this.maxLastModifiedTime = newTime.toISOString()
			}
		}),
		share(),
		takeUntil(this.destroy$)
	)
	private getAllPatientsEntity$ = interval(10000).pipe(
		switchMap(() =>
			this.patientAPIService
				.searchPatients({
					filter: {
						_lastModifiedTime: {
							from: this.patientMaxLastModifiedTime
						}
					}
				})
				.pipe(
					catchError((err) => {
						console.warn(err)
						return EMPTY
					})
				)
		),
		tap((data) => {
			if (data.data.length) {
				const newTime = new Date(data.data[0]._lastModifiedTime)
				newTime.setSeconds(newTime.getSeconds() + 1)
				this.patientMaxLastModifiedTime = newTime.toISOString()
			}
		}),
		share(),
		takeUntil(this.destroy$)
	)
	private getAllDevicesEntity$ = this.allDevicesIntervalFrequency$.pipe(
		switchMap((v) =>
			interval(v * 1000).pipe(
				switchMap(() => this.networkService.isOnlineObs$),
				filter((isOnline) => isOnline),
				exhaustMap(() =>
					this.deviceAPIService
						.searchDevices({
							filter: {
								_lastModifiedTime: {
									from: this.devicesMaxLastModifiedTime
								}
							}
						})
						.pipe(
							catchError((err) => {
								console.warn(err)
								return EMPTY
							})
						)
				),
				tap((data) => {
					if (data.data.length) {
						const newTime = new Date(data.data[0]._lastModifiedTime)
						newTime.setSeconds(newTime.getSeconds() + 1)
						this.devicesMaxLastModifiedTime = newTime.toISOString()
					}
				})
			)
		),
		share(),
		takeUntil(this.destroy$)
	)

	constructor(
		private http: HttpClient,
		private genericEntityAPIService: GenericEntityService,
		private deviceAPIService: DeviceService,
		private fileAPIService: FileAPIService,
		private patientAPIService: PatientService,
		private usageSessionAPIService: UsageSessionService,
		private caregiverAPIService: CaregiverService,
		private measurementsAPIService: MeasurementsService,
		private temporaryCredentialsAPIService: TemporaryCredentialsService,
		private measurementsV2APIService: MeasurementsV2Service,
		private readonly networkService: NetworkService,
		private readonly healthCheckService: HealthCheckService
	) {}

	get logoutObservable() {
		return this.logoutTrigger.asObservable()
	}

	static toPatientExportDTO(res: GenericEntityResponse): PatientExportDTO {
		const dynamicProperties: [keyof PatientExportDTO, any][] = [
			['observedPatient', (res as any).observed_patient?.id],
			['bodyTemperature', (res as any).body_temperature],
			['spo2', (res as any).spo2],
			['diastolicPressure', (res as any).diastolic_pressure],
			['heartRate', (res as any).heart_rate],
			['bloodGlucose', (res as any).blood_glucose],
			['respirationRate', (res as any).respiration_rate],
			['systolicPressure', (res as any).systolic_pressure],
			['bloodPressureMethod', (res as any).blood_pressure_method],
			['heartRateMethod', (res as any).heart_rate_method],
			['spo2Method', (res as any).spo2_method],
			['bodyTemperatureMethod', (res as any).body_temperature_method]
		]

		const transformed = {
			...res,
			id: res._id,
			creationTime: res._creationTime,
			lastModifiedTime: res._lastModifiedTime
		}

		dynamicProperties.forEach(([key, value]) => {
			if (value !== undefined) {
				;(transformed as any)[key] = value
			}
		})

		// @ts-ignore
		return transformed
	}

	private static toAlertRules(res: BackendAlertRuleDTO): AlertRuleDTO & {
		creationTime: string
		lastModifiedTime: string
		lastModifiedBy: string
	} {
		return {
			...res,
			id: res._id,
			name: res._name,
			isTemplate: res.isTemplate,
			creationTime: res._creationTime,
			lastModifiedTime: res._lastModifiedTime,
			lastModifiedBy: (res as any).lastModifiedBy,
			heartRate: {
				maxCritical: res.maxCriticalHeartRate,
				minCritical: res.minCriticalHeartRate,
				maxNormal: res.maxNormalHeartRate,
				minNormal: res.minNormalHeartRate,
				criticalHighDuration: res.criticalHighHeartRateDuration,
				criticalLowDuration: res.criticalLowHeartRateDuration
			},
			[ObservationFields.HeartRate]: {
				maxCritical: res.maxCriticalHeartRate,
				minCritical: res.minCriticalHeartRate,
				maxNormal: res.maxNormalHeartRate,
				minNormal: res.minNormalHeartRate,
				criticalHighDuration: res.criticalHighHeartRateDuration,
				criticalLowDuration: res.criticalLowHeartRateDuration
			},
			bodyTemperature: {
				maxCritical: res.maxCriticalBodyTemperature,
				minCritical: res.minCriticalBodyTemperature,
				maxNormal: res.maxNormalBodyTemperature,
				minNormal: res.minNormalBodyTemperature,
				criticalHighDuration: res.criticalHighBodyTemperatureDuration,
				criticalLowDuration: res.criticalLowBodyTemperatureDuration
			},
			[ObservationFields.BodyTemperature]: {
				maxCritical: res.maxCriticalBodyTemperature,
				minCritical: res.minCriticalBodyTemperature,
				maxNormal: res.maxNormalBodyTemperature,
				minNormal: res.minNormalBodyTemperature,
				criticalHighDuration: res.criticalHighBodyTemperatureDuration,
				criticalLowDuration: res.criticalLowBodyTemperatureDuration
			},
			diastolicPressure: {
				maxCritical: res.maxCriticalDiastolicPressure,
				minCritical: res.minCriticalDiastolicPressure,
				maxNormal: res.maxNormalDiastolicPressure,
				minNormal: res.minNormalDiastolicPressure,
				criticalHighDuration: res.criticalHighDiastolicDuration,
				criticalLowDuration: res.criticalLowDiastolicDuration
			},
			respirationRate: {
				maxCritical: res.maxCriticalRespirationRate,
				minCritical: res.minCriticalRespirationRate,
				maxNormal: res.maxNormalRespirationRate,
				minNormal: res.minNormalRespirationRate,
				criticalHighDuration: res.criticalHighRespirationRateDuration,
				criticalLowDuration: res.criticalLowRespirationRateDuration
			},
			[ObservationFields.RespirationRate]: {
				maxCritical: res.maxCriticalRespirationRate,
				minCritical: res.minCriticalRespirationRate,
				maxNormal: res.maxNormalRespirationRate,
				minNormal: res.minNormalRespirationRate,
				criticalHighDuration: res.criticalHighRespirationRateDuration,
				criticalLowDuration: res.criticalLowRespirationRateDuration
			},
			spO2: {
				maxCritical: res.maxCriticalSpO2,
				minCritical: res.minCriticalSpO2,
				maxNormal: res.maxNormalSpO2,
				minNormal: res.minNormalSpO2,
				criticalHighDuration: res.criticalHighSpO2Duration,
				criticalLowDuration: res.criticalLowSpO2Duration
			},
			[ObservationFields.SpO2]: {
				maxCritical: res.maxCriticalSpO2,
				minCritical: res.minCriticalSpO2,
				maxNormal: res.maxNormalSpO2,
				minNormal: res.minNormalSpO2,
				criticalHighDuration: res.criticalHighSpO2Duration,
				criticalLowDuration: res.criticalLowSpO2Duration
			},
			systolicPressure: {
				maxCritical: res.maxCriticalSystolicPressure,
				maxNormal: res.maxNormalSystolicPressure,
				minCritical: res.minCriticalSystolicPressure,
				minNormal: res.minNormalSystolicPressure,
				criticalHighDuration: res.criticalHighSystolicDuration,
				criticalLowDuration: res.criticalLowSystolicDuration
			},
			bloodGlucose: {
				maxCritical: res.maxCriticalBloodGlucose,
				maxNormal: res.maxNormalBloodGlucose,
				minCritical: res.minCriticalBloodGlucose,
				minNormal: res.minNormalBloodGlucose,
				criticalHighDuration: res.criticalHighBloodGlucoseDuration,
				criticalLowDuration: res.criticalLowBloodGlucoseDuration
			},
			exitBed: {
				monitorFrom: res.exitBedRiskMonitorFrom,
				monitorTill: res.exitBedRiskMonitorTill
			}
		}
	}

	private static toTaskDTO(res: BackendTaskDTO): TaskDTO {
		return {
			...res,
			id: res._id,
			assignee: res.assignee_,
			expirationTime: res.expirationTime,
			taskCompletedBy: res.taskCompletedBy,
			taskPatient: res.taskPatient,
			taskStartTime: res.taskStartTime,
			taskStatus: res.taskStatus,
			taskTreatmentAction: res.taskTreatmentAction,
			creationTime: res._creationTime,
			lastModifiedTime: res._lastModifiedTime
		}
	}

	private static toDepartmentDTO(res: BackendDepartmentDTO): DepartmentDTO & {
		creationTime: string
		lastModifiedTime: string
	} {
		const configuration =
			res.configuration && JSON.parse(res.configuration as unknown as string)
		return {
			...res,
			id: res._id,
			name: res._name,
			configuration:
				configuration &&
				BackendService.toDepartmentConfiguration(
					configuration as DepartmentConfigurationDTOInterface
				),
			creationTime: res._creationTime,
			lastModifiedTime: res._lastModifiedTime,
			isAutomatic: !!res.isAutomatic,
			templateId: undefined as unknown as string,
			departmentEmrId: (res as any).departmentEmrId,
			onDutyAssistantIds:
				res?.onDutyAssistantIds?.split(',').filter((dIdx) => dIdx.length) ||
				null,
			shiftManager: res.shiftManager || null
		}
	}

	private static toDepartmentConfiguration(
		configuration: DepartmentConfigurationDTOInterface
	): DepartmentConfigurationInterface {
		return {
			[ShiftType.DayShift]: configuration.templates.treatmentPlan.find((tp) =>
				tp.tpFrequency.includes(ShiftType.DayShift)
			),
			[ShiftType.EveningShift]: configuration.templates.treatmentPlan.find(
				(tp) => tp.tpFrequency.includes(ShiftType.EveningShift)
			),
			[ShiftType.NightShift]: configuration.templates.treatmentPlan.find((tp) =>
				tp.tpFrequency.includes(ShiftType.NightShift)
			)
		}
	}

	private static toDeviceDTO(res: BackendDeviceDTO): DeviceDTO {
		return {
			...res,
			id: res._id,
			name: res._description || '',
			patient: res._patient,
			status: res._status,
			configuration: Object.values((res as any)._configuration).length
				? BackendService.toDeviceDTOConfiguration((res as any)._configuration)
				: false,
			modality: res.modality as DeviceModality,
			model: res.model as DeviceModel,
			serialNumber: res.serialNumber,
			forceReadEnabled: !!(res._status as any).force_read_enabled,
			isConnected: res._status?._connection?._connected || false,
			batteryLevel: (res._status as any).battery_level || null,
			statusInformation: res._status?._operational?._message || null,
			lastMeasurementTime: (res._status as any).last_measurement_time || null,
			lastStatusUpdate: (res._status as any).last_update_time || null,
			isDeviceV9: (res.model as DeviceModel) === DeviceModel.V9,
			deviceType: (res.model as DeviceModel) === DeviceModel.V9 ? 'V9' : 'other'
		}
	}

	private static toDeviceDTOConfiguration({
		diastole_rest,
		heart_rate_rest,
		systole_rest
	}: {
		diastole_rest: number | null
		heart_rate_rest: number | null
		systole_rest: number | null
	}): boolean {
		return (
			typeof diastole_rest === 'number' &&
			diastole_rest >= 0 &&
			typeof heart_rate_rest === 'number' &&
			heart_rate_rest >= 0 &&
			typeof systole_rest === 'number' &&
			systole_rest >= 0
		)
	}

	private static toFileDTO(file: DownloadFileUrlResponseDTO): FileDTO {
		return {
			...file,
			size: null as unknown as number,
			signedUrl: file.signedUrl!,
			id: file.id!
		}
	}

	private static toPatientDTO(res: BackendPatientDTO): PatientDTO {
		return {
			...res,
			id: res._id,
			alertRules: (res as any).alertRules || null,
			firstName: res._name.firstName,
			lastName: res._name.lastName,
			phone: res._phone,
			templateAlertRuleId: (res as any).templateAlertRuleId || '',
			nationalId: res._nationalId,
			enabled: String(res._enabled) !== 'DISABLED',
			name: res._name.lastName + ' ' + res._name.firstName,
			conditions: (!res.conditions ? [] : res.conditions) as unknown as any[],
			gender: res._gender as PatientGender,
			email: res._email,
			caregiver: res._caregiver,
			creationTime: res._creationTime,
			lastModifiedTime: res._lastModifiedTime,
			symptoms: (!res.symptoms ? [] : res.symptoms) as unknown as any[],
			department: res.department,
			nursingAssistant: res.nursingAssistant || null,
			dateOfBirth:
				res._dateOfBirth && res._dateOfBirth.length
					? new Date(res._dateOfBirth)
					: undefined
		}
	}

	private static toPatientLogDTO(res: PatientLogDTO): PatientLogDTO {
		return {
			...res,
			id: res._id,
			addressee: res.addressee,
			alert: res.alert,
			author: res.author,
			logEntryPatient: res.logEntryPatient,
			logEntryType: res.logEntryType,
			read: res.read,
			text: res.text,
			creationTime: res._creationTime,
			lastModifiedTime: res._lastModifiedTime
		}
	}

	private static toUsageSessionDTO(s: SessionResponseV2): UsageSessionDTO {
		return {
			...s,
			id: s._id,
			device: s._device,
			patient: s._patient,
			state: s._state as UsageSessionStateEnum
		}
	}

	private static toUserDTO(res: GetCurrentUserResponse): UserDTO {
		return {
			...res,
			id: res._id,
			name: res._name,
			email: res._email,
			locale: res._locale,
			gender: res._gender,
			dateOfBirth: res._dateOfBirth,
			address: res._address,
			template: res._template,
			avatar: res.avatar,
			messagingToken: res.messagingToken,
			onDutyDepartment: res.onDutyDepartment,
			_degree: res._degree
		}
	}

	private static toAlertDTO(res: GenericEntityResponse): AlertDTO {
		return {
			...res,
			id: res._id,
			name: res._name,
			alertedDevice: (res as any).alertedDevice,
			patient: (res as any).patient,
			creationTime: new Date(res._creationTime),
			resolution: (res as any).resolution || '',
			resolvedBy: (res as any).resolvedBy || '',
			snoozedUntilTime: (res as any).snoozedUntilTime,
			severity: (res as any).severity,
			status: (res as any).status,
			subject: (res as any).subject?.[0],
			lastModifiedTime: new Date(res._lastModifiedTime)
		}
	}

	private static toInsightDTO(res: GenericEntityResponse): InsightDTO {
		const specifiedTime = new Date((res as any).insightStartTime)
		const currentTime = !(res as any).insightEndTime
			? new Date()
			: new Date((res as any).insightEndTime)
		// const timezoneOffset = currentTime.getTimezoneOffset() / 60
		// specifiedTime.setHours(specifiedTime.getHours() + timezoneOffset)
		// @ts-ignore
		const timeDifferenceMs = currentTime - specifiedTime
		const duration = Math.round(timeDifferenceMs / (1000 * 60 * 60))
		const insightDayDuration =
			moment((res as any).insightStartTime).get('hours') >= 7 &&
			moment((res as any).insightStartTime).get('hours') <= 23
				? 'day'
				: 'night'
		return {
			...res,
			id: res._id,
			name: res._name,
			insightDayDuration,
			baselineValue: (res as any).baselineValue,
			insightDuration: `${duration}h`,
			lastWeekValue: (res as any).lastWeekValue,
			baseline: !(res as any).baselineValue
				? 0
				: Math.floor(Number((res as any).baselineValue) * 10) / 10,
			maxValue: Math.floor(Number((res as any).maxValue) * 10) / 10,
			displayValue: String(
				Math.floor(Number((res as any).displayValue) * 10) / 10
			),
			lastWeekOutlineType: (res as any).lastWeekOutlineType,
			insightStatus: (res as any).insightStatus,
			averageValue: Math.floor(Number((res as any).averageValue) * 10) / 10,
			measurementExceedCount: (res as any).measurementExceedCount,
			measurements: (res as any).measurements
				? JSON.parse((res as any).measurements)
				: [],
			outlineType: (res as any).outlineType,
			creationTime: res._creationTime,
			lastModifiedTime: res._lastModifiedTime,
			insightSubject: (res as any).insightSubject,
			patient: (res as any).subjectPatient,
			acknowledged: (res as any).acknowledged,
			description: (res as any).description,
			insightEndTime: (res as any).insightEndTime,
			insightStartTime: (res as any).insightStartTime
		}
	}

	private static toTreatmentPlanDTO(
		res: GenericEntityResponse
	): TreatmentPlanDTO & { creationTime: string; lastModifiedTime: string } {
		let importantVitals: string[]
		if (typeof (res as any).additionalInformation === 'string') {
			importantVitals = (res as any).additionalInformation
				? (res as any).additionalInformation.split(',')
				: []
		} else {
			importantVitals = cloneDeep((res as any).additionalInformation)
		}
		return {
			...res,
			id: res._id,
			creationTime: res._creationTime,
			lastModifiedTime: res._lastModifiedTime,
			isBusinessRule: !!(res as any).isBusinessRule,
			additionalInformation: importantVitals,
			days: (res as any).days,
			endTime: (res as any).tpEndTime,
			frequency: (res as any).tpFrequency,
			scheduleType: (res as any).tpScheduleType,
			BT: !!importantVitals?.find((e) => e === 'BT'),
			BP: !!importantVitals?.find((e) => e === 'BP'),
			RR: !!importantVitals?.find((e) => e === 'RR'),
			HR: !!importantVitals?.find((e) => e === 'HR'),
			SPO2: !!importantVitals?.find((e) => e === 'SPO2'),
			BG: !!importantVitals?.find((e) => e === 'BG'),
			patientId: (res as any).tpPatient?.id,
			daily: (res as any).tpScheduleType === 'daily',
			weekly: (res as any).tpScheduleType === 'weekly',
			otherDay: (res as any).tpScheduleType === 'every_other_day',
			morningShiftTime:
				(res as any).tpFrequency &&
				!!(res as any).tpFrequency?.find(
					(shift: string) => shift === 'day_shift'
				),
			dayShiftTime:
				(res as any).tpFrequency &&
				!!(res as any).tpFrequency?.find(
					(shift: string) => shift === 'evening_shift'
				),
			nightShiftTime:
				(res as any).tpFrequency &&
				!!(res as any).tpFrequency?.find(
					(shift: string) => shift === 'night_shift'
				)
		}
	}

	private static toPatientObservationDTO(
		id: string,
		s: GetAggregatedMeasurementsResponse
	): PatientObservationDTO {
		let observations: ObservationsSortedMap = {}
		let latestPerVital: Partial<ObservationsLatestMap> = {}
		let tmpMeasurementsArray: {
			timestamp: string
			average: number
			standardDeviation: number
		}[] = []
		const latestMeasurements: [string, Measurement | any][] = s.attributes.map(
			(a) => {
				tmpMeasurementsArray = []
				a.sources.forEach((data) =>
					data.measurementSessions.forEach((m) => {
						m.measurements.forEach((mm) => {
							if (!mm.timestamp || !mm.average) return
							const ts = new Date(mm.timestamp).getTime()
							observations[ts] = {
								...observations[ts],
								[a.attributeName as `${ObservationFields}`]: mm.average,
								sessionId: m.sessionId,
								timestamp: mm.timestamp
							}
							if (!latestPerVital[a.attributeName as `${ObservationFields}`]) {
								latestPerVital[a.attributeName as `${ObservationFields}`] = {
									timestamp: mm.timestamp,
									value: mm.average
								}
							} else {
								if (
									new Date(
										latestPerVital[
											a.attributeName as `${ObservationFields}`
										]!.timestamp
									).getTime() < new Date(mm.timestamp).getTime()
								) {
									latestPerVital[a.attributeName as `${ObservationFields}`] = {
										timestamp: mm.timestamp,
										value: mm.average
									}
								}
							}
						})
						tmpMeasurementsArray.push(m.measurements as any)
					})
				)
				return [
					a.attributeName,
					orderBy(tmpMeasurementsArray.flat(), 'timestamp', 'asc')
				]
			}
		)
		const latestStats: {
			[x: string]: any
			timestamp: Date
		}[] = []
		latestMeasurements.forEach((data: [string, ObservationStats[]]) => {
			const [key, observationStats] = data
			observationStats.forEach((stat: ObservationStats) => {
				const idx = latestStats.findIndex((i) => i.timestamp === stat.timestamp)
				if (idx !== -1) {
					latestStats[idx][data[0]] = stat.average
				} else {
					latestStats.push({
						[key]: stat.average,
						timestamp: stat.timestamp
					})
				}
			})
		})

		const lastMeasurementTime =
			(latestPerVital?.[ObservationFields.SystolicPressure] &&
				latestPerVital?.[ObservationFields.SystolicPressure]?.timestamp) ||
			getLatestTimestampFromMeasurements(latestPerVital)
		return <PatientObservationDTO>{
			id: id,
			latest: orderBy(latestStats, 'timestamp', 'asc'),
			observations,
			latestPerVital,
			lastMeasurementTime
		}
	}

	triggerLogout() {
		this.logoutTrigger.next()
	}

	getAllAlertRules(
		olderThan?: string,
		// @ts-ignore
		isNull: boolean,
		alertRulesIds?: string[]
	) {
		return (
			// @ts-ignore
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						_templateName: {
							// @ts-ignore
							in: [EntitiesByNames.AlertRules]
						},
						_id: {
							in: alertRulesIds
						},
						...(olderThan && {
							_lastModifiedTime: {
								from: olderThan
							}
						}),
						...((isNull && {
							isTemplate: {
								isNull
							}
						}) ||
							(!isNull && {
								isTemplate: {
									isNotNull: true
								}
							}))
						// ...(ids
						//   ? {
						//     _id: {
						//       // @ts-ignore
						//       in: ids
						//     }
						//   }
						//   : null)
					}
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => {
						return res.data.map((ar) => {
							;(ar as Changeable).changeType =
								ar._creationTime === ar._lastModifiedTime
									? 'added'
									: ('modified' as ChangeType)
							return BackendService.toAlertRules(ar as BackendAlertRuleDTO)
						})
					})
				)
		)
	}

	setDisabledPatient(id: string): Observable<PatientDTO> {
		return (
			this.http
				.post(
					`${environment.apiUrl}/organization/v1/users/patients/${id}/enabled-state/DISABLED`,
					{}
				)
				// @ts-ignore
				.pipe(map(BackendService.toPatientDTO))
		)
	}

	setEnablePatient(id: string): Observable<PatientDTO> {
		return (
			this.http
				.post(
					`${environment.apiUrl}/organization/v1/users/patients/${id}/enabled-state/ENABLED`,
					{}
				)
				// @ts-ignore
				.pipe(map(BackendService.toPatientDTO))
		)
	}

	updateAlertRule(id: string, data: AlertRuleDTO): Observable<AlertRuleDTO> {
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.updateGenericEntity(id, toAlertRuleUpdate(data))
				.pipe(
					switchMap((res) =>
						of(
							BackendService.toAlertRules(res as unknown as BackendAlertRuleDTO)
						)
					)
				)
		)
	}

	deleteAlertRule(id: string): Observable<AlertRuleDTO> {
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.deleteGenericEntity(id)
		)
	}

	deleteTreatmentPlan(id: string): Observable<TreatmentPlanDTO> {
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.deleteGenericEntity(id)
		)
	}

	addAlertRule(newAlertRule: AlertRuleDTO): Observable<AlertRuleDTO> {
		return this.genericEntityAPIService
			.createGenericEntityByTemplateName(
				EntitiesByNames.AlertRules,
				// @ts-ignore
				toAlertRuleCreate(newAlertRule)
			)
			.pipe(
				map((res) =>
					BackendService.toAlertRules(res as unknown as BackendAlertRuleDTO)
				)
			)
	}

	getAllDepartments(shiftManagerId?: string): Observable<DepartmentDTO[]> {
		// @ts-ignore
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						_templateName: {
							// @ts-ignore
							in: [EntitiesByNames.Departments]
						},
						...(shiftManagerId
							? {
									'shiftManager.id': {
										// @ts-ignore
										in: [shiftManagerId]
									}
							  }
							: null)
					}
				})
				.pipe(
					// @ts-ignore
					map((res) =>
						res.data
							.map((value) => ({
								...value,
								changeType:
									value._creationTime === value._lastModifiedTime
										? 'added'
										: ('modified' as ChangeType)
							}))
							.map(BackendService.toDepartmentDTO as any)
					),
					takeUntil(this.destroy$)
				)
		)
	}

	getTaskBrowsing(id: string): Observable<TaskBackend> {
		return (
			// @ts-ignore
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						// taskStatus: {
						// 	in: ['active']
						// },
						'taskPatient.id': {
							in: [id]
						},
						_templateName: {
							// @ts-ignore
							in: [EntitiesByNames.TaskBrowsing]
						}
						// expirationTime: {
						// 	to: new Date().toISOString()
						// },
					},
					// freeTextSearch,
					// page,
					sort: [
						{
							prop: '_creationTime',
							order: 'DESC'
						}
					]
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => {
						// @ts-ignore
						return {
							data: res.data.map(BackendService.toTaskDTO as any),
							metadata: { ...res.metadata }
						}
					})
				)
		)
	}

	getPatientTaskBrowsing(
		id: string,
		page: number,
		status?: string
	): Observable<TaskBackend> {
		return (
			// @ts-ignore
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						// taskStatus: {
						// 	in: ['active']
						// },
						'taskPatient.id': {
							in: [id]
						},
						_templateName: {
							// @ts-ignore
							in: [EntitiesByNames.PatientTaskBrowsing]
						},
						...(status
							? {
									taskStatus: {
										in: [status]
									}
							  }
							: null)
					},
					// freeTextSearch,
					// page,
					sort: [
						{
							prop: '_creationTime',
							order: 'DESC'
						}
					],
					limit: 10,
					page
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => {
						// @ts-ignore
						return {
							data: res.data.map(BackendService.toTaskDTO as any),
							metadata: { ...res.metadata }
						}
					})
				)
		)
	}

	getAllTaskRecursively(patientIds?: string[]) {
		let p = 0
		let nextPage = 0
		return this.getAllTask(undefined, p, patientIds).pipe(
			expand((r) => {
				p = nextPage
				if (!r) {
					return of()
				}
				// @ts-ignore
				else if (p <= Math.round(r.metadata.page?.totalResults / 100)) {
					nextPage += 1
					return this.getAllTask(undefined, p, patientIds)
				} else {
					return of(null)
				}
			})
		)
	}

	getAllTask(olderThan?: string, page: number = 0, patientIds?: string[]) {
		return (
			// @ts-ignore
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						taskStatus: {
							in: ['active']
						},
						'taskPatient.id': {
							in: patientIds
						},
						_templateName: {
							in: [EntitiesByNames.AllTask]
						},
						// expirationTime: {
						// 	to: new Date().toISOString()
						// },
						...(olderThan && {
							_lastModifiedTime: {
								from: olderThan
							}
						})
					},
					page,
					sort: [
						{
							prop: '_creationTime',
							order: 'DESC'
						}
					]
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => {
						return {
							data: res.data.map((t) => {
								;(t as Changeable).changeType = 'added'
								return BackendService.toTaskDTO(t as unknown as BackendTaskDTO)
							}),
							metadata: res.metadata
						}
					})
				)
		)
	}

	getAllDevices(patientIds?: string[], olderThan?: string) {
		return this.deviceAPIService
			.searchDevices({
				filter: {
					...(olderThan && {
						_lastModifiedTime: {
							from: olderThan
						}
					}),
					['_patient.id']: {
						//@ts-ignore
						in: patientIds
					}
				}
			})
			.pipe(
				takeUntil(this.destroy$),
				map((res) => {
					return res.data.map((d) => {
						;(d as Changeable).changeType =
							d._creationTime === d._lastModifiedTime
								? 'added'
								: ('modified' as ChangeType)
						return BackendService.toDeviceDTO(d as BackendDeviceDTO)
					})
				})
			)
	}

	getDepartmentSharedDevices(departmentId: string) {
		return this.deviceAPIService
			.searchDevices({
				filter: {
					['room']: {
						isNull: true
					},
					['department.id']: {
						// @ts-ignore
						eq: departmentId
					}
				}
			})
			.pipe(
				takeUntil(this.destroy$),
				map((res) => {
					return res.data.map((d) => {
						;(d as Changeable).changeType =
							d._creationTime === d._lastModifiedTime
								? 'added'
								: ('modified' as ChangeType)
						return BackendService.toDeviceDTO(d as BackendDeviceDTO)
					})
				})
			)
	}

	updateDevice(id: string, data: any) {
		return this.deviceAPIService.updateDevice(id, data).pipe(
			map((res) => {
				return BackendService.toDeviceDTO(res as unknown as BackendDeviceDTO)
			})
		)
	}

	getFile(idsToLoad: string): Observable<FileDTO> {
		return this.fileAPIService.getDownloadFileUrl(idsToLoad).pipe(
			takeUntil(this.destroy$),
			exhaustMap((res: DownloadFileUrlResponseDTO) =>
				of(BackendService.toFileDTO(res))
			)
		)
	}

	createFile(data: { name: string; mimeType: string }) {
		return this.fileAPIService.createUploadFileUrl(data)
	}

	updateFileBody(url: string, data: Blob): Observable<any> {
		return this.http.put(`${url}`, data)
	}

	getAllPatients(olderThan?: string, departmentId?: string, page: number = 0) {
		return this.patientAPIService
			.searchPatients({
				// @ts-ignore
				filter: {
					...(departmentId && {
						'department.id': {
							eq: departmentId
						}
					}),
					room: {
						isNotNull: true
					},
					// _enabled: {
					// 	// @ts-ignore
					// 	eq: 'ENABLED'
					// },
					...(olderThan && {
						_lastModifiedTime: {
							from: olderThan
						}
					})
				},
				page
			})
			.pipe(
				takeUntil(this.destroy$),
				map((res) => {
					return {
						// @ts-ignore
						data: res.data.map(BackendService.toPatientDTO).map((value) => ({
							...value,
							changeType:
								value.creationTime === value.lastModifiedTime
									? 'added'
									: ('modified' as ChangeType)
						})),
						metadata: res.metadata
					}
				})
			)
	}

	getAllArchivedPatients(page: number = 0) {
		return this.patientAPIService
			.searchPatients({
				// @ts-ignore
				filter: {
					'department.id': {
						isNull: true
					}
				},
				page
			})
			.pipe(
				takeUntil(this.destroy$),
				map((res) => {
					return {
						// @ts-ignore
						data: res.data.map(BackendService.toPatientDTO).map((value) => ({
							...value,
							changeType:
								value.creationTime === value.lastModifiedTime
									? 'added'
									: ('modified' as ChangeType)
						})),
						metadata: res.metadata
					}
				})
				// concatAll()
			)
	}

	updatePatient(id: string, data: Partial<PatientDTO>): Observable<PatientDTO> {
		return (
			this.patientAPIService
				// @ts-ignore
				.updatePatient(id, data)
				.pipe(
					map((patient) =>
						BackendService.toPatientDTO(patient as unknown as BackendPatientDTO)
					)
				)
		)
	}

	createPatient(data: Partial<PatientDTO>): Observable<PatientDTO> {
		return (
			this.patientAPIService
				// @ts-ignore
				.createPatient(window.location.origin, data as any)
				.pipe(
					map((patient) =>
						BackendService.toPatientDTO(patient as unknown as BackendPatientDTO)
					)
				)
		)
	}

	setDevicesForceRead(deviceIds: string[]): Observable<
		{
			deviceId: string
			forceReadStatus: string
		}[]
	> {
		return this.http
			.post(`${environment.biobeatIntegrationApiUrl}/device/force-read`, {
				deviceIds
			})
			.pipe(
				// @ts-ignore
				map(
					(res: {
						result: {
							deviceId: string
							forceReadStatus: string
						}[]
					}) => res.result
				),
				catchError((err) => {
					console.warn('Force Read Error', err)
					return EMPTY
				})
			)
	}

	getPccImage(pccPatientId: string) {
		return this.http
			.get(
				`${environment.pccIntegrationApiUrl}/emr/patients/${pccPatientId}/photo`,
				{
					responseType: 'blob'
				}
			)
			.pipe(
				catchError(() => {
					return of()
				})
			)
	}

	getMeasurementSummary(
		patientIds: string[],
		startTime: string,
		endTime: string,
		// mode: ModeType = ModeType.Average,
		mode: string
	) {
		return this.http
			.post(`${environment.measurementsApiUrl}/measurement/summary`, {
				patientIds,
				startTime,
				endTime,
				mode
			})
			.pipe(
				// @ts-ignore
				takeUntil(this.destroy$),
				// @ts-ignore
				map((res: MeasurementsSummaryDTO) => res.measurementSummary),
				catchError((error) => {
					return throwError(() => new Error(error))
				})
			)
	}

	resetPassword(
		token: string,
		operation: string,
		entityId: string,
		password: string,
		username?: string
	): Observable<any> {
		let queryParams = new HttpParams()
		queryParams = queryParams.append('operation', operation)
		queryParams = queryParams.append('entityId', entityId)
		return this.http
			.post(
				`${environment.apiUrl}/ums/v1/tokens/${token}`,
				{
					operationData: {
						password,
						...(username && {
							username
						})
					}
				},
				{ params: queryParams }
			)
			.pipe(
				catchError(() => {
					return of()
				})
			)
	}

	getPccPatients(
		page: number,
		patientId: string,
		patientName: string,
		facilityId: number | null
	): Observable<PccDTO> {
		let queryParams = new HttpParams()
		queryParams = queryParams.append('pageSize', 30)
		queryParams = queryParams.append('page', page)
		if (facilityId !== null) {
			queryParams = queryParams.append('facilityId', facilityId)
		}
		if (patientName && patientName.length) {
			queryParams = queryParams.append('patientName', patientName)
		}
		return this.http
			.get(`${environment.pccIntegrationApiUrl}/emr/patients`, {
				params: queryParams
			})
			.pipe(
				// @ts-ignore
				map(
					(res: {
						data: PccPatientDTO[]
						paging: {
							hasMore: boolean
						}
					}) => {
						return {
							pccPatients: res.data,
							hasMore: res.paging.hasMore
						}
					}
				),
				catchError(() => {
					return of()
				})
			)
	}

	setManualVitals(data: ReportsVitalsInterface): Observable<any> {
		return this.http
			.post(`${environment.tasksApiUrl}/task/create`, {
				...data
			})
			.pipe(
				catchError(() => {
					return of()
				})
			)
	}

	reports(data: {
		facilityName: string
		departmentName: string
		rows: {
			name: string
			room: string
			bp: string
			hr: string
			rr: string
			spo: string
			temp: string
			lastMeasurements: string
		}[]
	}): Observable<any> {
		return this.http
			.post(
				`${environment.reportsApiUrl}/reports/convert/vitals/pdf/`,
				{
					...data
				},
				{ responseType: 'blob' }
			)
			.pipe(
				takeUntil(this.destroy$),
				tap((data) => {
					const url = window.URL.createObjectURL(new Blob([data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'reports.pdf')
					document.body.appendChild(link)
					link.click()
				}),
				catchError(() => {
					return of()
				})
			)
	}

	setCNAManualVitals(
		data: ManualVitalsInterface
	): Observable<{ stringPayload: string }> {
		return this.http
			.post<{ stringPayload: string }>(
				`${environment.measurementsApiUrl}/measurement`,
				{
					...data
				}
			)
			.pipe(
				catchError((err) => {
					return throwError(() => err)
				})
			)
	}

	getPccFacilities(page: number): Observable<PccDTO> {
		let queryParams = new HttpParams()
		queryParams = queryParams.append('pageSize', 30)
		queryParams = queryParams.append('page', page)
		return this.http
			.get(`${environment.pccIntegrationApiUrl}/emr/facilities`, {
				params: queryParams
			})
			.pipe(
				// @ts-ignore
				map(
					(res: {
						data: PccFacilityDTO[]
						paging: {
							hasMore: boolean
						}
					}) => {
						return {
							pccFacilities: res.data,
							hasMoreFacilities: res.paging.hasMore
						}
					}
				),
				catchError(() => {
					return of()
				})
			)
	}

	updatePccEmrMeasurement(data: PccUpdateEmrMeasurement): Observable<{
		exportStatus: string
		patient: PatientDTO
		biotObservationExportEntity: PatientExportDTO
	}> {
		return this.http
			.post(`${environment.pccIntegrationApiUrl}/emr/vitals/export`, data)
			.pipe(
				// @ts-ignore
				mergeMap(
					(data: {
						exportStatus: string
						patient: BackendPatientDTO
						biotObservationExportEntity: GenericEntityResponse
					}) => {
						return of({
							exportStatus: data.exportStatus,
							patient: BackendService.toPatientDTO(
								data.patient as BackendPatientDTO
							),
							biotObservationExportEntity:
								data.biotObservationExportEntity &&
								BackendService.toPatientExportDTO(
									data.biotObservationExportEntity
								)
						})
					}
				),
				catchError((err) => {
					return throwError(() => err)
				})
			)
	}

	updatePccEmrMeasurementBulk(data: {
		observations: PccUpdateEmrMeasurement[]
	}): Observable<any> {
		return this.http
			.post(`${environment.pccIntegrationApiUrl}/emr/vitals/export/bulk`, data)
			.pipe(
				catchError((err) => {
					return throwError(() => err)
				})
			)
	}

	pccLogout(): Observable<any> {
		return this.http.post(`${environment.authApiUrl}/pcc/logout`, {}).pipe(
			catchError(() => {
				return of()
			})
		)
	}

	enableFederatedLogin(oldPassword: string): Observable<any> {
		return this.http
			.post(`${environment.authApiUrl}/pcc/federatedLogin`, { oldPassword })
			.pipe(
				catchError((error) => {
					return of(error)
				})
			)
	}

	getPccIsAuthStatus(): Observable<any> {
		return this.http.get(`${environment.authApiUrl}/pcc/self/verify`).pipe(
			// @ts-ignore
			map((d: { tokenStatus: string }) => d.tokenStatus),
			catchError((err) => {
				console.warn('PointClickCare Verify Error', err)
				return throwError(() => err)
			})
		)
	}

	getAllPatientLogs(olderThan?: string) {
		return this.genericEntityAPIService
			.searchGenericEntities({
				filter: {
					_templateName: {
						// @ts-ignore
						in: [EntitiesByNames.AllPatientLogs]
					},
					...(olderThan && {
						_lastModifiedTime: {
							from: olderThan
						}
					})
				},
				limit: 50
			})
			.pipe(
				takeUntil(this.destroy$),
				map((res) => {
					return res.data
				}),
				concatAll()
			)
	}

	setPatientLogRead(
		id: string,
		entityDiff: UpdatePatientLogInterface
	): Observable<PatientLogDTO> {
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.updateGenericEntity(id, entityDiff)
				.pipe(
					mergeMap((res) => {
						return of(
							BackendService.toPatientLogDTO(res as unknown as PatientLogDTO)
						)
					})
				)
		)
	}

	updateOnDutyAssistantIds(
		id: string,
		entityDiff: Partial<BackendDepartmentDTO>
	): Observable<DepartmentDTO> {
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.updateGenericEntity(id, entityDiff)
				.pipe(
					mergeMap((res) => {
						return of(
							BackendService.toDepartmentDTO(
								res as unknown as BackendDepartmentDTO
							)
						)
					})
				)
		)
	}

	updateShiftManagerDepartment(
		id: string,
		entityDiff: Partial<BackendDepartmentDTO>
	): Observable<DepartmentDTO> {
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.updateGenericEntity(id, entityDiff)
				.pipe(
					mergeMap((res) => {
						return of(
							BackendService.toDepartmentDTO(
								res as unknown as BackendDepartmentDTO
							)
						)
					})
				)
		)
	}

	addPatientLogEntity(patientLog: CreatePatientLog): Observable<PatientLogDTO> {
		return this.genericEntityAPIService
			.createGenericEntityByTemplateName(
				EntitiesByNames.AllPatientLogs,
				patientLog
			)
			.pipe(
				map((res) =>
					BackendService.toPatientLogDTO(res as unknown as PatientLogDTO)
				)
			)
	}

	getDevicesSessions(deviceIds: string[]): Observable<UsageSessionDTO[]> {
		return (
			// @ts-ignore
			this.usageSessionAPIService
				// @ts-ignore
				.searchSessions({
					filter: {
						_state: {
							eq: 'ACTIVE'
						},
						'_device.id': {
							in: deviceIds
						}
					}
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => {
						return res.data.map(BackendService.toUsageSessionDTO)
					})
				)
		)
	}

	getSignsIllnessJson() {
		return forkJoin(
			this.http.get('/assets/resources/symptoms.json'),
			this.http.get('/assets/resources/conditions.json')
		)
	}

	getUserSelf(): Observable<UserDTO> {
		return this.caregiverAPIService.getCaregiverSelf().pipe(
			takeUntil(this.destroy$),
			map((res) => {
				return BackendService.toUserDTO(res)
			})
		)
	}

	getAllCaregivers(): Observable<UserDTO[]> {
		// @ts-ignore
		return this.caregiverAPIService
			.searchCaregivers({
				filter: {
					_degree: {
						// @ts-ignore
						in: ['NO_DEGREE', 'MEDICAL_ASSISTANT']
					}
				}
			})
			.pipe(
				takeUntil(this.destroy$),
				map((res) => {
					return res.data.map((d) => BackendService.toUserDTO(d as any))
				})
			)
	}

	getPatientsExports(
		patientIds: string[],
		creationTime?: string,
		page: number = 0
	) {
		return this.genericEntityAPIService
			.searchGenericEntities({
				filter: {
					...(patientIds && {
						'observed_patient.id': {
							in: [...patientIds]
						}
					}),
					_creationTime: {
						// @ts-ignore
						from: creationTime
					},
					_templateName: {
						// @ts-ignore
						in: [EntitiesByNames.Exports]
					}
				},
				sort: [
					{
						prop: '_creationTime',
						order: 'ASC'
					}
				],
				page
			})
			.pipe(
				takeUntil(this.destroy$),
				map((res) => {
					return {
						data: res.data
							.filter(
								(exp) =>
									(exp as any).observed_patient &&
									(exp as any).observed_patient.id
							)
							.map(BackendService.toPatientExportDTO)
							.map((value) => ({
								...value,
								changeType:
									value.creationTime === value.lastModifiedTime
										? 'added'
										: ('modified' as ChangeType)
							})),
						metadata: res.metadata
					}
				})
			)
	}

	getExports(patientIds?: string[], olderThan?: string, page: number = 0) {
		let creationTime: string
		const date = moment()
		if (date.get('hours') >= 7 && date.get('hours') < 15) {
			creationTime = date.set('hours', 7).set('minutes', 0).toISOString()
		} else if (date.get('hours') >= 15 && date.get('hours') < 23) {
			creationTime = date.set('hours', 15).set('minutes', 0).toISOString()
		} else if (date.get('hours') >= 23) {
			creationTime = date.set('hours', 23).set('minutes', 0).toISOString()
		} else if (date.get('hours') < 7) {
			creationTime = date
				.set('hours', 23)
				.set('minutes', 0)
				.subtract(1, 'days')
				.toISOString()
		}
		return this.genericEntityAPIService
			.searchGenericEntities({
				filter: {
					...(patientIds && {
						'observed_patient.id': {
							in: [...patientIds]
						}
					}),
					_creationTime: {
						// @ts-ignore
						from: creationTime
					},
					_templateName: {
						// @ts-ignore
						in: [EntitiesByNames.Exports]
					},
					...(olderThan && {
						_lastModifiedTime: {
							from: olderThan
						}
					})
				},
				sort: [
					{
						prop: '_creationTime',
						order: 'ASC'
					}
				],
				page
			})
			.pipe(
				takeUntil(this.destroy$),
				map((res) => {
					return {
						data: res.data
							.filter(
								(exp) =>
									(exp as any).observed_patient &&
									(exp as any).observed_patient.id
							)
							.map(BackendService.toPatientExportDTO)
							.map((value) => ({
								...value,
								changeType:
									value.creationTime === value.lastModifiedTime
										? 'added'
										: ('modified' as ChangeType)
							})),
						metadata: res.metadata
					}
				})
			)
	}

	getLastPatientExport(patientId: string) {
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						'observed_patient.id': {
							in: [patientId]
						},
						_templateName: {
							in: [EntitiesByNames.Exports]
						}
					},
					sort: [
						{
							prop: '_creationTime',
							order: 'DESC'
						}
					],
					limit: 1
				})
				.pipe(
					map((res) => {
						return {
							data: res.data
								.filter(
									(exp) =>
										(exp as any).observed_patient &&
										(exp as any).observed_patient.id
								)
								.map(BackendService.toPatientExportDTO)
								.map((value) => ({
									...value,
									changeType:
										value.creationTime === value.lastModifiedTime
											? 'added'
											: ('modified' as ChangeType)
								})),
							metadata: res.metadata
						}
					}),
					map((res) => res.data[0])
				)
		)
	}

	getAllTreatmentPlan(
		patientIds?: string[],
		olderThan?: string,
		page: number = 0
	) {
		return (
			// @ts-ignore
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						_templateName: {
							// @ts-ignore
							in: [EntitiesByNames.AllTreatmentPlan]
						},
						additionalInformation: {
							isNotNull: true
						},
						...(patientIds?.length && {
							'tpPatient.id': {
								in: [...patientIds]
							}
						}),
						['tpPatient.id']: {
							in: patientIds
						}
					},
					page
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => {
						return {
							data: res.data
								.filter((tp) => {
									;(tp as Changeable).changeType =
										tp._creationTime === tp._lastModifiedTime
											? 'added'
											: ('modified' as ChangeType)
									return (tp as any).tpPatient && (tp as any).tpPatient.id
								})
								.map(BackendService.toTreatmentPlanDTO),
							metadata: res.metadata
						}
					})
				)
		)
	}

	getAllTreatmentPlanRecursively(
		patientIds?: string[],
		olderThan?: string,
		page: number = 0
	) {
		let p = page
		let nextPage = page + 1
		return this.getAllTreatmentPlan(patientIds, olderThan).pipe(
			expand((r) => {
				p = nextPage
				if (!r) {
					return of()
				} else if (
					p <=
					// @ts-ignore
					Math.round(r.metadata.page?.totalResults / r.metadata.page?.limit)
				) {
					nextPage += 1
					return this.getAllTreatmentPlan(patientIds, olderThan, p)
				} else {
					return of(null)
				}
			})
		)
	}

	getAllInsights(olderThan?: string, page: number = 0) {
		return (
			// @ts-ignore
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						insightStatus: {
							in: ['ongoing']
						},
						_templateName: {
							// @ts-ignore
							in: [EntitiesByNames.Insights]
						},
						...(olderThan && {
							_lastModifiedTime: {
								from: olderThan
							}
						})
					},
					sort: [
						{
							prop: '_creationTime',
							order: 'DESC'
						}
					],
					page
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => {
						return {
							data: res.data.map(BackendService.toInsightDTO).map((value) => ({
								...value,
								changeType:
									value.creationTime === value.lastModifiedTime
										? 'added'
										: ('modified' as ChangeType)
							})),
							metadata: res.metadata
						}
					})
				)
		)
	}

	getBrowsingInsight(id: string): Observable<InsightDTO[]> {
		return (
			// @ts-ignore
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						_id: {
							in: [id]
						},
						_templateName: {
							// @ts-ignore
							in: [EntitiesByNames.Insights]
						}
					}
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => res.data.map(BackendService.toInsightDTO))
				)
		)
	}

	getBrowsingInsights(
		page: number,
		freeTextSearch: string,
		patientId?: string,
		patientsIds?: string[],
		status?: string,
		startTime?: string
	): Observable<InsightsBackend> {
		if (patientId) {
			patientsIds = undefined
		}
		// @ts-ignore
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						...(patientId && {
							'subjectPatient.id': {
								in: [patientId]
							}
						}),
						...(patientsIds?.length && {
							'subjectPatient.id': {
								in: [...patientsIds]
							}
						}),
						_templateName: {
							// @ts-ignore
							in: [EntitiesByNames.Insights]
						},
						...(status
							? {
									insightStatus: {
										eq: 'ongoing'
									}
							  }
							: null),
						...(startTime
							? {
									_creationTime: {
										from: startTime
									}
							  }
							: null)
					},
					page,
					sort: [
						{
							prop: '_creationTime',
							order: 'DESC'
						}
					],
					limit: 30
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => ({
						data: res.data.map(BackendService.toInsightDTO),
						metadata: { ...res.metadata }
					}))
				)
		)
	}

	getBrowsingCombineInsights(
		page: number,
		patientsIds?: string[]
	): Observable<InsightsBackend> {
		const filter = {
			...(patientsIds?.length && {
				'subjectPatient.id': {
					in: [...patientsIds]
				}
			}),
			_templateName: {
				in: [EntitiesByNames.Insights]
			}
		}
		const sort = [
			{
				prop: '_creationTime',
				order: 'DESC'
			}
		]
		const limit = 30
		// @ts-ignore
		const ongoingInsights$ = this.genericEntityAPIService.searchGenericEntities(
			{
				filter: {
					...filter,
					insightStatus: {
						// @ts-ignore
						eq: 'ongoing'
					}
				},
				page,
				sort,
				limit
			}
		)
		const recentEndedInsights$ =
			// @ts-ignore
			this.genericEntityAPIService.searchGenericEntities({
				filter: {
					...filter,
					insightEndTime: {
						from: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
						to: new Date().toISOString()
					}
				},
				page,
				sort
			})
		return forkJoin([ongoingInsights$, recentEndedInsights$]).pipe(
			map(([ongoingInsightsResponse, recentEndedInsightsResponse]) => {
				const ongoingInsights = (ongoingInsightsResponse as any).data || []
				const recentEndedInsights =
					(recentEndedInsightsResponse as any).data || []
				const metadata = {
					...(ongoingInsightsResponse as any).metadata,
					page: {
						...(ongoingInsightsResponse as any).metadata.page,
						totalResults:
							(ongoingInsightsResponse as any).metadata.page.totalResults +
							(recentEndedInsightsResponse as any).metadata.page.totalResults
					}
				}
				return {
					data: [...ongoingInsights, ...recentEndedInsights].map(
						BackendService.toInsightDTO
					),
					metadata
				}
			})
		)
	}

	getAllAlerts(
		page: number,
		freeTextSearch: string,
		patientId?: string,
		status?: string,
		patientsIds?: string[]
	): Observable<AlertBackend> {
		if (patientId) {
			patientsIds = undefined
		}
		return (
			// @ts-ignore
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						...(patientId && {
							'patient.id': {
								in: [patientId]
							}
						}),
						...(patientsIds?.length && {
							'patient.id': {
								in: [...patientsIds]
							}
						}),
						_templateName: {
							in: [EntitiesByNames.AllAlerts]
						},
						severity: {
							eq: 'critical'
						},
						...(status
							? {
									status: {
										in: [status]
									}
							  }
							: null)
					},
					freeTextSearch,
					page,
					sort: [
						{
							prop: '_creationTime',
							order: 'DESC'
						}
					],
					limit: 20
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => {
						return {
							data: res.data.map(BackendService.toAlertDTO).map((value) => ({
								...value,
								changeType:
									value.creationTime === value.lastModifiedTime
										? 'added'
										: ('modified' as ChangeType)
							})),
							metadata: { ...res.metadata }
						}
					})
				)
		)
	}

	updateAlert(id: string, data: UpdateAlertInterface): Observable<AlertDTO> {
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.updateGenericEntity(id, data)
				.pipe(mergeMap((res) => of(BackendService.toAlertDTO(res))))
		)
	}

	updateTask(id: string, data: UpdateTaskInterface): Observable<TaskDTO> {
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.updateGenericEntity(id, data)
				// @ts-ignore
				.pipe(mergeMap((res) => of(BackendService.toTaskDTO(res))))
		)
	}

	updateTreatmentPlan(
		id: string,
		data: UpdateTreatmentPlanDTO
	): Observable<TreatmentPlanDTO> {
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.updateGenericEntity(id, data)
				.pipe(map((res) => BackendService.toTreatmentPlanDTO(res)))
		)
	}

	getPatientHistoricalObservations(
		patientId: string,
		date: Date,
		hours: number,
		intervalSeconds?: number
	): Observable<PatientObservationDTO> {
		return this.measurementsAPIService
			.getMeasurements({
				attributes: Object.values(ObservationFields),
				patientId,
				// @ts-ignore
				binIntervalSeconds: !intervalSeconds ? 60 : intervalSeconds,
				startTime: subtractMinusHours(hours, date).toISOString(),
				endTime: subtractPlusHours(hours, date).toISOString()
			})
			.pipe(
				takeUntil(this.destroy$),
				switchMap((res) => {
					return of(BackendService.toPatientObservationDTO(patientId, res))
				})
			)
	}

	getPatientReportObservations(
		patientId: string,
		startTime: string,
		endTime: string,
		intervalSeconds?: number
	): Observable<PatientObservationDTO> {
		return this.measurementsAPIService
			.getMeasurements({
				attributes: Object.values(ObservationFields),
				patientId,
				// @ts-ignore
				binIntervalSeconds: !intervalSeconds ? 60 : intervalSeconds,
				startTime,
				endTime
			})
			.pipe(
				takeUntil(this.destroy$),
				switchMap((res) => {
					return of(BackendService.toPatientObservationDTO(patientId, res))
				})
			)
	}

	getPatientInsightObservations(
		patientId: string,
		attributes: string[],
		startTime: string,
		endTime: string
	): Observable<any> {
		return this.measurementsAPIService
			.getMeasurements({
				attributes,
				patientId,
				binIntervalSeconds: 1800,
				startTime,
				endTime
			})
			.pipe(
				map((res) => {
					const combinedArray: any = []
					res.attributes.forEach((attribute) => {
						attribute.sources.forEach((source) => {
							source.measurementSessions.forEach((session) => {
								session.measurements.forEach((measurement) => {
									combinedArray.push({
										[attribute.attributeName]: measurement.average,
										timestamp: measurement.timestamp
									})
								})
							})
						})
					})
					return orderBy(combinedArray, 'timestamp', 'asc')
				})
			)
	}

	getPatientEcgObservations(
		patientId: string,
		date: Date
	): Observable<GetRawMeasurementsResponseDTO> {
		return this.measurementsAPIService
			.getRawMeasurements({
				attributes: ['ecg'],
				patientId,
				startTime: moment(date).subtract(5, 'second').toISOString(),
				endTime: moment(date).toISOString()
			})
			.pipe(
				switchMap((res) => {
					return of(res)
				})
			)
	}

	getManualObservations(id: string, from: string, to: string): Observable<any> {
		// @ts-ignore
		return this.measurementsV2APIService
			.getRawMeasurements2({
				filter: {
					'_patient.id': {
						// @ts-ignore
						eq: id
					},
					timestamp: {
						from,
						to
					}
				}
			})
			.pipe(
				takeUntil(this.destroy$),
				map(({ data }) => {
					let manualObservation: ManualMeasurementsDTO = {}
					orderBy(data, 'timestamp', 'asc')
						.filter((i) => i.sourceEntityId === 'dev-Manual')
						.forEach((m) => {
							manualObservation = { ...manualObservation, ...m }
						})
					return manualObservation
				})
			)
	}

	addNewMeasurement(
		data: MeasurementMessageFromDeviceDTO
	): Observable<MeasurementMessageFromDeviceDTO> {
		return this.measurementsAPIService
			.saveMeasurement(data as any)
			.pipe(map((res) => res)) as any
	}

	getPatientMeasurements(
		id: string,
		hours: number
	): Observable<PatientObservationDTO> {
		return this.measurementsAPIService
			.getMeasurements({
				attributes: Object.values(ObservationFields),
				patientId: id,
				binIntervalSeconds: 60,
				startTime: moment(new Date()).subtract(hours, 'hours').toISOString(),
				endTime: moment(new Date()).toISOString()
			})
			.pipe(
				takeUntil(this.destroy$),
				switchMap((res) => {
					return of(BackendService.toPatientObservationDTO(id, res))
				})
			)
	}

	getUserProfile(userId: string): Observable<UserProfileDTO[]> {
		// @ts-ignore
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						_templateName: {
							// @ts-ignore
							in: [EntitiesByNames.UserProfile]
						},
						['caregiver.id']: {
							eq: userId
						}
					}
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => {
						return res.data
					})
				)
		)
	}

	getAllUsersProfile(): Observable<UserProfileDTO[]> {
		// @ts-ignore
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						_templateName: {
							// @ts-ignore
							in: [EntitiesByNames.UserProfile]
						},
						['caregiver.id']: {
							isNotNull: true
						}
					}
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => {
						return res.data
					})
				)
		)
	}

	subscribeMeasurementsWSConnection() {
		return this.temporaryCredentialsAPIService
			.getTempCredentialsForOrganizationClient()
			.pipe(
				map((cred) => {
					// if (this.measurementsWSConnection) {
					// 	this.measurementsWSConnection.disconnect()
					// 	this.measurementsWSConnection = null
					// }
					const config =
						iot.AwsIotMqttConnectionConfigBuilder.new_with_websockets()
							.with_clean_session(true)
							.with_client_id(`pub_sub_sample(${new Date()})`)
							.with_endpoint(cred.endpoint!)
							.with_credentials(
								'us-east-1',
								cred.credentials?.accessKeyId!,
								cred.credentials?.secretAccessKey!,
								cred.credentials?.sessionToken!
							)
							.with_keep_alive_seconds(30)
							.build()
					const client = new mqtt.MqttClient()
					const connection = client.new_connection(config)
					connection.subscribe(
						(cred as any).topic,
						1,
						this.measurementsWSOnMessageCallback
					)
					this.measurementsWSConnection = connection
					return connection
				})
			)
	}

	addUserProfile(
		user: UserInterface,
		messagingToken: string
	): Observable<string> {
		return this.genericEntityAPIService
			.createGenericEntityByTemplateName(EntitiesByNames.UserProfile, {
				caregiver: {
					id: user?.id,
					name: `${user?.name.firstName} ${user?.name.lastName}`,
					templateId: user?.template?.id
				},
				messagingToken,
				_name: `${user?.name.firstName} ${user?.name.lastName} User Profile`,
				_ownerOrganization: user._ownerOrganization
			} as any) // UserProfileDTO
			.pipe(map((res) => res._id))
	}

	addTreatmentPlan(data: CreateTreatmentPlanDTO): Observable<TreatmentPlanDTO> {
		// @ts-ignore
		return (
			this.genericEntityAPIService
				// @ts-ignore
				.createGenericEntityByTemplateName(
					EntitiesByNames.AllTreatmentPlan,
					data
				)
				.pipe(map((res) => BackendService.toTreatmentPlanDTO(res)))
		)
	}

	setUserProfileMessagingToken(
		userProfileId: string,
		messagingToken: string | null
	): Observable<string> {
		return this.genericEntityAPIService
			.updateGenericEntity(userProfileId, {
				messagingToken
			} as UpdateGenericEntityRequest)
			.pipe(map((res) => res._id))
	}

	deleteUserProfile(userProfileId: string): Observable<void> {
		return this.genericEntityAPIService.deleteGenericEntity(userProfileId)
	}

	findAllPatientsOpenCriticalAlerts(
		patientIds?: string[]
	): Observable<(AlertDTO & Changeable)[]> {
		return concat(
			this.getCriticalAlertsRecursively(
				['open', 'snoozed'],
				patientIds,
				0
			).pipe(map((value) => value?.data ?? (null as unknown as AlertDTO[]))),
			this.getAllEntities$.pipe(
				filterEntityAndEmptyDataAndAddChangeType(
					EntitiesByNames.AllAlerts,
					BackendService.toAlertDTO
				),
				map((value) => {
					if (!(patientIds && patientIds.length)) return value
					return value.filter((alert) => patientIds.includes(alert.patient.id))
				}),
				filter((value) => !!(value && value.length))
			)
		)
	}

	findAllAlertRules(
		alertRulesIds?: string[]
	): Observable<(AlertRuleDTO & Changeable)[]> {
		return concat(
			this.getAllAlertRules(undefined, true, alertRulesIds),
			this.getAllEntities$.pipe(
				filterEntityAndEmptyDataAndAddChangeType(
					EntitiesByNames.AlertRules,
					BackendService.toAlertRules
				),
				map((alertRules) =>
					alertRules.filter((alertRule) => {
						if (!alertRulesIds) return true
						return alertRulesIds.includes(alertRule.id)
					})
				),
				filter((value) => !!(value && value.length))
			)
		)
	}

	findAllDepartments(): Observable<(DepartmentDTO & Changeable)[]> {
		return concat(
			this.getAllDepartments(),
			this.getAllEntities$.pipe(
				filterEntityAndEmptyDataAndAddChangeType(
					EntitiesByNames.Departments,
					BackendService.toDepartmentDTO
				)
			)
		)
	}

	findAllTreatmentPlans(
		patientIds?: string[]
	): Observable<(TreatmentPlanDTO & Changeable)[]> {
		return concat(
			this.getAllTreatmentPlanRecursively(patientIds).pipe(
				map((value) => value?.data ?? (null as unknown as TreatmentPlanDTO[]))
			),
			this.getAllEntities$.pipe(
				filterEntityAndEmptyDataAndAddChangeType(
					EntitiesByNames.AllTreatmentPlan,
					BackendService.toTreatmentPlanDTO
				)
			)
		)
	}

	findAllPatientExports(
		patientIds: string[]
	): Observable<(PatientExportDTO & Changeable)[]> {
		return concat(
			this.getExports(patientIds).pipe(
				map(
					(v) =>
						v?.data || (null as unknown as (PatientExportDTO & Changeable)[])
				)
			),
			this.getAllEntities$.pipe(
				filterEntityAndEmptyDataAndAddChangeType(
					EntitiesByNames.Exports,
					BackendService.toPatientExportDTO
				)
			)
		)
	}

	findAllTasks(patientIds?: string[]): Observable<(TaskDTO & Changeable)[]> {
		return concat(
			this.getAllTaskRecursively(patientIds).pipe(
				filter((d) => d !== null),
				map((v) => v?.data || [])
			),
			this.getAllEntities$.pipe(
				filterEntityAndEmptyDataAndAddChangeType(
					EntitiesByNames.AllTask,
					BackendService.toTaskDTO
				)
			)
		)
	}

	findAllInsights(): Observable<(InsightDTO | Changeable)[]> {
		return concat(
			this.getAllInsightsRecursively().pipe(
				filter((d) => d !== null),
				map((v) => v?.data || [])
			),
			this.getAllEntities$.pipe(
				filterEntityAndEmptyDataAndAddChangeType(
					EntitiesByNames.Insights,
					BackendService.toInsightDTO
				)
			)
		)
	}

	// subscribeAllDevices(): Observable<DeviceDTO & Changeable> {
	// 	return this.allDevicesIntervalFrequency$.asObservable().pipe(
	// 		switchMap((v) =>
	// 			interval(v * 1000).pipe(
	// 				takeUntil(this.destroy$),
	// 				switchMap((_) => this.getAllDevices(this.lastPollDevices)),
	// 				switchMap((data) => {
	// 					return this.processDevices(of(data));
	// 				})
	// 			)
	// 		)
	// 	)
	// }

	// subscribeDepartments(): Observable<DepartmentDTO[]> {
	// 	return interval(10000).pipe(
	// 		takeUntil(this.destroy$),
	// 		switchMap((_) => this.getAllDepartments())
	// 	)
	// }

	// pollDeviceUpdated(): Observable<(DeviceDTO & Changeable) | null> {
	// 	return this.getAllDevices(this.lastPollDevices).pipe(
	// 		takeUntil(this.destroy$),
	// 		switchMap((data) =>
	// 			data && data.length ? this.processDevices(of(data)) : of(null)
	// 		)
	// 	)
	// }

	findAllPatients(
		departmentId?: string
	): Observable<(PatientDTO & Changeable)[]> {
		return concat(
			this.getAllPatientsRecursively(undefined, departmentId).pipe(
				filter((d) => d !== null),
				map((v) => v?.data || [])
			),
			this.getAllPatientsEntity$.pipe(
				filter((es) => es.data.length > 0),
				map((res) =>
					res.data.map((p) =>
						BackendService.toPatientDTO(p as BackendPatientDTO)
					)
				),
				map((res) =>
					res.map((value) => ({
						...value,
						changeType:
							value.creationTime === value.lastModifiedTime
								? 'added'
								: ('modified' as ChangeType)
					}))
				)
			)
		)
	}

	findAllDevices(
		patientIds?: string[]
	): Observable<(DeviceDTO & Changeable)[]> {
		return concat(
			this.getAllDevices(patientIds),
			this.getAllDevicesEntity$.pipe(
				filter((es) => es.data.length > 0),
				map((res) =>
					res.data.map((p) => BackendService.toDeviceDTO(p as BackendDeviceDTO))
				),
				map((res) =>
					res.map((value) => ({
						...value,
						changeType:
							value.creationTime === value._lastModifiedTime
								? 'added'
								: ('modified' as ChangeType)
					}))
				)
			)
		)
	}

	getAllArchivedPatientsRecursively(page: number = 0) {
		let p = page
		let nextPage = page + 1
		return this.getAllArchivedPatients().pipe(
			expand((r) => {
				p = nextPage
				if (!r) {
					return of()
				} else if (
					p <=
					// @ts-ignore
					Math.round(r.metadata.page?.totalResults / r.metadata.page?.limit)
				) {
					nextPage += 1
					return this.getAllArchivedPatients(p)
				} else {
					return of(null)
				}
			})
		)
	}

	getAllPatientsRecursively(
		departmentId?: string,
		olderThan?: string,
		page: number = 0
	) {
		let p = page
		let nextPage = page + 1
		return this.getAllPatients(olderThan, departmentId).pipe(
			expand((r) => {
				p = nextPage
				if (!r) {
					return of()
				} else if (
					p <=
					// @ts-ignore
					Math.round(r.metadata.page?.totalResults / r.metadata.page?.limit)
				) {
					nextPage += 1
					return this.getAllPatients(olderThan, departmentId, p)
				} else {
					return EMPTY
				}
			}),
			scan(
				(acc, curr) => ({
					data: [...acc.data, ...curr.data]
				}),
				{ data: [] as PatientDTO[] }
			),
			last()
		)
	}

	subscribeAllPatientsLogs(): Observable<PatientLogDTO & Changeable> {
		let maxLastModified = new Date().toISOString()
		return concat(
			this.getAllPatientLogs().pipe(
				tap((a) => {
					;(a as Changeable).changeType = 'added'
				})
			),
			interval(10000).pipe(
				takeUntil(this.destroy$),
				switchMap((_) => this.getAllPatientLogs(maxLastModified)),
				tap((a) => {
					;(a as Changeable).changeType =
						(a as any).status == 'closed'
							? 'removed'
							: a._creationTime == a._lastModifiedTime
							? 'added'
							: 'modified'
				})
			)
		).pipe(
			takeUntil(this.destroy$),
			distinct((a) => `${a._id}.${a._lastModifiedTime}`),
			// @ts-ignore
			tap((a) => {
				if (a._lastModifiedTime > maxLastModified)
					maxLastModified = a._lastModifiedTime
			}),
			map(BackendService.toPatientLogDTO)
		)
	}

	getPatientExportsRecursively(patientIds: string[], page: number = 0) {
		let p = page
		let nextPage = 0
		return this.getExports(patientIds).pipe(
			expand((r) => {
				p = nextPage
				if (!r) {
					return of()
				}
				// @ts-ignore
				else if (p < Math.round(r.metadata.page?.totalResults / 100)) {
					nextPage += 1
					return this.getExports(patientIds, undefined, p)
				} else {
					return of(null)
				}
			})
		)
	}

	getPatientsExportsRecursively(
		patientIds: string[],
		creationTime: string,
		page: number = 0
	) {
		let p = page
		let nextPage = 0
		return this.getPatientsExports(patientIds, creationTime).pipe(
			expand((r) => {
				p = nextPage
				if (!r) {
					return of()
				} else if (p < Math.round(r.metadata!.page!.totalResults! / 100)) {
					nextPage += 1
					return this.getPatientsExports(patientIds, creationTime, p)
				} else {
					return of(null)
				}
			})
		)
	}

	getCriticalAlertsRecursively(
		statuses: string[],
		patientIds?: string[],
		page: number = 0
	) {
		let p = page
		let nextPage = 0
		return this.getCriticalAlerts(statuses, patientIds).pipe(
			expand((r) => {
				p = nextPage
				if (!r) {
					return of()
				}
				// @ts-ignore
				else if (p <= Math.round(r.metadata.page?.totalResults / 100)) {
					nextPage += 1
					return this.getCriticalAlerts(statuses, patientIds, undefined, p)
				} else {
					return of(null)
				}
			})
		)
	}

	getAllInsightsRecursively(page: number = 0) {
		let p = page
		let nextPage = 0
		return this.getAllInsights(undefined).pipe(
			expand((r) => {
				p = nextPage
				if (!r) {
					return of()
				}
				// @ts-ignore
				else if (p <= Math.round(r.metadata.page?.totalResults / 100)) {
					nextPage += 1
					return this.getAllInsights(undefined, p)
				} else {
					return of(null)
				}
			})
		)
	}

	getAllInsightsBrowsingRecursively(page: number = 0, patientId: string) {
		let p = page
		let nextPage = 0
		return this.getBrowsingInsights(
			page,
			'',
			patientId,
			undefined,
			undefined,
			moment(new Date()).subtract('months', 3).toISOString()
		).pipe(
			expand((r) => {
				p = nextPage
				if (!r) {
					return of()
				}
				// @ts-ignore
				else if (p <= Math.round(r.metadata.page?.totalResults / 100)) {
					nextPage += 1
					return this.getBrowsingInsights(
						p,
						'',
						patientId,
						undefined,
						undefined,
						moment(new Date()).subtract('month', 3).toISOString()
					)
				} else {
					return of(null)
				}
			})
		)
	}

	getCriticalAlerts(
		statuses: string[],
		patientIds?: string[],
		olderThan?: string,
		page: number = 0
	) {
		return (
			// @ts-ignore
			this.genericEntityAPIService
				// @ts-ignore
				.searchGenericEntities({
					filter: {
						status: {
							in: statuses
						},
						_templateName: {
							in: [EntitiesByNames.CriticalAlerts]
						},
						severity: {
							in: ['critical']
						},
						...(olderThan && {
							_lastModifiedTime: {
								from: olderThan
							}
						}),
						['patient.id']: {
							in: patientIds
						}
					},
					page
				})
				.pipe(
					takeUntil(this.destroy$),
					map((res) => {
						return {
							data: res.data.map((a) => {
								;(a as Changeable).changeType =
									a._creationTime === a._lastModifiedTime
										? 'added'
										: ('modified' as ChangeType)
								return BackendService.toAlertDTO(a) as AlertDTO & Changeable
							}),
							metadata: res.metadata
						}
					})
				)
		)
	}

	public toggleAllDevicesUpdatesFrequency(
		value: FrequencyUpdatesTimings
	): void {
		this.allDevicesIntervalFrequency$.next(value)
	}

	public getLocalDevicesMeasurements(deviceId?: string): Observable<LocalDeviceData> {
    let queryParams = new HttpParams()
    if (deviceId)
      queryParams = queryParams.append('deviceId', deviceId)
    return this.http.get<LocalDeviceData>(this.LOCAL_DEVICE_URI,
        {
          params: queryParams
        }
      )
      .pipe(
			tap(({ data, metadata }) => {
				const outMessage: Partial<DeviceDTO> = {
					...data,
					...metadata,
					id: DeviceNames.LOCAL,
					status: {
						bp_diastolic: data.bp_diastolic,
						bp_systolic: data.bp_systolic,
						heart_rate: data.heart_rate,
						temp: data.temp,
						spo2: data.spo2,
						respiration_rate: data.respiration_rate,
						last_update_time: data.last_update_time,
						last_measurement_time: data.last_measurement_time
					}
				}
				this.localDeviceMeasurementsMessage$.next(outMessage as DeviceDTO)
			})
		)
	}

	public getHealthCheck() {
		return this.healthCheckService.healthCheck()
	}

	logout() {
		this.destroy$.next()
		this.destroy$.complete()
	}

	protected measurementsWSOnMessageCallback: OnMessageCallback = (
		topic: string,
		payload: ArrayBuffer,
		dup: boolean,
		qos: QoS,
		retain: boolean
	): void => {
		const decoder = new TextDecoder()
		const messageString = decoder.decode(payload)
		const message = JSON.parse(messageString) as DeviceUpdateMqttMessage
		const type = topic.split('/')

		let outMessage: any
		if (
			type[type.length - 2] === 'device' &&
			!(message as unknown as MqttDeviceDTO).data.entity?._id
				?.toLowerCase()
				.includes('manual')
		) {
			outMessage = {
				...message.data,
				...message.metadata,
				deviceId: (message as unknown as MqttDeviceDTO).data?.entity?._id,
				patientId: (message as unknown as MqttDeviceDTO).data?.entity?._patient
					?.id,
				deviceStatusFields: true,
				respiration_rate: (message as unknown as MqttDeviceDTO).data?.entity
					?._status.respiration_rate,
				diastolicPressure: (message as unknown as MqttDeviceDTO).data?.entity
					?._status.bp_diastolic,
				systolicPressure: (message as unknown as MqttDeviceDTO).data?.entity
					?._status.bp_systolic,
				body_temperature: (message as unknown as MqttDeviceDTO).data?.entity
					?._status.temp,
				heart_rate: (message as unknown as MqttDeviceDTO).data?.entity?._status
					.heart_rate,
				spo2: (message as unknown as MqttDeviceDTO).data?.entity?._status.spo2,
				timestamp: (message.metadata as any).endTime
					? new Date((message.metadata as any).endTime).toISOString()
					: null
			}
			this.deviceStatusFieldsWSOnMessage$.next(outMessage)
		}
		if (type[type.length - 1] === 'measurement') {
			outMessage = {
				...message.data,
				...message.metadata,
				timestamp: message.metadata.timestamp
					? new Date(message.metadata.timestamp).toISOString()
					: null
			}
			this.updatePatientVitalsManualWSOnMessage$.next(outMessage)
			this.measurementsWSOnMessage$.next(outMessage)
		}
		if (!outMessage) {
			return
		}
	}

	// 	private processDevices(
	// 		devices: Observable<DeviceDTO[]>
	// 	): Observable<DeviceDTO & Changeable> {
	// 		return devices.pipe(
	// 			takeUntil(this.destroy$),
	// 			tap((a) => {
	// 				a.forEach((a) => {
	// 					;(a as Changeable).changeType =
	// 						(a as any).status === 'closed'
	// 							? 'removed'
	// 							: // @ts-ignore
	// 							a._creationTime === a._lastModifiedTime
	// 							? 'added'
	// 							: 'modified'
	// 				})
	// 			}),
	// 			concatAll(),
	// 			// @ts-ignore
	// 			distinct((a) => `${a._id}.${a._lastModifiedTime}`),
	// 			// @ts-ignore
	// 			tap((a) => {
	// 				// @ts-ignore
	// 				if (a._lastModifiedTime > this.lastPollDevices)
	// 					// @ts-ignore
	// 					this.lastPollDevices = a._lastModifiedTime
	// 			}),
	// 			map(BackendService.toDeviceDTO)
	// 		)
	// 	}
}

function filterEntityAndEmptyDataAndAddChangeType<
	U extends {
		creationTime: Date | string
		lastModifiedTime: Date | string
	},
	F extends GenericEntityResponse
>(entity: EntitiesByNames, toDtoFn: (res: F) => U) {
	return function <T extends SearchResponseGenericEntityResponse>(
		source: Observable<T>
	) {
		return source.pipe(
			map((es) => extractEntityByName(entity, es)),
			filter((es) => es.length > 0),
			map((es) =>
				es
					.map((value) => toDtoFn(value as F))
					.map((value) => ({
						...value,
						changeType:
							value.creationTime === value.lastModifiedTime
								? 'added'
								: ('modified' as ChangeType)
					}))
			)
		)
	}
}
