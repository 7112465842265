import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'

export interface INotification {
	message: string
	type: NotificationType
	duration: number
}

export enum NotificationType {
	Success = 0,
	Warning = 1,
	Error = 2
}

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	// @ts-ignore
	private notification$: Subject<INotification> = new BehaviorSubject(null)

	constructor() {}

	get notification() {
		return this.notification$.asObservable()
	}

	success(message: string, duration: any = 0) {
		this.notify(message, NotificationType.Success, duration)
	}

	warning(message: string, duration: any = 0) {
		this.notify(message, NotificationType.Warning, duration)
	}

	error(message: string, duration: any = 0) {
		this.notify(message, NotificationType.Error, duration)
	}

	private notify(message: string, type: NotificationType, duration: number) {
		duration = !duration ? 3000 : duration
		this.notification$.next({
			message: message,
			type: type,
			duration: duration
		} as INotification)
	}
}
