<ul class="tabs">
  <ng-container *ngFor="let tab of tabs; let index = index; trackBy: trackBy">
    <li class="tab" [class.tab-active]="tab.active" (click)="onTabClick(tab, index)">
      <ng-container *ngTemplateOutlet="titleTemplate || defaultTitleTemplate; context: { $implicit: tab }"></ng-container>
    </li>
  </ng-container>
</ul>

<ng-template #defaultTitleTemplate let-tab>
  <span class="tab-icon" *ngIf="tab.icon as icon" nz-icon [nzType]="icon"></span>
  <span class="tab-text">{{ tab.text }}</span>
  <nz-badge [style.--_badge-bg-color]="tab.badgeBgColor" *ngIf="tab.badgeValue as val" [nzCount]="val" nzStandalone></nz-badge>
</ng-template>