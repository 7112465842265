export enum WarningTitle {
  SaveIncomplete = 'PROCESS INCOMPLETE',
  Warning = 'WARNING',
  SessionExpired = 'SESSION EXPIRED',
};

export enum WarningDescription {
  SaveIncomplete = 'Required vitals missing',
  Warning = 'Exiting without saving will result in data loss',
  SessionExpired = 'Your session has timed out. You can choose to stay and reactivate the session or leave without saving',
};

export enum WarningCancelType {
  SaveIncomplete = 'required',
  Warning = 'warning',
  SessionExpired = 'session',
};