<section class='report-issue'>
  <nz-modal (nzOnCancel)='handlerCancelClick()' [(nzVisible)]='isVisible' nzClassName='custom-report-issue-modal'>
    <div *nzModalTitle>
      <h4 class='report-issue-title'>{{ 'issuePopover.title' | translate }}</h4>
    </div>
    <div *nzModalContent>
      <form class='report-issue-form'>
        <textarea [nzAutosize]='{ minRows: 2, maxRows: 6 }' nz-input [(ngModel)]='inputValue' name='report-issue' [placeholder]="'issuePopover.placeholder' | translate"></textarea>
      </form>
      <div class='footer'>
        <button type='button' class='btn-cancel' (click)='handlerCancelClick()'>
          {{ 'button.cancel' | translate }}
        </button>
        <button type='button' class='btn-confirm' [disabled]='!inputValue.length' (click)='handlerOkClick()'>
          {{ 'issuePopover.send' | translate }}
        </button>
      </div>
    </div>
  </nz-modal>
</section>