import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {
	transform(data: string, ...args: unknown[]): string {
		if (!data) return ''
		const name = data.split(' ')
		return `${name[0][0]}${name[1][0]}`
	}
}
