<section [style.--modal-width]='width' [style.--zindex]='zIndex' class='warning-modal' [class.mobile]="isMobile">
  <div [class.container-session-desktop]='isSessionExpired' class='warning-modal__container'>
    <div class='warning-modal__title-box'>
      <ng-container [ngTemplateOutlet]='iconTemplate || defaultIconTemplate'></ng-container>
      <ng-template #defaultIconTemplate>
        <i [class.icon-session-desktop]='isSessionExpired' nz-icon nzType='icons:warning'></i>
      </ng-template>
      <div [class.title-session-desktop]='isSessionExpired' [class.normal]="isNormalTitle" class='title'>{{ title }}</div>
    </div>
    <div [class.text-session-desktop]='isSessionExpired' class='text'>
      <ng-container [ngTemplateOutlet]='descriptionTemplate || defaultTemplate'></ng-container>
      <ng-template #defaultTemplate>
        {{ description }}
      </ng-template>
    </div>
    <div [class.button-box-session-desktop]='isSessionExpired' class='warning-modal__button-box'>
      <button (click)='closeModalEmitter.emit(false)'
              [class.dark-btn]='hideSave || isFirstButtonDark'>{{ closeText }}
      </button>
      @if (!hideSave) {
        <button [disabled]="isSaveDisabled" (click)='saveModalDataEmitter.emit(true)'
                [nzLoading]='isLoading' nz-button
                nzType='primary'>{{ saveText }}
        </button>
      }
    </div>
  </div>
</section>
